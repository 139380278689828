<template>
  <div id="bg-main" class="fullscreen">
    <div id="bg-c1" class="circle" />
  </div>
</template>

<script>
export default {
  name: 'TodoBackground',
};
</script>

<style scoped>
#bg-main {
  background-color: #eee;
  z-index: 30;
}

#bg-c1 {
  position: absolute;
  left: 15%;
  top: 50%;
  transform: translateY(-50%);

  width: 300%;
  background-color: #fff;

  z-index: 30;
}
</style>
