import Vue from 'vue';
import VueRouter from 'vue-router';

import App from '/src/App.vue';
import Landing from '/src/Landing.vue';
import Admin from '/src/Admin.vue';
import Tracking from '/src/Tracking.vue';
import Document from '/src/Document.vue';
import Splash from '/src/Splash.vue';
import OfflineLogin from '/src/OfflineLogin.vue';
import OfflineTakeaways from '/src/OfflineTakeaways.vue';


Vue.use(VueRouter);

const routes = [
  {
    path: '/dl3NZcgen',
    name: 'Admin',
    component: Admin,
  },
  {
    path: '/tracking/:id?',
    name: 'Tracking',
    component: Tracking,
    props: route => {
      return {
        playerid: route.params.id, //This could be omitted if we can use localstorage
      };
    }
  },
  {
    path: '/app/:id/:skipIntro?',
    name: 'App',
    component: App,
    props: route => {
      return {
        playerid: route.params.id, //This could be omitted if we can use localstorage
        skipIntro: route.params.skipIntro,
      };
    }
  },
  {
    path: '/document/',
    name: 'DocumentCustomised',
    component: Document,
    props: () => {
      return {
        playerid: '2wAp',
      };
    }
  },
  {
    path: '/document/:id',
    name: 'Document',
    component: Document,
    props: route => {
      return {
        playerid: route.params.id,
      };   
    }
  },
  {
    path: '/',
    name: 'Splash',
    component: Splash,
  },
  {
    path: '/index.html',
    name: 'Index',
    component: Splash,
  },
  {
    path: '/login',
    name: 'OfflineLogin',
    component: OfflineLogin,
  },
  {
    path: '/offlinedocuments',
    name: 'OfflineTakeaways',
    component: OfflineTakeaways,
  },
  {
    path: '/new/:id',
    name: 'Landing',
    component: Landing,
    props: route => {
      return {
        playerid: route.params.id,
        newId: true,
      };   
    }
  },
  {
    path: '/:id?/:skipIntro?',
    name: 'Landing',
    component: Landing,
    props: route => {
      return {
        playerid: route.params.id,
        skipIntro: route.params.skipIntro,
      };   
    }
  },
];

let router;

if (Object.prototype.hasOwnProperty.call('process.versions', 'electron')) {
  router = new VueRouter({
    base: process.env.BASE_URL,
    routes
  });
}
else {
  router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  });
}

export default router;
