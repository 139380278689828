<template>
  <div id="app">
    <MainBackground v-show="showBackground" />
    <audio ref="audio-startup" :src="startupSrc" preload="auto" />
    <audio ref="audio-ambient" :src="ambientSrc" preload="auto" loop />

    <div id="middlecol" v-show="$store.state.callPlaying === false">
      <PersonalAssistant v-show="$store.state.currentView === 'default' || $store.state.currentView === 'radio'" ref="PAWidget" :started="started" />
    </div>
    <HomeScreen v-show="$store.state.callPlaying === false" :skip-intro="skipIntro" />

    <EmailRolodex v-if="$store.state.currentView === 'email'" ref="emailClient" @update-status="updateStatus" />
    <NewsRolodex v-if="$store.state.currentView === 'news'" ref="newsClient" @update-status="updateStatus" />
    <InvestorTool v-if="$store.state.currentView === 'investor'" />
    <InvestorScreen v-if="$store.state.currentView === 'investorscreen'" />
    <TodoList v-if="$store.state.currentView === 'todo'" />
    <Weather v-if="$store.state.currentView === 'weather'" />
    <CarbonTransactions v-if="$store.state.currentView === 'carbon'" />

    <RadioMiniControl />
    <RadioSubtitles />

    <Caller />
    <VideoSubtitles />
    <Notifier />

    <Food v-if="$store.state.currentView === 'food'" />
    <ClickToCall v-if="$store.state.pitches" />

    <TestingControl v-show="$store.state.devEnvironment && !$store.state.isPreviewEnvironment" />
    <LoginScreenTest :skip-intro="skipIntro" @started="startDemo" />
    <TakeAwayDocument v-if="$store.state.currentView === 'end'" />
    <Departure v-if="$store.state.end" />
  </div>
</template>

<script>
import MainBackground from './components/MainBackground.vue';
import PersonalAssistant from './components/PersonalAssistant.vue';
import EmailRolodex from './components/EmailRolodex.vue';
import NewsRolodex from './components/NewsRolodex.vue';
import Weather from './components/Weather.vue';
import Notifier from './components/Notifier.vue';
import ClickToCall from './components/ClickToCall.vue';
import InvestorTool from './components/InvestorTool.vue';
import InvestorScreen from './components/InvestorScreen.vue';
import LoginScreenTest from './components/LoginScreenTest.vue';
import TodoList from './components/TodoList.vue';
import CarbonTransactions from './components/CarbonTransactions.vue';
import Caller from './components/Caller.vue';
import Food from './components/Food.vue';
import HomeScreen from './components/HomeScreen.vue';
import TakeAwayDocument from './components/TakeAwayDocument.vue';
import RadioMiniControl from './components/RadioMiniControl.vue';
import RadioSubtitles from './components/RadioSubtitles.vue';
import VideoSubtitles from './components/VideoSubtitles.vue';
import Departure from './components/Departure.vue';
import TestingControl from './components/TestingControl.vue';

export default {
  name: 'App',
  components: {
    MainBackground,
    PersonalAssistant,
    EmailRolodex,
    NewsRolodex,
    RadioMiniControl,
    RadioSubtitles,
    VideoSubtitles,
    Weather,
    Notifier,
    ClickToCall,
    Food,
    InvestorTool,
    InvestorScreen,
    LoginScreenTest,
    TodoList,
    CarbonTransactions,
    Caller,
    HomeScreen,
    TakeAwayDocument,
    Departure,
    TestingControl,
  },
  props: {
    playerid: String,
    skipIntro: String,
  },
  data: function() {
    return {
      gameStatus: {},
      gameStatusLast: '',
      game_timer: 0,
      hintTimer: 0,
      // started: false,
      started: true,
    };
  },
  computed: {
    showBackground() {
      if (this.$store.state.calling)
        return false;

      return this.$store.state.currentView !== 'investor' && this.$store.state.currentView !== 'investorscreen' && this.$store.state.currentView !== 'email';
    },
    ambientPlaying() {
      if (this.$store.state.radioPlaying || this.$store.state.calling || this.$store.state.pitches || this.$store.state.end)
        return false;

      return true;
    },
    ambientSrc() {
      if (this.$store.state.isOfflineVersion)
        return 'local-asset://audio/effects/bg_soundscape_v5.mp3';

      return '/audio/effects/bg_soundscape_v5.mp3';
    },
    startupSrc() {
      if (this.$store.state.isOfflineVersion)
        return 'local-asset://audio/effects/startup_v5.2.mp3';

      return '/audio/effects/startup_v5.2.mp3';
    }
  },
  watch: {
    '$store.state.connected':function(status){
      console.log(status);
      if (status === 'couldnotconnect'){
        this.kickOut();
        console.log('PLAYER ID NOT FOUND AT BACK END');
      } else if (status === 'offline') {
        console.log('BACK END SIGNALLED OFFLINE MODE - NO PLAYER STATUS WILL BE SAVED');
      }
    },
    ambientPlaying: function(newState) {
      if (newState)
        this.$refs['audio-ambient'].play();
      else
        this.$refs['audio-ambient'].pause();
    }
  },
  mounted(){
    if (this.playerid){
      this.$store.dispatch('loadPlayerData', this.playerid);
    } else {
      this.kickOut();
      console.log('PLAYER HAD NO ID');
    }
  },
  created() {
    if (navigator.userAgent.indexOf(' Electron') === -1) {
      const beforeUnloadListener = (event) => {
        event.preventDefault();
        return event.returnValue = 'Are you sure you want to exit?';
      };

      // A function that invokes a callback when the page has unsaved changes.
      addEventListener('beforeunload', beforeUnloadListener, {capture: true});
      // addEventListener('popstate', (e) => { e.preventDefault(); }, {capture: true});
    }

    // Check WebP support and add 'webp' or 'no-webp' class to body tag
    if (support_format_webp) {
      document.body.classList.add('webp');
    }
    else {
      document.body.classList.add('no-webp');
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'Landing' && this.$store.state.connected !== 'couldnotconnect') {
      next(false);
      return;
    }

    next();
  },
  methods: {
    logInfo(param) {
      console.log(param);
    },
    increaseTime() {
      this.game_timer += 1;
    },
    startDemo() {
      console.log('Game started!');

      this.$refs['audio-startup'].play();
      setTimeout(() => { this.$refs['audio-ambient'].play(); }, 10000);
      // setTimeout(() => { this.updateStatus('demo-start'); }, 2000);
      // this.started = true;
      // this.$refs.radioWidget.playPause()
    },
    updateStatus(trigger) {
      if (trigger in this.gameStatus) {
        return;
      }

      this.gameStatus[trigger] = true;
      console.log('Completed task: ' + trigger);
      this.gameStatusLast = trigger;

      this.$store.dispatch('updateGameContent', trigger);
    },
    triggerHint() {
      this.$refs.PAWidget.update(this.gameStatusLast);
      this.hintTimer = 0;
    },
    kickOut(){
      if (this.playerid)
        this.$router.push({name: 'Landing', params: {id: this.playerid}});
      else
        this.$router.push({name: 'Landing'});
    }
  }
};

function support_format_webp()
{
  if (navigator.userAgent.indexOf('Safari') > -1)
    return false;

  var elem = document.createElement('canvas');

  if (elem.getContext && elem.getContext('2d'))
  {
    // was able or not to get WebP representation
    return (elem.toDataURL('image/webp').indexOf('data:image/webp') == 0);
  }
  else
  {
    // very old browser like IE 8, canvas not supported
    return false;
  }
}
</script>

<style scoped>
#app {
  overflow: hidden;
}
</style>
