<template>
  <div id="investor-wrapper" class="fullscreen" :class="{ zoomed: brochureZoom }">
    <div id="investor-bg" class="fullscreen">
      <div id="bg-c1" class="circle">
        <img :src="'/img/logos/' + company + '.svg'" alt="Company Logo">
      </div>
    </div>

    <perfect-scrollbar id="col-left">
      <div class="company-overview">
        {{ investorContent.companies[company].overview }}
      </div>

      <div class="company-whatdo">
        <p class="fg-darkgreen">
          <strong>What they do</strong>
        </p>
        <p>{{ investorContent.companies[company].work }}</p>
      </div>

      <div v-if="investorContent.companies[company].brochure" class="company-brochureplaceholder" @click="zoom">
        <picture alt="Company Brochure">
          <source :srcset="'/img/' + investorContent.companies[company].brochure + '.webp'" type="image/webp">
          <img :src="'/img/' + investorContent.companies[company].brochure + '.jpg'">
        </picture>

        <div class="zoomhint fullscreen">
          <div class="widget-content">
            Click to zoom
          </div>
        </div>
      </div>

      <div class="company-info">
        <div class="info-box">
          <p><strong>Sector</strong></p>
          <p>{{ investorContent.companies[company].sector }}</p>
        </div>

        <div class="info-box">
          <p><strong>Investment Opportunity</strong></p>
          <p>{{ investorContent.companies[company].type }}</p>
        </div>

        <div class="info-box">
          <p><strong>ESG Rating</strong></p>
          <p :class="esgClass">
            {{ esgSign }}{{ esgRating }}
          </p>
        </div>

        <div class="info-box">
          <p><strong>CEO</strong></p>
          <p>{{ investorContent.companies[company].ceo }}</p>
        </div>

        <div class="info-box">
          <p><strong>Geographical Domain</strong></p>
          <p>{{ investorContent.companies[company].domain }}</p>
        </div>
      </div>
    </perfect-scrollbar>

    <perfect-scrollbar id="col-right">
      <h2 class="bottom-shadow">
        Latest news
      </h2>
      <div class="company-latestnews">
        {{ investorContent.companies[company].name }} announce sale of shares
      </div>
      <div v-if="investorContent.companies[company].highlight" class="social">
        <div class="icon">
          <img src="/img/icons/search.png" alt="Search">
        </div>
        <div class="description" style="font-size: 14px;">
          {{ investorContent.companies[company].highlight }}
        </div>
      </div>

      <h2 class="bottom-shadow">
        Socials
      </h2>

      <div v-for="(item, index) in investorContent.companies[company].social" :key="index" class="social">
        <div class="icon">
          <img src="/img/icons/social.png" alt="Social">
        </div>
        <div class="description" style="font-size: 14px;">
          {{ item }}
        </div>
      </div>
    </perfect-scrollbar>

    <div v-if="brochureZoom" class="investor-brochure" :class="{ zoomed: brochureZoom }" @click="zoom">
      <picture alt="Company Brochure">
        <source :srcset="'/img/' + investorContent.companies[company].brochure + '.webp'" type="image/webp">
        <img :src="'/img/' + investorContent.companies[company].brochure + '.jpg'">
      </picture>

      <div v-if="!brochureZoom" class="zoomhint fullscreen">
        <div class="widget-content">
          Click to zoom
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import investorContentJSON from '@/content/investor.json';

export default {
  name: 'InvestorTool',
  data: function() {
    return {
      investorContent: investorContentJSON,
      company: this.$store.state.showCompany,
      brochureZoom: false,
      esgRatingBase: 0,
      esgRating: 0,
      esgRatingTimer: undefined,
    };
  },
  computed: {
    esgClass() {
      if (this.esgRating > 0)
        return 'fg-esg-green';

      if (this.esgRating < 0)
        return 'fg-esg-red';

      return '';
    },
    esgSign() {
      if (this.esgRating > 0)
        return '+';

      return '';
    }
  },
  mounted() {
    this.esgRatingTimer = setInterval(() => { this.updateESG(); }, 2000);

    this.esgRating = this.investorContent.companies[this.$store.state.showCompany].esg;

    if (this.company) {
      this.$store.dispatch('toggleCompanyInInvestorList', this.company);
    }
  },
  beforeDestroy() {
    clearInterval(this.esgRatingTimer);
  },
  destroyed() {
    this.$store.commit('setAppDisplay', { app: 'investor', item: '' });
  },
  methods: {
    zoom() {
      this.brochureZoom = !this.brochureZoom;
    },
    updateESG() {
      this.esgRating = this.investorContent.companies[this.company]['esg'] + Math.floor(Math.random() * 6)/10000 - 0.0003;
      this.esgRating = this.esgRating.toFixed(4);
    },
  }
};
</script>

<style scoped>
#investor-bg {
  background-color: #eee;
}
#bg-c1 {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -83%);

  width: 70%;
  max-width: 1000px;
  background-color: #fff;
}
#bg-c1 img {
  position: absolute;
  bottom: 25px;
  left: 50%;

  width: 25%;

  transform: translateX(-50%);
}

#investor-wrapper {
  /* background-color: #eee; */
  color: #333;
  z-index: 800;
}
#investor-wrapper.zoomed {
  z-index: 950;
}

#col-left, #col-right {
  position: absolute;
  top: 25%;
  height: 65%;
  padding-right: 20px;

  overflow: auto;
}
#col-left {
  left: 10%;
  width: calc(80% - 350px);
}
#col-right {
  right: 10%;
  width: 300px;
}

#col-left .company-overview {
  color: #536444;
  font-size: 20px;
}
#col-left .company-whatdo {
  font-size: 14px;
  padding: 10px 0;
  margin: 20px 0;

  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
#col-left .company-brochureplaceholder {
  position: relative;
  height: 25vh;
  width: 44.5vh;
  margin: 0 auto;
  /* content: " "; */

  margin-bottom: 40px;
}

#col-left .company-info {
  width: 100%;
}
#col-left .company-info .info-box {
  position: relative;
  float: left;
}
#col-left .company-info .info-box:nth-child(2),
#col-left .company-info .info-box:nth-child(5) {
  width: 50%;
}
#col-left .company-info .info-box:nth-child(1),
#col-left .company-info .info-box:nth-child(3),
#col-left .company-info .info-box:nth-child(4) {
  width: 25%;
}

#col-left .company-info .info-box strong::after {
  display: block;
  content: " ";
  position: relative;
  top: -22px;
  left: 10%;
  width: 80%;
  height: 30px;
  box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.2);
  margin-bottom: -10px;
}
#col-left .company-info .info-box p {
  text-align: center;
  margin: 0;
  padding: 0;
}
#col-left .company-info .info-box strong {
  font-size: 12px;
  padding: 20px 0;
  width: 80%;
}
#col-left .company-info .info-box p:nth-child(2) {
  font-size: 20px;
  margin-bottom: 30px;
}

#col-right h2 {
  margin: 0;
  margin-bottom: -10px;
  width: 100%;
}
h2.bottom-shadow::after {
  margin-bottom: -20px;
}
#col-right .company-latestnews {
  font-size: 18px;
  margin-bottom: 15px;
}
#col-right .social {
  font-size: 12px;
  position: relative;

  margin: 15px 0;
}
#col-right .social:nth-child(1) {
  margin-top: 0;
}
#col-right .social .description {
  position: relative;
  left: 60px;
  width: calc(100% - 60px);
}
#col-right .social .icon {
  position: absolute;
  top: 50%;

  width: 35px;
  height: 35px;

  transform: translateY(-50%);
}
#col-right .icon img {
  width: 35px;
  height: 35px;
}


#col-right .favourite {
  font-size: 12px;
  position: relative;

  margin: 10px 0;
}
#col-right .favourite:nth-child(1) {
  margin-top: 0;
}
#col-right .favourite .description {
  font-size: 14px;
}
#col-right .favourite .icon {
  position: relative;
  left: calc(50% - 40px);

  transform: scale(0.9);

  margin-bottom: 10px;

  cursor: pointer;

  animation: button-pop 3.5s linear;
  animation-iteration-count: infinite;
}
#col-right .favourite .icon.circle {
  background-color: #546545;
  width: 80px;
}
#col-right .favourite .icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 50%;
  height: 50%;
  cursor: pointer;
}
#heart * {
  cursor: pointer;
}

svg.heart-filled .outline {
  fill: #fff;
}


.investor-brochure {
  position: absolute;
  left: 50%;
  top: 30%;
  height: 60%;
  width: 106vh;
  transform: translateX(-50%);

  cursor: pointer;
}

.company-brochureplaceholder .zoomhint {
  opacity: 0;
  transition: all 0.5s linear;

  font-size: 20px;
  font-weight: 600;
}
.company-brochureplaceholder img {
  transition: all 0.5s linear;
}
.company-brochureplaceholder:hover img {
  opacity: 0.2;
}
.zoomed:hover img {
  opacity: 1;
}
.company-brochureplaceholder:hover .zoomhint {
  opacity: 1;
}
.investor-brochure.zoomed {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  background-color: #fff;

  transform: none;

  opacity: 1;
}
.investor-brochure picture, .investor-brochure img {
  cursor: pointer;
}

.company-brochureplaceholder * {
  cursor: pointer;
}
.company-brochureplaceholder img, .investor-brochure   img {
  max-height: 100%;
  max-width: 100%;
}

.fg-esg-green {
  color: #90B888;
}

.fg-esg-red {
  color: #F18682;
}

@keyframes button-pop {
  0%, 90%, 100% { transform: scale(0.9); }
  95% { transform: scale(1.0); }
}
</style>
