<template>
  <div id="bg-main">
    <div id="bg-right" />
    <div id="bg-middle" />
    <div id="bg-leftcol" />
  </div>
</template>

<script>
export default {
  name: 'EmailsBackgroundHome',
};
</script>

<style scoped>
#bg-main {
  z-index: 30;
}

#bg-leftcol {
  border-radius: 50%;
  height: 200%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;

  transform: translate(-50%, -50%);

  background-color: #85A59E;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  z-index: 30;
}

#bg-middle {
  border-radius: 50%;
  height: 45%;
  width: 20%;
  position: absolute;
  top: 50%;
  left: 37%;

  /* transform: translate(-50%, -50%); */
  transform: translate(0, -50%);
  filter: blur(45px);

  background-color: #95CC8D;
  z-index: 30;
}

#bg-right {
  border-radius: 50%;
  position: absolute;
  height: 170%;
  width: 80%;
  top: 50%;
  left: 38%;

  /* transform: translate(-50%, -50%); */
  transform: translate(-23%, -50%);
  background-color: #90BAB0;
  filter: blur(20px);
  z-index: 30;
}
</style>
