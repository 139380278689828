<template>
  <div id="search-wrapper">
    <input v-model="keyword" type="search" placeholder="Search">

    <div v-if="keyword" class="delete" @click="clearSearch">
      &times;
    </div>

    <ul id="results">
      <li v-for="(item, index) in getResults" :key="index" @click="log(item)">
        <a>{{ newsContent[item].heading }}</a>
      </li>
    </ul>
    <div v-if="keyword.length < 3" id="search-empty">
      Type three or more characters to search
    </div>
    <div id="error">
      {{ searchError }}
    </div>
  </div>
</template>

<script>
import newsContentJSON from '@/content/news.json';

export default {
  name: 'SearchBar',
  data: function() {
    return {
      keyword: '',
      newsContent: newsContentJSON,
    };
  },
  computed: {
    getResults() {
      // Filter through search results and only include ones which contain content
      // which is accessible (ie. part of newsList or emailsList)
      if (this.keyword.length < 3)
        return [];

      var returner = Object.keys(this.newsContent).filter(key => {
        if (!('tags' in this.newsContent[key]))
          return false;

        var filterKeywords = this.newsContent[key].tags.filter(kw => kw.indexOf(this.keyword.toLowerCase()) === 0);

        if (filterKeywords.length > 0) {
          if (this.$store.state.newsList.includes(key))
            return true;
        }
        
        return false;
      });

      return returner;
    },
    searchError() {
      if (this.keyword.length < 3)
        return '';
      //   return 'Type three or more characters to search';
      
      if (this.getResults.length === 0 && this.keyword.length > 0)
        return 'No results found for \'' + this.keyword + '\'';
      
      return '';
    }
  },
  watch: {
    getResults: function() {
      // Emit user-input to update scrollbar length in main view
      // whenever search results change
      this.$emit('user-input');
    }
  },
  methods: {
    log(itm) {
      console.log(itm);
      this.$store.commit('setAppDisplay', { app: 'news', item: itm });
    },
    clearSearch() {
      this.keyword = '';
    },
  }
};
</script>

<style scoped>
/* #search-wrapper {
  z-index: 600;
  position: absolute;
  top: 15px;
  left: 15px;
} */
#search-wrapper {
  position: relative;
}

#search-wrapper input {
  width: 100%;
  color: #fff;
}
#search-wrapper input:focus, #search-wrapper input:active {
  border: 0;
  outline: none;
}

#error, #search-empty {
  font-size: 16px;
  font-weight: 600;
}

#results {
  margin-top: 15px;
  font-size: 14px;
  font-weight: 400;

  margin-left: 0;
  padding-left: 20px;

  text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

#results li {
  margin-bottom: 5px;
}

#results a {
  font-weight: 400;
}

#results div {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
  text-decoration: underline;
}

.delete {
  position: absolute;
  top: 1px;
  right: 15px;

  font-size: 1.2em;

  cursor: pointer;
}

#search-empty {
  animation: highlight-search 5s linear;
  animation-iteration-count: infinite;
}

@keyframes highlight-search {
  0%, 20%, 40%, 100% {
    opacity: 1;
  }
  10%, 30% {
    opacity: 0;
  }
}
</style>
