<template>
  <div id="radio-client" class="pos-center">
    <audio ref="radio-calvermeinerbreaking" :src="main1Src" preload="auto" controls @ended="onMainRadioEnded">
      <track ref="radioVTT-calvermeinerbreaking" src="/audio/radio/subtitles/Crucial_SM_Deliotte Pod_part_8_Calverminer_v1.vtt" type="subtitles" srclang="en" label="English" default @cuechange="radioSubtitle">
    </audio>

    <audio ref="radio-harry" :src="main2Src" preload="auto" controls @ended="onMainRadioEnded">
      <track ref="radioVTT-harry" src="/audio/radio/subtitles/Crucial_SM_Deliotte Pod_part_2_Urban Living_v1.vtt" type="subtitles" srclang="en" label="English" default @cuechange="radioSubtitle">
    </audio>

    <audio ref="radio-" :src="optionalSrc" preload="auto" controls>
      <track ref="radioVTT-" src="/audio/radio/subtitles/combined_v5.2.vtt" type="subtitles" srclang="en" label="English" default @cuechange="radioSubtitle">
    </audio>

    <div v-show="radioFocused">
      <a @click.stop.prevent="playRadio">
        <img v-if="!playing" class="radio-control" src="/img/radio/play.svg">
        <img v-else class="radio-control" src="/img/radio/pause.svg">
      </a>
      <div v-show="nowPlaying">
        Now Playing: {{ nowPlaying }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RadioInline',
  props: ['radioFocused'],
  data: function() {
    return {
      triggersSent: {},

      // Set this to the first compulsory feature that needs preloading
      currentFeaturePreload: 'calvermeinerbreaking',
    };
  },
  computed: {
    currentTrack() {
      return this.$store.state.radioFeature;
    },
    playing() {
      return this.$store.state.radioPlaying;
    },
    nowPlaying() {
      if (this.currentTrack) {
        return this.$store.state.radioLogic[this.currentTrack].nowPlaying;
      }

      return '';
    },
    optionalSrc() {
      if (this.$store.state.isOfflineVersion)
        return 'local-asset://audio/radio/optional_v5.2cbr.mp3';

    return '/audio/radio/optional_v5.2cbr.mp3';
    },
    main1Src() {
      if (this.$store.state.isOfflineVersion)
        return 'local-asset://audio/radio/main_1_Calverminer_v5.1cbr.mp3';

    return '/audio/radio/main_1_Calverminer_v5.1cbr.mp3';
    },
    main2Src() {
      if (this.$store.state.isOfflineVersion)
        return 'local-asset://audio/radio/main_2_Urban Living_v5.1cbr.mp3';

    return '/audio/radio/main_2_Urban Living_v5.1cbr.mp3';
    },
  },
  watch: {
    playing: function(newState) {
      this.pauseAll();

      if (newState === false)
        return;
      if (this.$store.state.calling || this.$store.state.pitches) {
        this.$store.commit('radioStop');
        return;
      }

      console.log('Playing radio');
      this.$refs['radio-' + this.currentTrack].play();
    },

    currentTrack: function() {
      this.$store.commit('updateRadioSubtitle', '');
      this.pauseAll();

      if (this.radioPlaying)
        this.$refs['radio-' + this.currentTrack].play();

      // Send trigger if it hasn't already been sent
      if (this.currentTrack !== '' && !(this.currentTrack in this.triggersSent)) {
        console.log('Started radio trigger timer');
        setTimeout(() => {
          this.$store.dispatch('updateGameContent', this.$store.state.radioLogic[this.currentTrack].emits);
        }, this.$store.state.radioLogic[this.currentTrack].delay * 1000);

        this.triggersSent[this.currentTrack] = true;
      }
    },
  },
  methods: {
    playRadio() {
      if (this.$store.state.calling || this.$store.state.pitches)
        return;

      this.$store.commit('radioToggle');
    },
    onMainRadioEnded() {
      this.$store.commit('clearRadioFeature');
    },
    radioSubtitle() {
      if (this.$refs['radioVTT-' + this.currentTrack].track.activeCues.length === 0)
        return;

      this.$store.commit('updateRadioSubtitle', this.$refs['radioVTT-' + this.currentTrack].track.activeCues[0].text);
    },
    pauseAll() {
      this.$refs['radio-calvermeinerbreaking'].pause();
      this.$refs['radio-harry'].pause();
      this.$refs['radio-'].pause();
    }
  }
};
</script>

<style scoped>
#radio-client {
  z-index: 100;
}
#player-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#playpause {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translateX(-50%) scale(0.3);
}

audio {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 1px;
}

.radio-control {
  height: 20px;
  width: 20px;
}

.btn {
  position: absolute;

}
</style>
