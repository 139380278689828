<template>
  <div id="main">
    <transition name="landing-main">
      <router-view :playerid="playerid" />
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    playerid: String
  },
  mounted(){
    console.log(this.playerid);
  }
};
</script>
<style scoped>
.landing-main-enter, .landing-main-leave-to {
  opacity: 0;
}
.landing-main-enter-to, .landing-main-leave {
  opacity: 1;
}
.landing-main-enter-active, .landing-main-leave-active {
  transition: all 2s linear;
}
</style>
