<template>
  <div id="food-wrapper" class="fullscreen">
    <div id="food-bg">
      <div class="food-bg-05" :class="bgLeft" />
      <div class="food-bg-c1 circle pos-center" :class="bgMid" />
      <div class="food-bg-c2 circle" :style="bgObject">
        <div class="food-gradient" :class="'gradient-' + bgMid" />
      </div>
    </div>

    <div v-if="!ordered && !selected" id="food-bubbles" style="will-change: auto;">
      <transition-group id="food-bubbles-main" name="restaurant-nav" tag="div" class="pos-center">
        <div v-for="(item, index) in restaurantList" :id="'rb-' + index" :key="keys[index]" class="restaurant-bubble circle" :style="{ backgroundImage: imgUrl(item) }" @click="setRestaurant(item)">
          <!-- <img :src="'/img/food/' + item['image']"> -->
          <div class="food-gradient" :class="'gradient-bg-' + restaurantIndex(item)" />
          <p>
            {{ foodContent[item].name }}
          </p>
        </div>
      </transition-group>

      <div v-if="selected === ''" id="food-nav" class="pos-center">
        <div id="food-items-prev" @click="changeRestaurant(-1)">
          &lt;
        </div>
        <div id="food-items-next" @click="changeRestaurant(1)">
          &gt;
        </div>
      </div>
    </div>

    <div v-if="!ordered && selected" id="food-bubbles-right">
      <div v-for="(item, index) in restaurantList" :id="'rb-' + index" :key="keys[index]" class="restaurant-bubble circle" :style="{ backgroundImage: imgUrl(item), transform: 'scale('+(selected === item ? 1.25 : 0.95)+')' }" @click="setRestaurant(item)">
        <div class="circle fullscreen bg" :class="'bg-'+restaurantIndex(item)+'-3'" />
        <div class="widget-content">
          {{ foodContent[item].name }}
        </div>
      </div>
      <div v-if="$store.state.devEnvironmentLocal" @click="selected = ''">
        Clear
      </div>
    </div>

    <div v-if="selected !== ''" id="food-title-restaurant">
      {{ foodContent[selected].name }}
    </div>
    <div v-else-if="!ordered" id="food-title">
      Select a restaurant
    </div>

    <perfect-scrollbar v-if="selected !== ''" id="menu-wrapper" :class="{ 'alter-height': (shoppingCart.length > 0) }">
      <div id="restaurant-description">
        {{ foodContent[selected].description }}
      </div>

      <!-- Restaurant Menu -->
      <div id="food-items">
        <div v-for="(item, index) in foodItems" :key="item.name" class="food-items">
          <h1>{{ item.name }}</h1>
          <p>{{ item.description }}</p>

          <div class="food-info-bubbles">
            <div class="circle food-carbon">
              <p class="pos-center tooltip">
                <CarbonCountSymbol class="pos-center" style="transform: translateX(-50%) scale(1.2)">
                  {{ item.carbon }}
                </CarbonCountSymbol>
                <span class="description" style="font-size: 14px;">
                  Carbon Cost
                </span>
              </p>
            </div>
            <div class="circle food-price">
              <p class="pos-center">
                £{{ item.price > 15 ? item.price : item.price.toFixed(2) }}
              </p>
            </div>
            <div class="circle food-addtocart" @click="addToCart(index)">
              <p class="pos-center">
                +
              </p>
            </div>
            <div class="clearfix" />
          </div>
        </div>
      </div>
    </perfect-scrollbar>

    <!-- Spotlight circle -->
    <transition name="tr-opacity">
      <div v-if="selected !== '' && 'extra' in foodContent[selected]" id="restaurant-highlight" class="circle" :class="bgSticker">
        <div class="widget-content">
          <div class="title">
            {{ foodContent[selected].extra.title }}
          </div>
          <div class="body">
            {{ foodContent[selected].extra.body }}
          </div>
        </div>
      </div>
    </transition>

    <!-- Shopping cart -->
    <perfect-scrollbar v-if="shoppingCart.length > 0" id="cart" :class="{ 'alter-height': (shoppingCart.length > 0) }">
      <table>
        <tr v-for="item in shoppingCart" :key="item">
          <td>{{ foodItems[item].name }}</td>
          <td>£{{ foodItems[item].price.toFixed(2) }}</td>
          <td>
            <CarbonCountSymbol>{{ foodItems[item].carbon }}</CarbonCountSymbol>
          </td>
          <td class="delete" @click="removeFromCart(item)">
            &#10799;
          </td>
        </tr>
        <tr>
          <td><strong>Total</strong></td>
          <td><strong>£{{ cartTotal.price.toFixed(2) }}</strong></td>
          <td>
            <CarbonCountSymbol>{{ cartTotal.carbon }}</CarbonCountSymbol>
          </td>
        </tr>
      </table>
    </perfect-scrollbar>
    <div v-else-if="selected !== ''" id="cart">
      <strong>Your shopping cart is empty</strong>
    </div>

    <!-- Order button -->
    <div v-if="shoppingCart.length > 0" id="checkout" class="circle animation-pop" @click="checkout">
      <div class="widget-content">
        Order
      </div>
    </div>

    <div v-if="ordered" id="checkout-bg1" class="circle" />
    <div v-if="ordered" id="checkout-bg2" class="circle" />
    <div v-if="ordered" id="checkout-complete" class="pos-center">
      Your bicycle courier is<br>en route with your food
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
import foodContentJSON from '@/content/food.json';
import { v4 as uuidv4 } from 'uuid';
import CarbonCountSymbol from '@/components/CarbonCountSymbol.vue';

export default {
  name: 'Food',
  components: {
    CarbonCountSymbol,
  },
  data: () => {
    return {
      foodContent: foodContentJSON,
      selected: '',
      shoppingCart: [],
      restaurantSelection: 0,
      ordered: false,
      keys: [],
      restaurantList: [...Array(5)],
    };
  },
  computed: {
    bgObject() {
      if (this.selected === '') {
        return { display: 'none' };
      }

      if (this.selected === 'Restaurant 3')
        return { backgroundImage: this.imgUrl(this.selected), backgroundPositionY: 'top' };
      else if (this.selected === 'Restaurant 2')
        return { backgroundImage: this.imgUrl(this.selected), backgroundPositionY: '250px' };
      else if (this.selected === 'Restaurant 5')
        return { backgroundImage: this.imgUrl(this.selected), backgroundPositionY: '50px' };

      return { backgroundImage: this.imgUrl(this.selected) };
    },
    restaurantIdx() {
      if (this.ordered === true)
        return 'end';
      else if (this.selected === '')
        return '';

      return this.selected.split(' ')[1];
    },
    bgLeft() {
      if (this.selected === '' && this.ordered === false) {
        return '';
      }

      return 'bg-' + this.restaurantIdx + '-2';
    },
    bgMid() {
      if (this.selected === '' && this.ordered === false) {
        return '';
      }

      return 'bg-' + this.restaurantIdx;
    },
    bgSticker() {
      if (this.selected === '' && this.ordered === false) {
        return '';
      }

      return 'bg-sticker-' + this.restaurantIdx;
    },
    foodItems() {
      if (this.selected === '')
        return [];

      return this.foodContent[this.selected].menu;
    },
    cartTotal() {
      var cart = {
        price: 0,
        carbon: 0,
      };

      for (var i = 0; i < this.shoppingCart.length; i++) {
        cart.price += this.foodContent[this.selected].menu[this.shoppingCart[i]].price;
        cart.carbon += this.foodContent[this.selected].menu[this.shoppingCart[i]].carbon;
      }

      return cart;
    },
  },
  created() {
    Object.keys(this.foodContent).forEach(() => {
      this.keys.push(uuidv4());
    });

    for (var i = 0; i < 5; i++) {
      this.restaurantList[(2 + i) % 5] = Object.keys(this.foodContent)[(this.restaurantSelection + i) % 5];
    }
  },
  methods: {
    checkout() {
      this.$store.dispatch('addToCarbonCount', {
        title: this.foodContent[this.selected].name,
        cost: this.cartTotal.carbon
      });

      this.$store.dispatch('updateGameContent', 'foodordered');
      this.selected = '';
      this.shoppingCart = [];
      this.ordered = true;
    },
    changeRestaurant(direction) {
      // this.restaurantSelection += direction;

      // if (this.restaurantSelection < 0)
      //   this.restaurantSelection += Object.keys(this.foodContent).length;
      // else if (this.restaurantSelection > (Object.keys(this.foodContent).length - 1))
      //   this.restaurantSelection = 0;
      var tmp;

      if (direction == 1) {
        tmp = this.restaurantList.splice(0, 1);
        this.restaurantList.push(tmp[0]);
        this.keys.splice(0, 1);
        this.keys.push(uuidv4());
      }
      else {
        tmp = this.restaurantList.splice(this.restaurantList.length-1, 1);
        this.restaurantList.splice(0, 0, tmp[0]);
        this.keys.splice(this.restaurantList.length-1, 1);
        this.keys.splice(0, 0, uuidv4());
      }
    },
    addToCart(item) {
      if (this.shoppingCart.includes(item))
        return;

      this.shoppingCart.push(item);
    },
    removeFromCart(item) {
      this.shoppingCart.splice(this.shoppingCart.indexOf(item), 1);
    },
    imgUrl(key) {
      if (this.$store.state.isIE11 || this.$store.state.isSafari) {
        return 'url(/img/food/' + foodContentJSON[key]['image'] + '.png)';
      }

      return 'url(/img/food/' + foodContentJSON[key]['image'] + '.webp)';
    },
    bgScale(index) {
      var out = 'scale(';

      if (index === 0 || index === 4)
        out += 0.7;
      else if (index === 2)
        out += 1.0;
      else
        out += 0.85;
      
      return out + ')';
    },
    styleOpacity(key) {
      if (this.selected === '')
        return 1;
      
      if (this.selected === key)
        return 1;
      else
        return 0.5;
    },
    setRestaurant(key) {
      if (this.selected === '') {
        var commonStyle = {
          duration: 0.5,
          left: 'unset',
          right: '0px',
          width: '11vh'
        };

        gsap.to('#rb-0', {
          ...commonStyle,
          top: '20%',
        });
        gsap.to('#rb-1', {
          ...commonStyle,
          top: '32%',
        });
        gsap.to('#rb-2', {
          ...commonStyle,
          top: '44%',
        });
        gsap.to('#rb-3', {
          ...commonStyle,
          top: '56%',
        });
        gsap.to('#rb-4', {
          ...commonStyle,
          top: '68%',
        });
        gsap.to('#rb-5', {
          ...commonStyle,
          top: '80%',
        });
      }

      this.selected = key;
      this.menuSelection = 0;
      this.shoppingCart = [];
    },
    restaurantIndex(restaurant) {
      // return Object.keys(this.foodContent).indexOf(restaurant);
      return restaurant.split(' ')[1];
    },
  }
};
</script>

<style scoped>
#food-wrapper {
  background-color: #fff;

  z-index: 200;
}

.food-bg-c1 {
  background-color: #f6f6f6;

  width: 75%;
  height: 150%;

  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
}
.food-bg-c2 {
  position: absolute;
  left: 50%;
  top: 0;

  background-color: #333;

  width: 40%;
  max-width: 60vh;
  transform: translate(-50%, -70%);
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-size: 100% auto;
  background-position-y: 100%;
}
.food-bg-05 {
  position: absolute;
  top: 0;
  left: 0%;
  width: 50%;
  height: 100%;

  background-color: #eee;
}

.food-gradient {
  position: absolute;
  left: -1%;
  top: -1.5%;
  width: 102%;
  height: 102%;

  border-radius: 50%;
}
.gradient-bg-1 {
  background: #e9ceac;
  background: linear-gradient(0deg, #e9ceac 0%, rgba(255,255,255,0) 25%); 
}
.gradient-bg-2 {
  background: #a2a7a1;
  background: linear-gradient(0deg, #a2a7a1 0%, rgba(255,255,255,0) 25%); 
}
.gradient-bg-3 {
  background: #b0b791;
  background: linear-gradient(0deg, #b0b791 0%, rgba(255,255,255,0) 25%); 
}
.gradient-bg-4 {
  background: #dab6a3;
  background: linear-gradient(0deg, #dab6a3 0%, rgba(255,255,255,0) 25%); 
}
.gradient-bg-5 {
  background: #becf9f;
  background: linear-gradient(0deg, #becf9f 0%, rgba(255,255,255,0) 25%); 
}

.restaurant-bubble {
  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%; */
  width: 15%;
  /* transform: translate(-50%, -50%); */

  background-repeat: no-repeat;
  background-size: cover;

  cursor: pointer;
}
.restaurant-bubble p, #food-items .circle > p {
  color: #fff;
  margin: 0;
  padding: 0;
  text-align: center;

  width: 80%;
}
#food-bubbles-main .restaurant-bubble p {
  position: absolute;
  bottom: 0;
  left: 50%;

  transform: translate(-50%, -100%);

  font-weight: 400;
  font-size: 1.2em;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
}
#food-bubbles-right .restaurant-bubble .widget-content {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-weight: 600;
}
#food-items .circle > p {
  color: #333;
}

#food-bubbles-main {
  width: 50%;
  height: 40vh;
}
#food-bubbles-main .restaurant-bubble {
  float: left;
  position: absolute;
  width: 40vh;
  top: 0;
  transition: all 0.5s linear;
  /* transform: translate(-50%, -50%); */
  /* transform-origin: bottom; */
}

#food-bubbles-right {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  /* height: 100%; */
  width: 12%;
}
#food-bubbles-right .restaurant-bubble {
  position: relative;
  left: 20%;
  width: 60%;
  transform: none;
  transition: transform 0.5s linear;
  margin-bottom: 15px;
}
#food-bubbles-right .restaurant-bubble .bg {
  opacity: 0.7;
}

#food-bubbles-main #rb-0, #food-bubbles-main #rb-4 {
  z-index: 1;
  left: calc(10% - 14vh);
  top: 6vh;
  width: 28vh;
  filter: blur(4px);
}
#food-bubbles-main #rb-1, #food-bubbles-main #rb-3 {
  z-index: 2;
  left: calc(30% - 17vh);
  top: 3vh;
  width: 34vh;
  filter: blur(2px);
}
#food-bubbles-main #rb-2 {
  z-index: 3;
  left: calc(50% - 20vh);
}
#food-bubbles-main #rb-3 {
  left: calc(70% - 17vh);
}
#food-bubbles-main #rb-4 {
  left: calc(90% - 14vh);
}

#food-title, #food-title-restaurant {
  position: absolute;
  top: 15%;
  left: 50%;

  transform: translate(-50%, -100%);

  color: #fff;
  font-size: 1.8em;
  font-weight: 400;
}
#food-title {
  top: 15%;
  color: #333;
}

#menu-wrapper {
  position: absolute;
  top: 20%;
  left: 50%;

  width: 58%;
  height: 70%;

  padding-right: 20px;

  overflow: auto;

  transform: translateX(-50%);
}

#menu-wrapper.alter-height {
  height: 55%;
}
#cart.alter-height {
  height: 20%;
}

#restaurant-description {
  width: 85%;
  margin: 0 auto;

  text-align: center;
  font-size: 14px;
}
#restaurant-highlight {
  position: absolute;
  top: 15%;
  left: 3%;
  
  width: 230px;

  color: #fff;
}
#restaurant-highlight .title {
  font-weight: 700;
}
#restaurant-highlight .body {
  font-size: 10px;
}


#food-items {
  position: relative;
  width: 100%;
  /* height: 40%;
  min-height: 320px; */

  /* top: 55%; */
}
#food-items .food-items {
  width: 32%;

  position: absolute;
}
#food-items .food-items:nth-child(1) {
  top: 20px;
  left: 16%;
}
#food-items .food-items:nth-child(2) {
  top: 20px;
  right: 16%;
}
#food-items .food-items:nth-child(3) {
  top: 220px;
  left: 0;
}
#food-items .food-items:nth-child(4) {
  top: 220px;
  left: 50%;
  transform: translateX(-50%);
}
#food-items .food-items:nth-child(5) {
  top: 220px;
  right: 0;
}
#food-items h1 {
  text-align: center;
  font-size: 16px;

  margin-bottom: 0;
}
#food-items .food-items p {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 15px;
  text-align: center;
}
#food-items .food-items > p::after {
  position: relative;
  content: " ";
  display: block;
  width: 80%;
  left: 10%;
  height: 25px;
  margin-top: -25px;
  box-shadow: 0 10px 13px -10px rgba(0, 0, 0, 0.4);
}
#food-items .circle {
  width: 55px;
  position: relative;
  float: left;

  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
#food-items .circle {
  margin-right: 4%;
}
#food-items .circle:nth-child(3) {
  margin-right: 0;
}
.food-info-bubbles {
  display: flex;
  justify-content: center;
}
.food-info-bubbles .circle {
  background-color: #eee;
}
.food-info-bubbles .circle.food-carbon {
  background: none;
  box-shadow: none !important;
}
.food-info-bubbles .circle.food-price {
  font-size: 12px;
}
.food-info-bubbles .circle.food-addtocart {
  font-size: 20px;
  font-weight: 300;
}
#food-nav {
  width: 55%;
}
#food-bubbles #food-nav {
  width: 70%;
}
#food-items-prev {
  position: absolute;
  left: 0;
  top: 55%;

  transform: translateY(-50%);
  font-size: 70px;
}
#food-items-next {
  position: absolute;
  right: 0;
  top: 55%;

  transform: translateY(-50%);
  font-size: 70px;
}
#food-items .circle p {
  font-weight: 600;
  font-size: 1.2em;
}
#food-items .food-carbon p, #food-items .food-addtocart p {
  color: #90b888;
}

.food-addtocart, .food-addtocart p, .delete, #food-items-next, #food-items-prev, .restaurant-bubble p, #food-bubbles-right div, #checkout, #checkout div {
  cursor: pointer;
}

#cart {
  position: absolute;
  left: 50%;
  bottom: 2%;

  width: 35%;

  transform: translateX(-50%);
}
#cart table {
  width: 95%;
}
#cart .delete {
  width: 25px;
  color: #f00;
  font-size: 20px;
}
div#cart {
  text-align: center;
}

#checkout {
  position: absolute;
  bottom: 2%;
  left: 70%;

  width: 80px;
  background-color: #fff;
}
#checkout .widget-content {
  font-weight: 700;
}
#checkout-complete {
  font-size: 26px;
  font-weight: 600;
  text-align: center;

  color: #fff;
}
#checkout-bg1 {
  position: absolute;
  top: 45%;
  left: 58%;

  transform: translate(-50%, -50%);

  width: 25%;

  box-shadow: 0 30px 35px -15px rgba(0, 0, 0, 0.2);

  background-color: rgba(0, 0, 0, 0.03);
}
#checkout-bg2 {
  position: absolute;
  top: 55%;
  left: 42%;

  transform: translate(-50%, -50%);

  width: 25%;

  box-shadow: 0 -30px 35px -15px rgba(0, 0, 0, 0.2);

  background-color: rgba(0, 0, 0, 0.02);
}

.bg-1 {
  background-color: #f1d6b4;
}
.bg-1-2 {
  background-color: #e9ceac;
}
.bg-1-3 {
  background: #d49f70;
}
.bg-2 {
  background-color: #adb1ab;
}
.bg-2-2 {
  background-color: #a2a7a1;
}
.bg-2-3 {
  background: #505051;
}
.bg-3 {
  background-color: #bac19c;
}
.bg-3-2 {
  background-color: #b0b791;
}
.bg-3-3 {
  background: #7c7738;
}
.bg-4 {
  background-color: #e4bfac;
}
.bg-4-2 {
  background-color: #dab6a3;
}
.bg-4-3 {
  background: #cb7b5d;
}
.bg-5 {
  background-color: #c9d9a9;
}
.bg-5-2 {
  background-color: #becf9f;
}
.bg-5-3 {
  background: #84a24a;
}
.bg-end {
  background-color: #d7cfb9;
}
.bg-end-2 {
  background-color: #cfc8b1;
}

.bg-sticker-1 {
  background: #c7a577;
  background: linear-gradient(315deg, #c7a577 70%, rgb(255, 255, 255) 102%);
}
.bg-sticker-2 {
  background: #7c8578;
  background: linear-gradient(315deg, #7c8578 70%, rgb(255, 255, 255) 102%);
}
.bg-sticker-3 {
  background: rgb(121,126,62);
  background: linear-gradient(315deg, rgba(121,126,62,1) 70%, rgb(206, 209, 175) 102%);
}
.bg-sticker-4 {
  background: rgb(121,126,62);
  background: linear-gradient(315deg, rgba(121,126,62,1) 70%, rgb(206, 209, 175) 102%);
}
.bg-sticker-5 {
  background: #84995b;
  background: linear-gradient(315deg, #84995b 70%, rgb(255, 255, 255) 102%);
}

.restaurant-nav-enter-active, .restaurant-nav-leave-active {
  transition: all 0.5s linear;
  z-index: -1 !important;
  position: absolute !important;
}
.restaurant-nav-leave-active {
  position: absolute !important;
  z-index: -1;
}
.restaurant-nav-enter, .restaurant-nav-leave-to {
  opacity: 0;
}
.restaurant-nav-enter-to, .restaurant-nav-leave {
  opacity: 1;
}
.restaurant-nav-move {
  transition: all 0.5s linear;
}
/* .test-enter {
  transform: translateX(100%);
}
.test-enter-to {
  transform: none;
} */
</style>
