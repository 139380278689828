<template>
  <transition name="tr-opacity">
    <div id="subtitles">
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Subtitles',
};
</script>

<style scoped>
#subtitles {
  z-index: 960;

  position: absolute;
  bottom: 0;
  left: 50%;

  max-width: 100%;

  transform: translateX(-50%);

  padding: 5px 20px;

  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  white-space: pre;

  color: #fff;
  background-color: rgba(0, 0, 0, 1);

  font-size: 26px;
}

#subtitles:hover {
  opacity: 0.2;
}
</style>
