<template>
  <perfect-scrollbar id="day-planner">
    <div class="heading">
      Today
    </div>

    <div class="events-wrapper">
      <div class="rows">
        <div class="row double" />
        <div class="row double" />
        <div class="row double" />
        <div class="row double" />
        <div class="row double" />
        <div class="row double" />
      </div>
      <div class="events-list">
        <div class="event">
          Call with Anna
        </div>
        <div class="blank double" />
        <div class="blank" />
        <div class="event double">
          Venture Pitch
        </div>
        <div class="blank double" />
        <div class="event double">
          Lunch
        </div>
        <div class="blank" />
        <div class="event">
          Investment Window
        </div>
      </div>
      <div class="indicator" :style="indicatorPosition" />
    </div>
  </perfect-scrollbar>
</template>

<script>
export default {
  name: 'DayPlanner',
  data: function() {
    return {
      lineHeight: 26,
    };
  },
  computed: {
    indicatorPosition() {
      var positionMultiplier = 0;

      if (this.$store.state.eventTimer !== undefined) {
        if ('investment_start' in this.$store.state.events)
          positionMultiplier = 11.5;
        else if ('radio_watermead' in this.$store.state.events)
          positionMultiplier = 10.5;
        else if ('call_harry' in this.$store.state.events)
          positionMultiplier = 10;
        else if ('call_pitches' in this.$store.state.events)
          positionMultiplier = 7;
        else if ('calvermeinerbreaking' in this.$store.state.events)
          positionMultiplier = 3;
        else if ('barry_responsereceived' in this.$store.state.events)
          positionMultiplier = 2;
        else if ('call_adela' in this.$store.state.events)
          positionMultiplier = 1;
      }
      else {
        if ('investment_start' in this.$store.state.events)
          positionMultiplier = 11.5;
        else if ('food_reviewcarbon' in this.$store.state.events)
          positionMultiplier = 10;
        else if ('call_pitches' in this.$store.state.events)
          positionMultiplier = 5;
        else if ('precalvermeiner' in this.$store.state.events)
          positionMultiplier = 2;
        else if ('call_adela' in this.$store.state.events)
          positionMultiplier = 1;
      }

      return { top: (positionMultiplier * this.lineHeight - 1) + 'px' };
    }
  }
};
</script>

<style scoped>
#day-planner {
  position: absolute;
  top: 28%;
  left: 52%;

  width: 50vh;
  min-width: 200px;

  height: 70%;

  overflow: auto;

  z-index: inherit;

  padding-bottom: 20px;
  padding-right: 20px;
}

.heading {
  font-size: 24px;
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
}

.row {
  border-bottom: #fff 1px solid;
  margin-left: 15px;
}
.row:nth-child(1) {
  border-top: #fff 1px solid;
}

.events-wrapper {
  position: relative;

  overflow: none auto;
  font-size: 14px;
}

.events-list {
  position: absolute;
  top: 0;
  width: 100%;
}

.event {
  border-radius: 6px;
  height: 26px;

  width: calc(100% - 35px);

  color: #fff;
  background-color: #75958E;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);

  font-weight: 600;

  margin: 0 25px;
  padding: 3px 10px;
}

.blank {
  height: 26px;

  width: 94%;

  margin: 0 3%;
}

.event.double, .row.double, .blank.double {
  height: 52px;
}

.indicator {
  height: 2px;
  width: 100%;

  position: absolute;
  top: 0;

  background-color: #000;
}
.indicator::before {
  border-radius: 50%;

  width: 10px;
  height: 10px;
  background-color: #000;

  position: relative;
  top: -4px;
  left: 0px;

  content: " ";
  display: block;
}
</style>
