<template>
  <div id="app">
    <LoadingSpinner v-if="showLoadingSpinner" class="spinner" />
    <TakeAwayDocument v-if="playerData && !showLoadingSpinner" :player-investments-input="playerData.playerInvestments" :group-investments-input="groupInvestmentsData" />

    <div v-if="!showLoadingSpinner && !playerData">
      <h3 class="pos-center">
        Could not find player investments. Please verify the access code and ensure the user has completed the experience.
      </h3>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import TakeAwayDocument from './components/TakeAwayDocument.vue';
import LoadingSpinner from './components/LoadingSpinner.vue';
import possibleUserNames from '@/content/names.json';

export default {
  name: 'App',
  components: {
    TakeAwayDocument,
    LoadingSpinner
  },
  props: {
    playerid: String,
    queryString: Boolean
  },
  data: function() {
    return {
      playerData: null,
      groupData: null,
      groupInvestmentsData: {},
      showLoadingSpinner: true,
    };
  },
  mounted(){
    this.getData(this.playerid);
  },
  methods: {
    getData(id) {
      if (Object.keys(this.$route.query).length > 0) {
        console.log('Using query string data');

        let permittedKeys = ['nipsonic', 'nettech', 'tyne', 'calvermeiner', 'parskil', 'plantdeep', 'repeat', 'filtaer', 'lancing', 'ashmont', 'watermead'];
        let aliases = {'filtaer': 'filtair', 'repeat': 'peatssake'};

        this.playerData = {};
        this.playerData.playerInvestments = {};

        // TODO: Error handling
        for (let key in this.$route.query) {
          if (permittedKeys.includes(key)) {
            let keyval = key;

            if (key in aliases) {
              keyval = aliases[key];
            }

            this.playerData.playerInvestments[keyval] = parseInt(this.$route.query[key]);
          }
        }

        this.showLoadingSpinner = false;

        return;
      }

      if (this.$store.state.offlineDatabase) {
        console.log('OFFLINE, CODE: ' + id);

        window.ipcRenderer.invoke('getOfflineInvestments', id).then((res) => {
          if (Object.keys(res).length > 0) {
            console.log('Received investment choices');
            console.log(res);
            this.playerData = {};
            this.playerData.playerInvestments = res;
          }
        });

        setTimeout(() => {
          this.showLoadingSpinner = false;
        }, 10);

        return;
      }

      let headers = {};
      let dbURL = this.$store.state.offlineDbUrl;

      if (this.$store.state.isOfflineVersion && this.$store.state.offlineJWT) {
        headers = { 'Authorization': 'Bearer ' + this.$store.state.offlineJWT };
      }

      // TODO: Fix issue where 403 errors lead to infinite loading spinner
      axios.get(dbURL + 'players', { params: { id }, headers })
      .then(res => {
        console.log('Got Player Data');
        console.log(res.data[0]);
        this.playerData = res.data[0];
        axios.get(dbURL + 'groups', { params: { id: this.playerData.group }, headers })
        .then(res => {
          console.log('Got Group Data');
          console.log(res.data);
          this.groupData = res.data;
          this.groupData.forEach(groupplayer =>{
            this.groupInvestmentsData[groupplayer._id] = {investments: groupplayer.playerInvestments, name: possibleUserNames[groupplayer.placeInGroup]};
          });
          
          setTimeout(()=>{
            this.showLoadingSpinner = false;
          },10);
        })
        .catch(error => {
          console.log('Error from backend');
          console.log(error);
        }); 
      })
      .catch(error => {
        console.log('Error from backend');
        console.log(error);
        this.showLoadingSpinner = false;
      });

          
    }
  }
};
</script>

<style scoped>
#spinner {
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
}
</style>
