<template>
  <div id="spinner" key="spinner">
    <svg v-for="i in 16" :key="i" height="40" width="40" class="spinner-dot" :style="{'animation-delay':`${i*40}ms`,'animation-duration':`${Math.random()*2000+1000}ms`}">
      <circle cx="20" cy="20" r="20" fill="#29745a" />
    </svg>
  </div> 
</template>

<script>
export default {
  name: 'LoadingSpinner',
  data: function() {
    return {
    };
  }
};
</script>

<style scoped>
#spinner {
  transition: all 1s ease-out;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 50px);
  grid-template-rows: repeat(4, 50px);
}

@keyframes animate-spinner {
  0%,10%,90%,100% {
    opacity: 1;
  }
  40%,60% {
    opacity: 0;
  }
}
.spinner-dot {
  animation-name: animate-spinner;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
</style>