<template>
  <div id="splash">
    <div id="splash-menu">
      <div id="splash-menu-content">
        <div id="deloitte-logo">
          <img src="/img/splash/deloitte-black.svg" alt="Deloitte UK Logo" width="182" height="34">
        </div>

        <div id="links">
          <a href="#">
            Prepare for departure
          </a>
          <a href="#trailer">
            Trailer
          </a>
          <a href="#faq">
            FAQ
          </a>
        </div>

        <div id="swamp-logo">
          <img src="/img/splash/SwampNeon_1x1_BLK_800px.png" alt="Swamp Motel" width="160" height="74">
        </div>
      </div>
    </div>

    <div id="splash-header">
      <div id="header-intro">
        <h1 class="fg-accessibilitygreen">
          Wonder what a net zero future might be like?
        </h1>

        <p>
          Then get ready to <span class="fg-accessibilitygreen fw-600">Enter Net Zero</span>, an immersive experience taking you to an imagined world that has reached net zero.
        </p>
      </div>

      <div id="circle-logo" />
    </div>

    

    <div id="splash-content">
      <div id="col-left">
        <p>
          Whilst the experience is <strong>based on science and research</strong> with much of it inspired by Deloitte’s own insights, <strong>we’ve added a touch of creativity to bring it to life.</strong> It’s an imagined vision of what a net zero world might be like. 
        </p>
        <p>
          In this world, industries have evolved and responded to the threats and opportunities that climate change presents. You’ll explore what the future of <strong>energy and infrastructure; shipping and transport; food and agriculture; real estate; carbon capture and offsetting</strong> might be like.
        </p>
        <p>
          You take on the role of an investor and need to uncover different businesses to determine the investment opportunities with the <strong>strongest ESG credentials.</strong>
        </p>
      </div>
      <div id="col-right">
        <h2>Want to experience Enter Net Zero?</h2>
        <button @click="showRegistrationForm">
          Start Here
        </button>
        <h2>Already have an access code?</h2>

        <div>
          <form @submit.prevent="ticketCodeSubmit">
            <input v-model="playerid" type="text" placeholder="Enter Ticket Code">
            <input type="submit" value="Depart">
          </form>
        </div>
      </div>

      <div v-if="registrationFormVisible" id="registration-form" ref="registration">
        <!-- ADDED EMBED CODE HERE -->
        <script type="module" src="https://fv0deloitte.b-cdn.net/enter-net-zero/build/enter-net-zero.esm.js"></script>
        <enter-net-zero-entrypoint onward-url="/new/" asset-base="https://fv0deloitte.b-cdn.net/enter-net-zero/">
          <div slot="top">
            Add your details below to <b>ENTER NET ZERO</b>
          </div>
          <div slot="privacy">
            <em>How do we use your personal data? See our <a href="https://www2.deloitte.com/uk/en/legal/privacy.html" target="_blank">Privacy Summary</a>.</em>
          </div>
        </enter-net-zero-entrypoint>
        <!-- END EMBED CODE -->
      </div>

      <div id="trailer">
        <h1>Trailer</h1>
        <div class="video-container">
          <video poster="/img/trailer_preview.jpg" preload="auto" controls>
            <source :src="trailerSrcH265" type='video/mp4; codecs="hvc1.1.6.H120.90"'>
            <source :src="trailerSrcH264" type='video/mp4; codecs="avc1.4D4028"'>
          </video>
        </div>
      </div>

      <div id="faq">
        <h1>Frequently Asked Questions</h1>

        <h2>What is Enter Net Zero?</h2>
        <p>Enter Net Zero is an online immersive experience that places you in a simulated net zero future and allows you to explore some of the economic, governmental and social transformations that may have happened to make net zero possible.</p>

        <h2>How can I play?</h2>
        <p>Click the ‘Start Here’ button, input your details, and get ready to Enter Net Zero.</p>
        <p>Or reach out to your Deloitte representative and ask them to provide you with a unique access code.</p>

        <h2>How does the experience work?</h2>
        <p>Once the experience starts, you will log on to a desktop from a net zero future.</p>
        <p>You assume the role of an investor and are given a task by your boss. To complete this task you will need to interact with many of the desktop’s features: email inbox, news hub, investor tool, weather report, radio, carbon counter and food ordering services. By interacting with the desktop you’ll absorb information about changes to society and business in a net zero future.</p>
        <p>As the experience comes to a close, you are asked to put your learnings into practice as you invest a fund in different businesses and projects.</p>

        <h2>How long does it take?</h2>
        <p>Gameplay times can vary as some aspects are driven by the user. Experiences usually last 30-40 minutes.</p>

        <h2>Accessibility information</h2>
        <p>The experience is fully subtitled.</p>

        <h2>What should I do if I’m stuck?</h2>
        <p>Please bear in mind that Enter Net Zero requires you, the player, to sleuth around investigating companies that might provide an interesting investment opportunity. The gameplay is based on looking for clues and following leads, the information will not always come straight to you - that would be too easy! So, in the first instance, if you are stuck try exploring the desktop to see what you can find - the email inbox and news hub provide plenty of clues and information.</p>
        <p>Remember, your objective is to find businesses that would be strong investments for when the “investment window” opens later in the game. To really uncover how sustainable they are you’ll also need to do some research on them. (Note: all gameplay and research takes place within the site. You do not need to search on separate tabs.)</p>
        <p>If you are still unsure about your next move, try looking on the “To Do” list as this will always prompt you towards the next activity.</p>

        <h2>I am having some technical problems, help!</h2>
        <p>Enter Net Zero has been designed to be played on a laptop or desktop computer, not a tablet or phone. It can be accessed by many different internet browsers but has been optimised for Chrome. We also recommend you play in full-screen mode. For the best possible experience, please make sure your speakers are working correctly as Enter Net Zero includes audio content to enhance the experience. All of the content which is crucial for getting through the experience is subtitled.</p>
        <p>Please bear in mind that the experience requires thought and decision making from you, the player, and so next steps will not always be obvious. For advice on how to progress through the game please see information under “What should I do if I get stuck?”</p>
        <p>If a technical problem arises you can contact your Deloitte representative who may be able to guide you on next steps.</p>

        <h2>What happens if I can’t log in?</h2>
        <p>If you followed a link to Enter Net Zero from your client rep, please contact them to verify the validity of the code. If your code was entered through this page, ensure that the six character code was correctly copied into the form.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Splash',
    data() {
        return {
            playerid: '',
            registrationFormVisible: false,
        };
    },
    computed: {
      trailerSrcH265() {
        if (this.$store.state.isOfflineVersion)
          return 'local-asset://video/h265/ENZ_Trailer_V9_EmmetAudio.mp4';

      return '/video/h265/ENZ_Trailer_V9_EmmetAudio.mp4';
      },
      trailerSrcH264() {
        if (this.$store.state.isOfflineVersion)
          return 'local-asset://video/h264/ENZ_Trailer_V9_EmmetAudio.mp4';

      return '/video/h264/ENZ_Trailer_V9_EmmetAudio.mp4';
      }
    },
    created() {
      // Check WebP support and add 'webp' or 'no-webp' class to body tag
      if (support_format_webp) {
        document.body.classList.add('webp');
      }
      else {
        document.body.classList.add('no-webp');
      }
    },
    mounted() {
      // Setup for Electron version of the experience.
      // NOTE: REQUIRED EVERY TIME EXPERIENCE RELOADS
      // Otherwise settings will get lost as Vuex resets
      if (this.$store.state.isOfflineVersion && !('goToPage' in window.ipcRenderer._events)) {
        window.ipcRenderer.on('goToPage', (event, page) => {
          this.$router.push(page);
        });
      }

      if (this.$store.state.isOfflineVersion) {
        window.ipcRenderer.invoke('getJWT').then((res) => {
          this.$store.commit('setOfflineToken', res.token);

          if (res.token === '')
            this.$store.commit('setOfflineDatabase', true);
          else
            this.$store.commit('setOfflineDatabase', false);
        });
      }
    },
    methods: {
      ticketCodeSubmit() {
        var skipIntro;
        var tmp = this.playerid.trim();

        if (tmp.length === 0)
          return;

        if (tmp.endsWith('/skip')) {
          skipIntro = 'skip';
          tmp = tmp.replace('/skip', '');
          console.log('Changing intro skip');
        }

        this.$router.push({name: 'Landing', params: { id: tmp, skipIntro }});
      },
      showRegistrationForm() {
        this.registrationFormVisible = true;
        this.$nextTick(()=>{
        const el = this.$refs.registration;
          if (el) {
            el.scrollIntoView({ behavior: 'smooth' });
          }
        });
      }
    }
};

function support_format_webp()
{
  if (navigator.userAgent.indexOf('Safari') > -1)
    return false;

  var elem = document.createElement('canvas');

  if (elem.getContext && elem.getContext('2d'))
  {
    // was able or not to get WebP representation
    return (elem.toDataURL('image/webp').indexOf('data:image/webp') == 0);
  }
  else
  {
    // very old browser like IE 8, canvas not supported
    return false;
  }
}
</script>

<style>
#splash * {
  user-select: text;
}
</style>

<style scoped>
#splash {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 400;
  font-size: 18px;

  background-color: #fff;

  overflow: auto;
  height: 100%;
}

#splash a {
  color: #000;
  font-weight: 600;
}

#splash-menu {
  position: fixed;
  top: 0;
  left: 0;

  height: 94px;
  width: 100%;
  min-width: 1260px;

  background-color: #fff;

  z-index: 2;
}

#splash-menu a {
  display: inline-block;

  margin-right: 40px;
  padding: 10px 0;

  color: #000;
  text-decoration: none;
  font-size: 20px;
  font-weight: 400;
}

#splash-menu a:hover {
  text-decoration: underline;
}

#splash-menu-content {
  position: absolute;
  top: 10px;
  left: 0;
}

#splash-menu-content {
  width: calc(100% - 250px);
  margin: 0 125px;
}

#splash-content {
  width: 100%;
  padding: 0 125px;
}

#deloitte-logo, #swamp-logo, #splash-menu #links {
  position: absolute;
  display: inline-block;
}

#deloitte-logo {
  top: 20px;
  left: 0;
}

#swamp-logo {
  top: 0;
  right: 0;
}

#splash-menu #links {
  top: 13px;
  left: 250px;
}

#splash-header {
  position: absolute;
  top: 94px;
  left: 0;

  width: 100%;
  min-width: 1260px;
  height: 506px;

  background-color: #eee;
  background-image: url("/img/splash/SMxDCCP.png");
  background-size: cover;
  background-position-y: -120px;
}

.webp #splash-header {
  background-image: url("/img/splash/SMxDCCP.webp");
}

#splash-header img {
  width: 100%;
  height: 500px;

  background-size: cover;
}

#circle-logo {
  position: absolute;
  top: 0;
  right: 8.5%;

  width: 500px;
  height: 500px;

  background-image: url("/img/splash/ENZ-Identity-WHT-01-05.png");
  background-size: cover;
}

#header-intro {
  position: absolute;
  top: 50%;
  left: 125px;
  width: 600px;
  transform: translateY(-50%);
}
#header-intro h1 {
  font-size: 24px;

  font-weight: 800;
}
#header-intro p {
  font-size: 20px;
}

#splash-content {
  position: absolute;
  top: 600px;
  left: 0;
  min-width: 1260px;

  padding-top: 30px;

  background-color: #fff;
}

#splash-content #col-left, #splash-content #col-right {
  position: relative;
  float: left;
  width: 50%;
}

#splash-content #col-left {
  padding-right: 20px;
}
#splash-content #col-right {
  padding-left: 20px;
  text-align: center;
}

#col-right input[type="text"] {
  /* background-color: #ddd; */

  background: rgb(220,220,220);
  background: linear-gradient(0deg, rgba(220,220,220,0.4) 0%, rgba(255,255,255,0) 50%, rgba(220,220,220,0.4) 100%);
  
  height: 40px;
}
#col-right input[type="submit"], button {
  background-color: #26890D;
  color: #fff;

  border: 0;
  border-radius: 14px;
  padding: 8px 15px;
  margin-left: 20px;

  font-size: 16px;
  font-weight: 600;

  cursor: pointer;
}
#col-right h3 {
  margin-top: 45px;
}


#faq, #trailer, #registration-form {
  position: relative;
  clear: both;
  display: inline-block;
}

#trailer {
  width: 100%;
}

#trailer .video-container {
  display: flex;
  justify-content: center;
}

#trailer video {
  display: block;
  position: relative;
  width: auto;
  max-height: 60vh;
  max-width: 100%;
  flex: 0 0 auto;
}

#trailer {
  padding-bottom: 40px;
}
#faq h2, #faq p {
  margin-left: 50px;
  margin-right: 50px;
}
#faq h2 {
  margin-bottom: 6px;
}
#faq p {
  margin-top: 6px;
}

#registration-form {
  padding-top: 48px;
  padding-bottom: 48px;
  scroll-margin-top: 48px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
