<template>
  <!-- <div id="caller-widget" class="circle call-bg" @click.prevent.stop="acceptCall"> -->
  <div id="caller-wrapper">
    <div v-show="calling" id="caller-bg-opaque" class="fullscreen" />

    <div v-show="callPlaying" id="caller-bg-blank" class="fullscreen" />

    <div v-if="callPlaying" id="caller-widget" class="circle call-bg circle-extension" style="z-index: 951" :class="{ 'pitches-bg': currentCall === 'call_pitches' }" />

    <video ref="video-dom" :src="videoSrc" class="call preload" :class="{ 'call-positioning': callPlaying }" preload="auto" tabindex="-1" @ended="onCallEnded">
      <track ref="videoVTT" src="/video/subtitles/Anna Intro.vtt" type="subtitles" srclang="en" label="English" default @cuechange="setSubtitlePosition">
    </video>

    <div v-if="callPlaying && $store.state.devEnvironment" class="circle end-call bg-red" @click="onCallEnded">
      <div class="widget-content">
        Skip
      </div>
    </div>

    <div v-if="callPlaying && !$store.state.pitches" class="call-button-wrapper circle bg-red call-end-wrapper">
      <img src="/img/icons/call_accept.svg" alt="End Call Button" class="call-end">
    </div>

    <div v-if="callPlaying" class="circle hold-call" @click="holdCall">
      <div class="widget-content">
        Hold
      </div>
    </div>

    <div v-if="$store.state.calling" id="caller-widget" class="circle call-bg">
      <audio ref="ringtone" :src="ringtoneSrc" loop />
      <div class="widget-content">
        <h1>Call function</h1>
      </div>

      <div v-show="$store.state.calling" id="calling" class="circle">
        <div id="calling-inner" class="circle call-bg" />
      </div>
      <div v-show="$store.state.calling" id="caller-id-wrapper" class="circle">
        <div id="caller-id-img" class="circle" :style="{ 'background-image': callerIDimg }" />

        <div id="caller-id">
          {{ callerID }}
        </div>
        <div v-show="onHold" id="caller-onhold">
          (On Hold)
        </div>
      </div>
    </div>
    <div v-show="!callPlaying && $store.state.calling" class="call-button-wrapper circle bg-green" @click.prevent.stop="acceptCall">
      <img src="/img/icons/call_accept.svg" alt="Accept Call Button" class="call-accept">
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';

export default {
  name: 'Caller',
  data: () => {
    return {
      // calling: true,
      onHold: false,
      videoSources: {
        'call_adela': 'Anna_Intro_v0.mp4',
        'call_pitches': 'VenturePitch_EmmetAudio_reencode_v2.mp4',
        'call_harry': 'Harry_Kitchen_v0.mp4',
        'call_adela_finale': 'Anna_Outro_v0.mp4',
      },
      videoSubtitles: {
        'call_adela': 'Anna Intro.vtt',
        'call_pitches': 'Pitches.vtt',
        'call_harry': 'Harry Kitchen.vtt',
        'call_adela_finale': 'Anna Outro.vtt',
      },
      nextCall: {
        'call_adela': 'call_pitches',
        'call_pitches': 'call_harry',
        'call_harry': 'call_adela_finale',
        'call_adela_finale': '',
      },
      callerIDs: {
        'call_adela': 'Anna Russell',
        'call_pitches': 'Venture Pitch',
        'call_harry': 'Harry Lessons',
        'call_adela_finale': 'Anna Russell',
      },
    };
  },
  computed: {
    callPlaying() {
      return this.$store.state.callPlaying;
    },
    currentCall() {
      return this.$store.state.currentCall;
    },
    videoSrc() {
      if (this.$store.state.isOfflineVersion) {
        return 'local-asset://video/h264/' + this.videoSources[this.currentCall];
      }
      const video = document.createElement('video');
      if (video.canPlayType('video/mp4; codecs="hvc1.1.6.H120.90"')){
        return '/video/h265/' + this.videoSources[this.currentCall];
      } else {
        return '/video/h264/' + this.videoSources[this.currentCall];
      }
    },
    videoVTT() {
      if (this.videoSubtitles[this.currentCall] === '')
        return;

      console.log('Subtitles found: ' + this.videoSubtitles[this.currentCall]);

      return '/video/subtitles/' + this.videoSubtitles[this.currentCall];
    },
    ringtoneSrc() {
      if (this.$store.state.isOfflineVersion)
        return 'local-asset://audio/effects/ringtone_v5.2.mp3';

    return '/audio/effects/ringtone_v5.2.mp3';
    },
    callerID() {
      return this.callerIDs[this.currentCall];
    },
    callerIDimg() {
      var cID = 'url(\'/img/calls/' + this.currentCall.replace('call_', '');

      if (this.currentCall === 'call_adela_finale')
        cID = cID.replace('_finale', '');

      if (this.$store.state.isIE11 || this.$store.state.isSafari)
        cID += '.jpg';
      else
        cID += '.webp';

      return cID + '\')';
    },
    calling() {
      return this.$store.state.calling;
    }
  },
  watch: {
    callPlaying: function() {
      if (this.$refs['video-dom'].paused && this.$store.state.callPlaying) {
        console.log('APP: Playing call');
        this.$refs['video-dom'].play();
        setTimeout(() => { this.$refs['ringtone'].pause(); }, 500);

        this.$refs['video-dom'].style.left = '0';
      }
      else if (!this.$store.state.callPlaying) {
        this.$refs['video-dom'].pause();
        this.$refs['video-dom'].style.left = '';

        // this.$refs['video-dom'].style.display = 'none';
      }
    },
    calling: function() {
      if (this.$store.state.callAutoplay && this.calling) {
        this.$store.commit('playVideoCall');
      }
      else if (this.calling) {
        setTimeout(() => { this.$refs['ringtone'].play(); }, 500);
      }
    },
    videoVTT: function(newVTT) {
      this.$refs['videoVTT'].src = newVTT;
    }
  },
  updated() {
    gsap.to('#calling', {
      duration: 1,
      scale: 1.2,
      repeat: -1,
      ease: 'back',
      yoyo: true,
    });
  },
  methods: {
    acceptCall() {
      this.$store.commit('playVideoCall');
    },
    onCallEnded() {
      this.$store.dispatch('endCall', this.currentCall);
      this.onHold = false;
      // this.currentCall = this.nextCall[this.currentCall];
      this.$store.commit('setCall', this.nextCall[this.currentCall]);
      this.$store.commit('updateVideoSubtitle', '');
    },
    setSubtitlePosition() {
      this.$refs['videoVTT'].track.mode = 'hidden';

      try {
        this.$store.commit('updateVideoSubtitle', this.$refs['videoVTT'].track.activeCues[0].text);
      }
      catch (TypeError) {
        return;
      }

      // console.log('New subtitle row');
    },
    holdCall() {
      this.$store.commit('togglePlayVideoCall');

      if (this.$store.state.callPlaying)
        this.onHold = false;
      else
        this.onHold = true;
    },
  }
};
</script>

<style scoped>
#caller-bg-opaque {
  background-color: #eee;
  z-index: 945;
}
#caller-wrapper video {
  height: 100%;
  width: auto;
  max-width: 100%;
}

#caller-bg-blank {
  background-color: #e7e7e7;
  z-index: 951;
}

.call-positioning {
  left: 50% !important;
  transform: translateX(-50%);
}

#caller-widget {
  color: #fff;

  width: 90.87%;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  z-index: 950;
}

#caller-widget.call-bg {
  /* background-color: #6ca46a;
  background-color: #6ba26b;
  background: radial-gradient(circle, rgba(108,164,106,1) 0%, rgba(108,164,106,1) 90%, rgba(100,155,100,1) 100%); */

  background: rgb(108,164,106);
  background: radial-gradient(#649b64 45%, #6ba26b 55%); 
}

#caller-widget.pitches-bg {
  background: #79c9c9;
}

#calling {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;

  width: 50%;
  background-color: gray;
  filter: blur(20px);
}
#calling-inner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 85%;

  text-align: center;
  color: #fff;
}
#caller-id-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 60%;
}
#caller-id {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  font-size: 2em;

  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}
#caller-id-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* background-image: url('/img/calls/adela.png'); */
  background-position-x: 50%;
  background-size: cover;
  width: 100%;

  filter: blur(20px);
}
#caller-onhold {
  position: absolute;
  left: 50%;
  top: calc(50% + 40px);
  transform: translate(-50%, -50%);

  font-size: 16px;

  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.call-button-wrapper {
  position: absolute;
  top: 75%;
  left: calc(50% - 35px);

  width: 70px;

  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

  z-index: 953;
}
.call-button-wrapper img {
  width: 25px;
  height: 25px;

  position: absolute;
  top: 50%;
  left: 50%;
}
.bg-green {
  background-color: #85A59E;
}
.bg-red {
  background-color: #F18682;
}
.call-accept {
  transform: translate(-50%, -50%) rotate(135deg);
  cursor: pointer;
}
.call-button-wrapper.bg-green {
  animation: button-pop 2s linear;
  animation-iteration-count: infinite;

  cursor: pointer;
}
.call-end-wrapper {
  cursor: none;
}
.call-end {
  transform: translate(-50%, -50%) rotate(-90deg);
  cursor: none;
}

/* For Hold button */
.hold-call {
  position: absolute;
  top: 75%;
  left: calc(50% + 60px);

  width: 70px;

  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

  z-index: 953;

  font-size: 16px;
  font-weight: 600;

  cursor: pointer;

  background-color: #aaa;

  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
}

.hold-call .widget-content {
  color: #fff;
  
  cursor: pointer;
}

/* For Skip button in devEnvironment */
.end-call {
  z-index: 952;
  width: 80px;

  font-size: 16px;
  font-weight: 600;

  position: absolute;
  top: 40px;
  right: 40px;

  transform: translateX(-50%);

  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);

  cursor: pointer;
}

.end-call .widget-content {
  color: #fff;
  
  cursor: pointer;
}


.circle-extension {
  max-width: 150vh;
}
</style>
