<template>
  <transition name="email-appear" appear>
    <div id="email-window" class="fullscreen">
      <div id="email-window-title">
        Email
      </div>
      <div id="email-list">
        <transition-group name="rolodex-transition" tag="div">
          <div v-for="(item, index) in activeEmails" :key="keys[index]" class="email-title" :class="'offset-' + offsets[index]" @click="setEmailIndex(index)">
            <div v-if="item && !$store.state.emailLogic[item].read" class="email-read-marker unread">
              •
            </div>
            <div v-else-if="item" class="email-read-marker">
              ◦
            </div>
            <div class="email-title-text" :class="{ unread: item && !$store.state.emailLogic[item].read }">
              {{ item ? emails[item].title : '\xa0' }}
            </div>
            <div class="email-from">
              {{ item ? emails[item].from : '\xa0' }}
            </div>
          </div>
        </transition-group>
      </div>

      <div id="email-body">
        <transition-group name="show-email" tag="div">
          <perfect-scrollbar v-for="(item, index) in $store.state.emailsList" v-show="showEmail == index && !newEmailDialog" :ref="'emailmsg-' + index" :key="item" class="email-message" @wheel.stop="handleTextScroll">
            <div class="email-message-body" @click.stop.prevent="openLink" v-html="replaceUserPlaceholder(emails[$store.state.emailsList[index]].message)" />

            <transition-group name="tr-opacity">
              <div v-if="!('response' in $store.state.emailLogic[$store.state.emailsList[index]]) && responseIndex === undefined" key="reply-btn" class="email-response-button circle" @click="respondTo(index)">
                <div class="widget-content">
                  Reply
                </div>
              </div>
              <div v-else-if="responseIndex !== undefined && !newEmailDialog" id="email-response-area" key="reply-area">
                <textarea ref="textarea" v-model="emailResponseBody" :placeholder="'Reply to ' + emails[$store.state.emailsList[responseIndex]].from" @wheel.stop="handleTextScroll" />

                <span id="email-buttons">
                  <a class="tooltip tooltip-top" @click="sendResponse">
                    <img src="/img/icons/send.svg" alt="Send Email">
                    <span class="description-noarrow">
                      Send
                    </span>
                  </a>
                  <a class="tooltip tooltip-top" @click="cancelResponse">
                    <img src="/img/icons/cancel.svg" alt="Cancel Reply">
                    <div class="description-noarrow">
                      Cancel
                    </div>
                  </a>
                </span>
              </div>
              <div v-else key="reply-body" class="email-response">
                {{ $store.state.emailLogic[$store.state.emailsList[index]].response }}
              </div>
            </transition-group>
          </perfect-scrollbar>
        </transition-group>

        <div v-if="newEmailDialog" id="new-email">
          You have a new email from {{ emails[$store.state.emailsList[0]].from }}

          <div class="circle email-bg" @click.prevent.stop="showNewEmail">
            <div class="widget-content">
              Show
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import emailContentJSON from '@/content/emails.json';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'EmailRolodex',
  data: function() {
    return {
      emails: emailContentJSON,
      showEmail: this.$store.state.showEmail,
      timerID: undefined,
      responseIndex: undefined,
      emailResponseBody: '',
      keys: [...Array(9).keys()],
      itemLimit: 7,
    };
  },
  computed: {
    showEmailChanged: function() {
      return this.$store.state.showEmail;
    },
    newEmailDialog: function() {
      return this.$store.state.newEmailDialog;
    },
    activeEmails: function() {
      var emailsList = [];
      var halfListLength = (this.itemLimit - 1) / 2 + 1;
      var idx;

      for (let i = -halfListLength; i <= halfListLength; i++) {
        idx = this.showEmail + i;

        if (idx < 0 || idx > this.$store.state.emailsList.length) {
          emailsList.push('');
        }
        else {
          emailsList.push(this.$store.state.emailsList[idx]);
        }
      }

      return emailsList;
    },
    offsets: function() {
      var offsetsList = [];

      for (let i = 0; i < this.itemLimit + 2; i++) {
        if (i <= (this.itemLimit + 1) / 2)
          offsetsList.push(i);
        else
          offsetsList.push((this.itemLimit + 1) - i);
      }

      return offsetsList;
    }
  },
  watch: {
    showEmailChanged(newIndex) {
      var diff = newIndex - this.showEmail;

      // console.log('New index: ' + newIndex);
      // console.log('Original index: ' + this.showEmail);
      // console.log('Index from store: ' + this.showEmailChanged);

      this.showEmail = this.showEmailChanged;

      if (this.responseIndex !== undefined) {
        this.responseIndex += diff;
      }
    }
  },
  mounted: function() {
    this.activeEmails.forEach(() => {
      this.keys.push(uuidv4());
    });

    // this.$store.commit('emailRead', this.$store.state.emailsList[this.showEmail]);
    // this.updateRead();
  },
  beforeMount() {
    window.addEventListener('wheel', this.handleScroll);
    // this.$refs.eMW.addEventListener('wheel', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('wheel', this.handleScroll);
  },
  methods: {
    handleScroll(event) {
      var direction = 0;

      if (event.deltaY > 0)
        direction = 1;
      else if (event.deltaY < 0)
        direction = -1;
      else
        return;

      if (!this.timerID) {
        this.scrollEr(direction);
        this.timerID = setTimeout(() => { this.timerID = undefined; }, 500);
      }
    },
    scrollEr(direction) {
      this.timerID = undefined;

      if (direction == 1) {
        if (this.showEmail + 1 === this.$store.state.emailsList.length)
          return;

        this.showEmail = this.showEmail + 1;

        this.keys.splice(0, 1);
        this.keys.push(uuidv4());
      }
      else {
        if (this.showEmail === 0)
          return;

        this.showEmail = this.showEmail - 1;

        this.keys.splice(-1, 1);
        this.keys.splice(0, 0, uuidv4());
      }

      this.$store.commit('setAppDisplay', { app: 'email', item: this.showEmail });
      this.$store.commit('hideEmailDialog');
      this.updateRead();

      if (this.responseIndex !== undefined) {
        this.cancelResponse();
      }
    },
    setEmailIndex(offset) {
      var middle = (this.itemLimit + 1) / 2;
      var diff = offset - middle;

      this.showEmail = (this.showEmail + diff + 3 * this.$store.state.emailsList.length) % this.$store.state.emailsList.length;
      var tmpList = [];

      for (var i in [...Array(Math.abs(diff))]) {
        tmpList[i] = uuidv4();
      }

      if (diff >= 1) {
        this.keys.splice(0, diff);
        this.keys.push(...tmpList);
      }
      else {
        this.keys.splice(diff, -diff);
        this.keys.splice(0, 0, ...tmpList);
      }

      this.$store.commit('setAppDisplay', { app: 'email', item: this.showEmail });
      this.$store.commit('hideEmailDialog');
      this.updateRead();

      if (this.responseIndex !== undefined) {
        this.cancelResponse();
      }
    },
    updateRead() {
      var emailItem = this.$store.state.emailLogic[this.$store.state.emailsList[this.showEmail]];

      // console.log(this.$store.state.emailsList[this.showEmail]);

      if (emailItem.read)
        return;

      this.$store.commit('emailRead', this.$store.state.emailsList[this.showEmail]);
      this.$emit('update-status', this.$store.state.emailsList[this.showEmail]);
    },
    replaceUserPlaceholder(text) {
      if (!text)
        return '';
      
      return text.replace('$username', this.$store.state.username);
    },
    respondTo(index) {
      // this.$refs['emailmsg-' + index][0].style.display = 'none';
      this.responseIndex = index;

      setTimeout(() => {
        this.$refs['textarea'].focus();
      }, 200);
    },
    cancelResponse() {
      // this.$refs['emailmsg-' + this.responseIndex][0].style.display = '';
      this.responseIndex = undefined;
      this.emailResponseBody = '';
    },
    sendResponse() {
      if (this.emailResponseBody === '')
        return;

      this.$store.dispatch('emailResponse', {
        id: this.$store.state.emailsList[this.showEmail],
        body: this.emailResponseBody,
      });

      this.cancelResponse();
    },
    openLink(evt) {
      if (!evt.target.dataset.link)
        return;

      var app = 'investor';

      if (evt.target.dataset.app)
        app = evt.target.dataset.app;

      this.$store.commit('setAppDisplay', { app: app, item: evt.target.dataset.link });
      this.$store.dispatch('setView', app);
      this.$store.commit('setBackButton', 'email');
    },
    handleTextScroll(evt) {
      // Fixes an issue where longer texts weren't scrollable
      if (evt.target.parentElement.scrollHeight > evt.target.parentElement.clientHeight) {
        evt.stopPropagation();
      }

      if (evt.deltaY >= 0) {
        evt.target.parentElement.scrollTop += 40;
      }
      else {
        evt.target.parentElement.scrollTop -= 40;
      }
    },
    showNewEmail() {
      this.updateRead();
      this.$store.commit('hideEmailDialog');
    },
  }
};
</script>

<style>
.email-message.ps .ps__rail-y::before {
  background-color: #41615a !important;
}

.email-message.ps .ps__thumb-y, .email-message.ps .ps__rail-y:hover .ps__thumb-y {
  background-color: #41615a;
}
</style>

<style scoped>
.email-appear-enter-active {
  transition: all 0.5s linear;
  transition-delay: 1s;
}
.email-appear-enter {
  opacity: 0;
}
#email-window {
  color: #fff;
  font-weight: 400;

  z-index: 100;
}
#email-window-title {
  position: absolute;
  top: calc(5% + 28px);
  right: 15%;
  font-size: 1.5em;
  font-weight: 200;
  cursor: default;
  transform: translateY(-50%);

  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}
#email-close {
  position: absolute;
  top: 5%;
  right: 10%;
  font-size: 3em;
  font-weight: 200;
  cursor: pointer;
}
#email-response-area {
  position: relative;
  left: 50%;

  width: 80%;
  height: 30vh;

  transform: translateX(-50%);
}
#email-response-area textarea {
  width: 100%;
  height: 25vh;

  margin-bottom: 10px;

  font-size: 14px;
}
#email-response-area #email-buttons {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

#email-response-area a {
  margin: 10px 10px;
}
#email-response-area a img {
  cursor: pointer;
}

#new-email {
  position: absolute;
  top: 50%;
  left: 50%;

  width: 100%;

  transform: translate(-50%, -50%);

  text-align: center;

  font-size: 20px;
}
#new-email .circle {
  width: 80px;

  margin-top: 20px;

  position: relative;
  left: calc(50% - 40px);

  font-size: 14px;
  font-weight: 600;

  cursor: pointer;
}
#new-email .circle .widget-content {
  cursor: pointer;
}

.email-response-button {
  margin-top: 20px;
  text-decoration: underline;
  cursor: pointer;

  width: 90px;
  left: calc(50% - 45px);

  margin-bottom: 10px;

  background-color: #85A59E;

  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);

  position: relative;
}
.email-response-button .widget-content {
  cursor: pointer;
  font-weight: 600;
}

.email-response {
  border-top: 1px solid #eee;
  margin-top: 20px;
  padding-top: 20px;
}

#email-list {
  position: absolute;
  top: 50%;
  left: 10%;

  width: 37%;

  transform: translate(0, -50%);
  font-size: 1.3em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}

.email-read-marker {
  position: absolute;
  left: 0;
  font-size: 1.5em;
  font-weight: 400;
  transform: translate(-18%, -19%);
}

.email-read-marker.unread {
  font-size: 1.8em;
  transform: translate(-18%, -19%);
  font-weight: 600;
}

.email-title {
  transition: opacity 1s linear;
  /* transition: color 1s linear; */
  /* transition: all 1s linear; */
  margin-bottom: 10px;
  width: 84%;
}
.email-title-text {
  transform: translateX(20px);
}
.email-from {
  font-size: 0.7em;
}

.email-title-text.unread {
  font-weight: 700;
}

#email-body {
  position: absolute;
  left: 55%;
  top: 50%;
  width: 38%;
  height: 70%;

  transform: translateY(-50%);
  font-size: 16px;
}

.email-message {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  overflow: auto; 
  white-space: pre-line;
  width: 100%;
  max-height: 100%;
  padding-right: 30px;
}
.email-message-body {
  user-select: text;
}

.email-message div:first-child {
  margin-bottom: 20px;
}
.email-message a {
  position: relative;
  left: calc(50% - 20px);
}
.email-message a img {
  cursor: pointer;
}
.email-message a .description-noarrow {
  left: calc(50% - 30px);
}

.show-email-enter-active {
  transition: all 1s linear;
  transition-delay: 0.3s;
}
.show-email-leave-active {
  transition: all 0.5s linear;
}
.show-email-enter, .show-email-leave-to, .show-email-leave {
  opacity: 0;
}
.show-email-enter-to {
  opacity: 1;
}

.rolodex-transition-enter-active, .rolodex-transition-leave-active, .rolodex-transition-move {
  transition: all 0.5s linear;
}
.rolodex-transition-enter, .rolodex-transition-leave-to {
  opacity: 0
}
.rolodex-transition-leave-active {
  position: absolute;
}

.offset-0 {
  opacity: 0;
}
.offset-1 {
  position: relative;
  left: 4%;
  opacity: 0.1;
}
.offset-2 {
  position: relative;
  left: 8%;
  opacity: 0.25;
}
.offset-3 {
  position: relative;
  left: 12%;
  opacity: 0.5;
}
.offset-4 {
  position: relative;
  left: 16%;
  opacity: 1;
  font-weight: 600;
}

.border-top {
  /* border-top: #fff 2px solid; */
  box-shadow: inset 0 15px 20px -20px rgba(0, 0, 0, 0.3);
  margin-top: 10px;
  padding-top: 30px;
}

.border-bottom {
  box-shadow: 0 15px 15px -15px rgba(0, 0, 0, 0.5);
  margin-bottom: 25px;
  padding-bottom: 10px;
}

.tr-opacity-leave-active {
  position: absolute !important;
}
#email-response-area.tr-opacity-leave-active {
  transition: opacity 1s linear;
  display: none;
}


.offset-4 .email-title-text, .offset-4 .email-from {
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
}
#new-email, .email-response-button {
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
}
.email-message-body, .email-response {
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
}
</style>
