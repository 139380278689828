<template>
  <div id="notifier" />
</template>

<script>
import { useSound } from '@vueuse/sound';

export default {
  name: 'Notifier',
  setup() {
    // Sprite map for notifications
    const spriteMap = {
      emailsent: [0, 995],
      emailreceived: [1000, 1800],
      emailselect: [3000, 350],
      taskcompleted: [4000, 1250],
      windowopen: [5300, 1100],
      windowclose: [6400, 880],
      weather: [7350, 4500],
    };

    const playNotification = useSound('/audio/notifications_v5.2.mp3', { sprite: spriteMap });

    return { playNotification };
  },
  data: () => {
    return {
      notificationLength: {
        emailsent: 940,
        emailreceived: 950,
        taskcompleted: 1200,
        windowopen: 1100,
        windowclose: 880,
        weather: 4950,
      },
    };
  },
  computed: {
    notification() {
      // if (this.$store.state.notifications.length === 0 || this.$store.state.currentView === 'default') {
      if (this.$store.state.notifications.length === 0) {
        return [];
      }

      return this.$store.state.notifications;
    },
  },
  watch: {
    notification: function (val) {
      if (val.length === 0) {
        return;
      }

      this.playNotification.play({ id: this.notification[0] });

      setTimeout(() => {
      this.$store.commit('updateNotificationStack');
      }, this.notificationLength[this.notification[0]]);
    }
  },
};
</script>
