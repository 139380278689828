<template>
  <div id="bg-main">
    <div id="bg-wrapper" />
  </div>
</template>

<script>
export default {
  name: 'MainBackground',
};
</script>

<style scoped>
#bg-main {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  z-index: -1;
}

#bg-wrapper {
  position: absolute;
  top: 45%;
  left: 50%;

  height: 85vh;
  width: 128vh;

  transform: translate(-50%, -50%);

  background-image: url('/img/background3.png');
  background-repeat: no-repeat;
  background-size: cover;
}

/* OLD WRAPPER CODE */
#bg-wrapper2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  background-image: url('/img/background.jpg');
  background-size: cover;
  background-position: center center;
}
</style>
