<template>
  <div id="control-panel">
    <span id="testing-minimise" @click="toggleControl">
      <span v-if="minimised">+</span>
      <span v-else>-</span>
    </span>

    <span v-if="!minimised">
      <button type="button" class="button" @click="testingCue('next_call')">
        Cue Adela
      </button>
      <button type="button" class="button" @click="testingCue('barry2')">
        Cue Nikesh2
      </button>
      <button type="button" class="button" @click="testingCue('precalvermeiner')">
        Cue Calvermeiner
      </button>
      <button type="button" class="button" @click="testingCue('calvermeinerbreaking')">
        Calvermeiner Radio
      </button>
      <button type="button" class="button" @click="testingCue('johanna')">
        Tyne email
      </button>
      <button type="button" class="button" @click="testingCue('pitches_start')">
        Pitch Start
      </button>
      <button type="button" class="button" @click="testingCue('food_reviewcarbon')">
        Cue Carbon
      </button>
      <!-- <button type="button" class="button" @click="testingCue('food_start')">
        Cue Food
      </button> -->
      <button type="button" class="button" @click="testingCue('foodordered')">
        Cue Food Ordered
      </button>
      <button type="button" class="button" @click="testingCue('carbon_within_allowance')">
        Cue Harry
      </button>
      <button type="button" class="button" @click="testingCue('call_harry')">
        Cue Harry done
      </button>
      <button type="button" class="button" @click="testingCue('investment_start')">
        Allow investing
      </button>
    </span>
  </div>
</template>

<script>
export default {
  name: 'TestingControl',
  data: function() {
    return {
      minimised: true,
    };
  },
  methods: {
    cueCall() {
      this.$store.commit('cueCall');
    },
    testingCue(cue) {
      this.$store.dispatch('testingCue', cue);
    },
    toggleControl() {
      this.minimised = !this.minimised;
    },
  }
};
</script>

<style scoped>
#control-panel {
  position: absolute;
  bottom: 0;
  left: 0;

  padding: 15px;

  z-index: 800;

  background-color: #eee;
  border: 1px solid #ccc;
  border: 3px solid #ed716c;
  border-bottom: 0;
  border-left: 0;
  border-radius: 0 20px 0 0;
}
#control-panel * {
  margin-right: 20px;
}
#control-panel *:last-child {
  margin: 0;
}

#testing-minimise {
  font-weight: 600;
}
</style>
