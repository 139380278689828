<template>
  <div id="investor-wrapper" class="fullscreen">
    <div id="investor-header">
      <h1>Investor Tool</h1>
      <div v-if="'call_adela' in $store.state.events">
        You have
        <h2>£{{ (currValue * 1000).toLocaleString('en-US') }}</h2>
        In the fund
      </div>
      <div v-else>
        You do not currently have a fund to invest.
      </div>
    </div>

    <perfect-scrollbar v-if="Object.keys(groups).length > 0" class="investor-content">
      <table v-for="(companies, groupname) in groups" :key="groupname">
        <tr>
          <th colspan="5">
            {{ groupname }}
          </th>
        </tr>
        <tr v-for="company in companies" :key="company">
          <td class="td-logo">
            <img :src="'/img/logos/' + company + '.svg'" :alt="names[company] + ' Logo'">
          </td>
          <td class="td-title">
            <a href="#" @click.stop.prevent="showCompany(company)">{{ names[company] }}</a>
          </td>
          <!-- TODO: Fix bug here. IE11 uses @change instead of @input... -->
          <td class="td-slider">
            <input :id="company" v-model="sums[company]" type="range" :max="maxValue" in="0" @input="checkSums">
          </td>
          <td class="td-amount">
            <label :for="company">£{{ (sums[company] * 1000).toLocaleString('en-US') }}</label>
          </td>
          <td class="td-amount">
            {{ (sums[company] / maxValue * 100).toFixed(1) }}%
          </td>
        </tr>
      </table>
    </perfect-scrollbar>

    <div class="investor-info" :class="{ nocompanies: Object.keys(sums).length === 0 }">
      <div v-if="Object.keys(sums).length === 0" id="investor-find" class="fw-600">
        <p>You have discovered 0 companies for potential investment.</p>
      </div>

      <div v-if="$store.state.investmentWindowOpen" id="investor-submit">
        <div v-show="error" class="error">
          {{ error }}
        </div>
        <div class="circle" @click="checkSubmit">
          <div class="widget-content">
            Submit
          </div>
        </div>
      </div>
      <div v-else-if="'call_adela' in $store.state.events" id="investor-find">
        <p>Investments must be executed within the investment window, you will be notified when this opens.</p>
      </div>
    </div>
  </div>
</template>

<script>
import investorContentJSON from '@/content/investor.json';

export default {
  name: 'InvestorScreen',
  data: () => {
    return {
      maxValue: 1000,
      currValue: 1000,
      error: '',
      sums: {},
      groups: {},
      names: {
        'nipsonic': 'Nipsonic',
        'nettech': 'Nettech',
        'tyne': 'Tyne Bio Energy',
        'calvermeiner': 'Calvermeiner',
        'parskil': 'Parskil',
        'plantdeep': 'Plantdeep',
        'peatssake': 'Re:Peat',
        'filtair': 'Filtaer',
        'ashmont': 'Ashmont Tidal District',
        'watermead': 'Watermead Harbour',
        'lancing': 'Lancing Plains',
      }
    };
  },
  mounted() {
    var sumstmp = this.$store.state.investmentChoices;

    for (var company of this.$store.state.companies ) {
      if (!(company in sumstmp)) {
        sumstmp[company] = 0;
      }
      else {
        this.currValue -= sumstmp[company];
      }
    }

    this.sums = sumstmp;

    
    this.groups = {};

    for (var g in investorContentJSON.groups) {
      for (var g_c of investorContentJSON.groups[g]) {
        if (g_c in this.sums) {
          if (!(g in this.groups)) {
            this.groups[g] = [];
          }

          this.groups[g].push(g_c);
        }
      }
    }
  },
  destroyed() {
    this.$store.dispatch('sendPlayerProgress');
  },
  methods: {
    checkSums(event) {
      var sum = Object.values(this.sums).reduce((a, b) => { return (parseInt(a) + parseInt(b)); });
      var src = event.srcElement.id;

      if (sum >= this.maxValue) {
        this.sums[src] = Math.max(this.maxValue - (sum - this.sums[src]), 0);
        event.srcElement.value = this.sums[src];
      }

      this.currValue = Math.max(this.maxValue - sum, 0);
    },
    checkSubmit(event) {
      var sum = Object.values(this.sums).reduce((a, b) => { return (parseInt(a) + parseInt(b)); });

      if (sum > this.maxValue) {
        this.error = 'ERROR: Cannot invest more money than the fund contains';
        event.preventDefault();
      }
      else if (sum < this.maxValue) {
        this.error = 'ERROR: Unallocated funds in investing account';
        event.preventDefault();
      }
      else {
        this.error = '';
        this.$store.dispatch('submitInvestments', this.sums);
      }
    },
    showCompany(company) {
      this.$store.commit('setAppDisplay', { app: 'investor', item: company });
      this.$store.dispatch('setView', 'investor');
      this.$store.commit('setBackButton', 'investorscreen');
    }
  }
};
</script>

<style scoped>
#investor-wrapper {
  color: #536444;
  z-index: 300;
}
#investor-header {
  position: absolute;
  top: 2%;
  left: 50%;
  transform: translateX(-50%);

  text-align: center;
}
#investor-header h1 {
  font-size: 2.5em;
  font-weight: 200;
  margin-top: 0;
  margin-bottom: 10px;
}
#investor-header h2 {
  font-size: 1.5em;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
}

#investor-find {
  width: 100%;
  margin-top: 40px;
  text-align: center;
}

#investor-submit .circle {
  position: relative;
  left: calc(50% - 4vh);

  width: 100px;
  margin-top: 10px;

  font-weight: 700;
  font-size: 16px;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  cursor: pointer;
}
#investor-submit .circle .widget-content {
  cursor: pointer;
}

.investor-content {
  position: absolute;
  top: 185px;
  left: 20%;
  width: 60%;
  height: calc(100% - 340px);

  overflow: auto;

  /* transform: translateX(-50%); */
}

.investor-content table {
  width: 100%;
}

th {
  padding-top: 10px;
  font-size: 20px;
}

.td-logo {
  width: 70px;
  position: relative;
}
.td-logo img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.td-title {
  width: 220px;
  padding-left: 20px;
}
.td-slider {
  padding: 0 10px;
}
.td-amount {
  display: table-cell;
  width: 120px;
}

.td-slider input {
  margin-top: 6px;
  width: 100%;
}
input[type=range]::-ms-tooltip {
  display: none;
}

.investor-info {
  position: absolute;

  bottom: 30px;
  left: 50%;

  transform: translateX(-50%);
}
.investor-info.nocompanies {
  top: 55%;
  transform: translate(-50%, -50%);
}

a {
  color: #536444;
  font-weight: 400;
}
</style>
