<template>
  <transition name="hide-login">
    <div v-if="!$store.state.started" id="login-wrapper">
      <LoginBackground />

      <div id="login-screen" ref="loginScreen"> 
        <transition-group name="loading-items-fade" mode="out-in">
          <!-- <div v-show="!showSpinner" id="login-window"  key="welcome">
            <div id="start-demo" ref="loginSubmit">
              Hello {{ $store.state.username }}
            </div>
          </div> -->

          <LoadingSpinner v-if="showSpinner" id="spinner" key="spinner" />
        </transition-group>
      </div>
    </div>
  </transition>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import LoginBackground from '@/components/LoginBackground.vue';

export default {
  name: 'LoginScreenTest',
  components: {
    LoadingSpinner,
    LoginBackground
  },
  props: {
    skipIntro: String,
  },
  data: function() {
    return {
      showSpinner: true,
      showSpinnerForMs: 4000,
    };
  },
  mounted(){
    setTimeout(()=>{
      this.showSpinner = false;
      setTimeout(()=>{
        this.startGame();
      },1500);
    },this.showSpinnerForMs);
  },
  emits: ['started'],
  methods: {
    startGame() {
      console.log('Starting test');

      this.$store.dispatch('startGame', this.skipIntro);
      this.$emit('started');
    }
  },
};
</script>

<style scoped>
@keyframes changeLogin {
  from {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  to {
    transform: matrix(0.8, -0.3, -0.2, 0.8, 0, 0);
  }
}
@-webkit-keyframes changeLogin {
  from {
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  to {
    transform: matrix(0.8, -0.3, -0.2, 0.8, 0, 0);
  }
}

@keyframes displayTransition {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.hide-login-leave-active {
  transition: all 2s ease-out;
}
.hide-login-leave-to {
  opacity: 0;
}

.loading-items-fade-enter, .loading-items-fade-leave-to {
  opacity: 0;
}
.loading-items-fade-enter-to, .loading-items-fade-leave {
  opacity: 1;
}
.loading-items-fade-enter-active, .loading-items-fade-leave-active {
  transition: all 0.5s ease;
  transition-delay: 1s;
}
.loading-items-fade-leave-active {
    transition-delay: 0s;
}

#login-name {
  opacity: 0;
  animation-name: displayTransition;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}
#login-screen {
  position: absolute;
  top: 0;
  left: 0;
  /* background-color: white; */
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  z-index: 900;
  font-weight: 300;
  font-size: 2em;
}
#login-window {
  margin: 0 auto;
  width: 40%;
  text-align: center;
}

#start-demo {
  cursor: default;
  padding-bottom: 15px;
  border-bottom: 2px solid #ccc;
  /* background-color: lightcoral;
    border: 2px solid #000;
    padding: 10px 20px; */
}

.loginResize {
  animation-name: changeLogin;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

</style>    
