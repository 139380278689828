<template>
  <div id="pa">
    <svg id="svg-pa" ref="svgPA" width="100%" height="100%" viewBox="0 0 600 600">
      <!-- <transition name="intro-ring-outline">
        <circle
          v-show="started" cx="200" cy="200" r="170" fill="none"
          stroke="black" stroke-width="2" class="pa-circleoutline"
        />
      </transition> -->
      <transition name="intro-ring">
        <circle
          v-show="started" ref="svgCircle" cx="300" cy="300" r="40%"
          fill="none" stroke="white" stroke-width="2" class="pa-circle"
        />
      </transition>

      <!-- <transition name="circle-alert">
        <circle
          v-show="circleMoving" ref="svgCircleAlert" cx="200" cy="200" r="170"
          fill="none" stroke="white" stroke-width="2" class="pa-circlealert"
        />
      </transition> -->

      <transition name="intro-dot">
        <circle
          v-show="started" cx="300" cy="10%" r="10" fill="white"
          class="pa-indicator" :class="{ squeeze: circleMoving }"
        />
      </transition>
    </svg>
    <transition name="intro-text">
      <div v-show="started" id="pa-text">
        <div id="pa-text-content">
          {{ PAStatus || " " }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { useSound } from '@vueuse/sound';

export default {
  name: 'PersonalAssistant',
  props: {
    started: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  setup() {
    // Sprite map for the intro tutorial.
    const spriteMap = {
      hopewell: [0, 1250],
      annacalled: [1800, 5800],
      movedcarbon: [8860, 7550],
      radio: [16660, 6850],
      controlradio: [24130, 6700],
      otherapps: [32150, 6100],
    };

    // Sprite map for Hello $username
    const spriteMapNames = {
      sam: [0, 1900],
      alex: [2000, 2100],
      ali: [4200, 1900],
      jj: [6200, 1800],
      mal: [8100, 1800],
      nicki: [10000, 1900],
      jamie: [12000, 2000],
      charlie: [14100, 1900],
      jas: [16100, 1850],
      sacha: [18100, 2100],
      anna_sam: [20300, 4200],
      anna_alex: [24600, 4200],
      anna_ali: [28900, 4100],
      anna_jj: [33100, 4100],
      anna_mal: [37300, 4100],
      anna_nicki: [41500, 4300],
      anna_jamie: [45900, 4100],
      anna_charlie: [50200, 4100],
      anna_jas: [54500, 4100],
      anna_sacha: [58700, 4100],
    };

    // Sprite map for the 'main' PA audio file
    const spriteMap2 = {
      newtodo: [0, 2850],
      breaking: [2900, 3850],
      radiocalv: [7000, 2650],
      webreminder: [9700, 3900],
      webscheduled: [13700, 5050],
      webjoin: [18800, 3200],
      bloodsugar: [22100, 5150],
      reviewcarbon: [27600, 2400],
      timelunch: [30100, 1550],
      warningcarbon: [31700, 3600],
      offsetrequired: [35900, 2000],
      itsnotlong:  [38000, 3750],
      harrycalled:  [41800, 2150],
      newvideomsg:  [44000, 2900],
      radiolifestyle: [47000, 4150],
      investmentopen: [51300, 5400],
      timeinvest: [57500, 1600]
    };

    const playIntro = useSound('/audio/pa/intro_tutorial_v5.2.mp3', { sprite: spriteMap });
    const playHello = useSound('/audio/pa/intro_hello_names_v5.1.mp3', { sprite: spriteMapNames });
    const playPA = useSound('/audio/pa/all_but_email_and_intro_v5.1.mp3', { sprite: spriteMap2 });
    const playNewEmail = useSound('/audio/pa/new_email_v5.mp3');

    return { playIntro, playHello, playPA, playNewEmail };
  },
  data: () => {
    return {
      statusText: '',
      circleMoving: false,
      angles: {
        news: '-50',
        radio: '-90',
        investor: '130',
        weather: '50',
        todo: '-130',
        email: '90',
        food: '180',
        none: '0',
        carbon: '7',
      },
      lastRadioStatus: false,
    };
  },
  computed: {
    PAStatuses() {
      return this.$store.state.PAStatuses;
    },
    PAStatus() {
      if (this.PAStatuses.length > 0) {
        var tmp = this.PAStatuses[0].msg.replace('$username', this.$store.state.username);
        return tmp;
      }

      return '';
    }
  },
  watch: {
    PAStatuses: function() {
      // console.log('PA: Status changed');

      if (this.PAStatuses.length > 1) {
        var delay = 5500;

        if ('track' in this.PAStatuses[0] && (this.PAStatuses[0].track === 'webjoin') || this.PAStatuses[0].track === 'webscheduled')
          delay = 7000;

        if ('audio' in this.PAStatuses[0] && this.PAStatuses[0].audio === 'intro') {
          if (this.PAStatuses[0].track === 'todolist')
            delay = 4500;
          else
            delay = 7500;
        }

        setTimeout(() => { this.$store.commit('updatePA'); }, delay);
      }


      // Stop radio if playing and resume when done
      if (this.PAStatuses.length >= 1 && this.PAStatuses[0].msg !== ' ' && this.$store.state.radioPlaying) {
        this.$store.commit('radioToggle');
        this.lastRadioStatus = true;

        // console.log('PA: Pausing radio');
      }
      // TODO: Change timer to non-static time
      if (this.lastRadioStatus && this.PAStatuses.length === 1) {
        this.lastRadioStatus = false;
        setTimeout(() => { this.resumeRadio(); }, 4000);
      }

      // This whole if block deals with the PA voice system
      if (!this.$store.state.callPlaying) {
        if (this.PAStatuses.length > 0 && 'track' in this.PAStatuses[0]) {
          if ('audio' in this.PAStatuses[0] && this.PAStatuses[0].audio === 'intro') {
            this.playIntro.play({ id: this.PAStatuses[0].track });
          }
          else {
            this.playPA.play({ id: this.PAStatuses[0].track });
          }
        }
        else if (this.PAStatuses.length > 0 && 'audio' in this.PAStatuses[0] && this.PAStatuses[0].audio === 'newemail') {
          this.playNewEmail.play();
        }
        else if (this.PAStatuses.length > 0 && 'audio' in this.PAStatuses[0] && this.PAStatuses[0].audio === 'annacall') {
          this.playHello.play({ id: 'anna_' + this.$store.state.username.toLowerCase() });
        }
        else if (this.PAStatuses.length > 0 && 'audio' in this.PAStatuses[0] && this.PAStatuses[0].audio === 'hellos') {
          this.playHello.play({ id: this.$store.state.username.toLowerCase() });

          setTimeout(() => {
            this.playIntro.play({ id: 'hopewell' });
          }, 2300);
        }
      }

      if (this.PAStatuses.length > 0 && this.PAStatuses[0].widget) {
        this.rotatePA(this.PAStatuses[0].widget);

        if (this.PAStatuses[0].widget !== 'none')
          this.$store.commit('setPAHintActive');
        else
          this.$store.commit('setPAHintActive', false);
      }
    }
  },
  methods: {
    update(trigger) {
      if (!(trigger in this.hints)) {
        return;
      }

      this.rotatePA(this.hints[trigger].widget, this.hints[trigger].status);
    },
    rotatePA(widget) {
      this.circleMoving = true;
      this.$refs.svgPA.style.transform = 'rotate(' + this.angles[widget] + 'deg)';

      setTimeout(this.resetPA, 3000);
    },
    rotatePA2(widget, statusMsg = '') {
      this.circleMoving = true;
      this.$refs.svgPA.style.transform = 'rotate(' + this.angles[widget] + 'deg)';

      if (statusMsg) {
        this.statusText = statusMsg;
      }

      setTimeout(this.resetPA, 3000);
    },
    resetPA() {
      this.circleMoving = false;
    },
    resumeRadio() {
      if (this.$store.state.calling || this.$store.state.pitches)
        return;

      this.$store.commit('radioPlay');
    }
  }
};
</script>

<style scoped>
#pa {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 100%;
  max-width: 500px;
  height: 70%;
  transform: translateX(-50%) translateY(-50%);
}

#svg-pa {
  transition: transform 1s cubic-bezier(0.6, 0.07, 0.63, 0.91);
}

#pa-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60%;
  height: 45%;
  transform: translate(-50%, -50%);

  font-size: 22px;
  text-align: center;
  vertical-align: middle;
  font-weight: 400;

  color: #fff;
  text-shadow: 1px 1px 12px rgba(0, 0, 0, 0.45);
}

#pa-text-content {
  text-align: center;
  vertical-align: middle;
  width: 100%;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%)
}

@media screen and (min-height: 800px) {
  #pa {
    max-width: 900px;
  }

  #pa-text {
    font-size: 24px;
  }
}

@keyframes circle-breathe {
  0%, 100% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.4;
  }
}
@keyframes expand-dot {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.15); }
}

.pa-circleoutline {
  opacity: 0.2;
  filter: blur(7px);
}
.pa-circle {
  opacity: 0.1;
  animation: circle-breathe 5s ease-in infinite;
  transition: opacity 3s linear;
}
.pa-indicator {
  filter: drop-shadow(0 0 3px rgba(100, 100, 100, 0.5));
  opacity: 0.75;

  transform-origin: 50% 10%;
  animation: expand-dot 0.5s linear;
  animation-delay: 3s;
}
.pa-circlealert {
  opacity: 1;
}

@keyframes squeeze {
  0%, 100% { transform: scaleY(1) }
  15%, 85% { transform: scaleY(0.8)}
}
.squeeze {
  transition: transform 0.5s linear;
  transform-origin: 0% 7.5%;
  animation: squeeze 1s cubic-bezier(0.6, 0.07, 0.63, 0.91);
  /* transform: scaleY(0.8) */
}

.circle-alert-enter-active {
  transition: all 0.2s cubic-bezier(0.6, 0.07, 0.63, 0.91);
}
.circle-alert-leave-active {
  transition: all 1s linear;
}

.circle-alert-enter-from,
.circle-alert-leave-to {
  opacity: 0;
}
.circle-alert-enter-to,
.circle-alert-leave-from {
  opacity: 1;
}

.intro-top-enter-from {
  opacity: 0;
}
.intro-top-enter-to {
  opacity: 1;
}
.intro-top-enter-active {
  transition: all 2s linear;
  transition-delay: 4.5s;
}

.intro-ring-enter-active {
  transition: all 2s linear;
  transition-delay: 2s;
  box-shadow: none;
  filter: none;
}
.intro-ring-enter-from {
  opacity: 0;
}
.intro-ring-enter-to {
  opacity: 1;
}
.intro-ring-outline-enter-active {
  display: none;
}

.intro-dot-enter-from {
  opacity: 0;
}
.intro-dot-enter-to {
  opacity: 0.75;
}
.intro-dot-enter-active {
  transition: all 1.5s ease-out;
  transition-delay: 2s;
}
.intro-text-enter-from {
  opacity: 0;
}
.intro-text-enter-to {
  opacity: 1;
}
.intro-text-enter-active {
  transition: all 1s linear;
  transition-delay: 3s;
}
</style>
