<template>
  <div v-show="displayWidget" id="radio-mini-control" class="circle tooltip tooltip-top" @click.stop.prevent="playRadio">
    <div class="widget-content player-animation">
      <!-- TODO: Replace this with the animation only (no bg) -->
      <img src="/img/radio/radio-mini-control.gif">
    </div>
    <div id="play-controls" class="circle radio-bg">
      <div class="widget-content">
        <img v-if="!playing" class="radio-control" src="/img/radio/play.svg">
        <img v-else class="radio-control" src="/img/radio/pause.svg">
      </div>
    </div>

    <div class="description-noarrow">
      Radio
    </div>
  </div>
</template>

<script>
export default {
  name: 'RadioMiniControl',
  computed: {
    displayWidget() {
      if (this.$store.state.callPlaying || this.$store.state.calling)
        return false;

      if (this.$store.state.end || this.$store.state.currentView === 'end')
        return false;

      return (this.$store.state.currentView !== 'radio' && this.$store.state.currentView !== 'default');
    },
    playing() {
      return this.$store.state.radioPlaying;
    },
  },
  methods: {
    playRadio() {
      this.$store.commit('radioToggle');
    },
  }
};
</script>

<style>
#radio-mini-control.tooltip-top .description-noarrow {
  transform: translate(20%, -100%);
  font-size: 15px;
  font-weight: 500;

  background-color: #4c6176;

  padding: 5px 10px;
}
</style>

<style scoped>
#radio-mini-control {
  width: 93px;
  padding: 0;

  position: absolute;
  bottom: 10px;
  right: 10px;

  z-index: 900;

  cursor: pointer;

  /* background-color: #4c6176; */
}
#radio-mini-control .player-animation {
  width: 100%;
  left: 0;
}

.widget-content img {
  width: 100%;
}

#radio-mini-control:hover .player-animation {
  display: none;
}
#radio-mini-control:hover #play-controls {
  display: block;
}

#play-controls {
  position: absolute;
  left: 21px;
  top: 19px;

  display: none;

  width: 51px;
  background-color: #4c6176;

  cursor: pointer;
}
#play-controls .widget-content {
  width: 40%;
  height: 40%;
  left: 30%;
}
#play-controls .widget-content img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.widget-content a {
  height: 100%;
  width: 100%;
}
</style>
