<template>
  <div id="bg-main" class="fullscreen">
    <div id="bg-middle" class="circle todo-bg" />
    <div id="bg-top" class="circle" />
  </div>
</template>

<script>
export default {
  name: 'TodoBackground',
};
</script>

<style scoped>
#bg-main {
  background-color: #eee;
  z-index: 30;
}

#bg-top {
  position: absolute;
  top: -75vh;
  left: 50%;
  height: 100vh;
  width: 170vh;

  transform: translateX(-50%);
  /* transform: translate(-50%, -50%); */

  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
  z-index: 30;
}

#bg-middle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150vh;

  min-width: 950px;

  /* transform: translate(-50%, -50%); */
  transform: translate(-50%, -50%);

  z-index: 30;
}
</style>
