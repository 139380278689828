<template>
  <div id="weather-wrapper" class="fullscreen">
    <div id="radio-bg" class="fullscreen">
      <svg class="fullscreen">
        <!-- <filter id="blurFilter" x="0" y="0">
          <feGaussianBlur in="SourceGraphic" stdDeviation="45" />
        </filter> -->
        <!-- <circle cx="53%" cy="50%" r="30%" class="bg-circle3" filter="url(#blurFilter)" />
        <circle cx="35%" cy="50%" r="19%" class="bg-circle1" filter="url(#blurFilter)" /> -->
        <!-- <circle cx="35%" cy="15%" r="40%" class="bg-circle2" /> -->
        <circle cx="70%" cy="70%" r="50%" class="bg-circle3" />
        <circle cx="15%" cy="78%" r="38%" class="bg-circle1" />
      </svg>
      <div id="radio-bg-overlay" class="fullscreen" />
    </div>

    <div id="weather-middle" class="circle weather-bubble-middle tooltip">
      <img src="/img/weather/clear-day.svg" alt="Clear Day" class="pos-center">
      <div class="bubble-content">
        33°C
        <span class="description">
          Expect temperatures upwards of 38°C later this week.
        </span>
      </div>
    </div>
    <div id="weather-air" class="circle weather-bubble">
      <div class="circle bg-air circle-bg" />
      <div class="bubble-content-label">
        <img src="/img/weather/airquality.svg" alt="Air Quality Icon">
        <span>Air Quality</span>
      </div>
      <div class="bubble-content">
        <strong>13 degrees</strong><br>
        (Very good)
      </div>
      <div class="bubble-back" style="font-size: 14px;">
        No illegal carbon emitters have been detected for the past 45 days, you can breathe easy.
      </div>
    </div>
    <div id="weather-pollen" class="circle weather-bubble">
      <div class="circle bg-pollen circle-bg" />
      <img src="/img/weather/pollen.svg" alt="Pollen Icon" class="pos-center">
      <div class="bubble-content">
        H
      </div>
      <div class="bubble-back" style="font-size: 12px;">
        Pollen count is high. Good for the bees but remember your hayfever tablets.
      </div>
    </div>
    <div id="weather-uv" class="circle weather-bubble">
      <div class="circle bg-uv circle-bg" />
      <img src="/img/weather/uv.svg" alt="UV Icon" class="pos-center">
      <div class="bubble-content">
        Factor 60 recommended
      </div>
      <div class="bubble-back" style="font-size: 12px;">
        UV levels are moderate, remember to bring sunscreen.
      </div>
    </div>
    <div id="weather-wind" class="circle weather-bubble">
      <div class="circle bg-wind circle-bg" />
      <img src="/img/weather/wind.svg" alt="Wind Icon" class="pos-center">
      <div class="bubble-content">
        SW
      </div>
      <div class="bubble-back" style="font-size: 14px;">
        1am - 5am: Optimal time for low carbon vehicle charging
      </div>
    </div>
    <div id="weather-warnings" class="circle weather-bubble">
      <div class="circle bg-warnings circle-bg" />
      <img src="/img/weather/warnings.svg" alt="Warnings Icon" class="pos-center">
      <div class="bubble-content" />
      <div class="bubble-back" style="font-size: 10px;">
        No extreme weather events expected but please always remain vigilant.
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';

export default {
  name: 'Weather',
  data: function() {
    return {
    };
  },
  mounted: function() {
    console.log('Animation here');

    gsap.to('.bg-circle1', {
      duration: 5,
      scale: 1.2,
      repeat: -1,
      yoyo: true,
      ease: 'back',
    });
    gsap.to('.bg-circle2', {
      duration: 7,
      xPercent: 10,
      yPercent: 5,
      repeat: -1,
      yoyo: true,
      ease: 'back',
    });

    gsap.to('#weather-pollen', {
      duration: 5.4,
      translateX: 20,
      translateY: 15,
      repeat: -1,
      yoyo: true,
      ease: 'back',
    });
    gsap.to('#weather-air', {
      duration: 4.2,
      translateX: 20,
      repeat: -1,
      yoyo: true,
      ease: 'back',
    });
    
    gsap.to('#weather-wind', {
      duration: 6.2,
      // translateX: 10,
      translateY: 10,
      repeat: -1,
      yoyo: true,
      ease: 'back',
    });
    gsap.to('#weather-warnings', {
      duration: 6.2,
      // translateX: 10,
      translateY: 10,
      repeat: -1,
      yoyo: true,
      ease: 'back',
    });
  },
};
</script>

<style scoped>
#weather-wrapper {
  z-index: 100;
}
#radio-bg {
  filter: blur(65px);
}
/* #radio-bg-overlay {
  background: rgba(255, 255, 255, 0.3);
} */
.bg-circle1 {
  fill: #90B888;
}
.bg-circle2 {
  fill: #F18682;
  fill: #536444;
}
.bg-circle3 {
  fill: #536444;
}

.bg-air {
  background-color: #8BBAE6;
}
.bg-pollen {
  background-color: #ED7527;
}
.bg-uv {
  background-color: #F5BD60;
}
.bg-wind {
  background-color: #BCBCBC;
}
.bg-warnings {
  background-color: #F18682;
}

.c1 {
  width: 30%;
}
.c2 {
  width: 50%;
}
.c3 {
  width: 65%;
}
.c4 {
  width: 85%;
}

/* Weather and climate bubbles */
.weather-bubble {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
}
.weather-bubble .circle-bg {
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
}
.weather-bubble:hover {
  box-shadow: none;
  background: none;
}
.weather-bubble:hover .circle-bg {
  filter: blur(10px);
}

.weather-bubble-middle {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
}
.bubble-content {
  position: absolute;
  top: 50%;
  left: 50%;
  /* width: 100%;
  height: 100%; */
  width: 80%;
  transform: translate(-50%, -50%);

  color: #fff;
  font-weight: 300;
  text-align: center;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
}
.bubble-back {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70%;
  transform: translate(-50%, -50%);

  color: #fff;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);

  opacity: 0;
  transition: opacity 1s linear;

  text-align: center;
}

#weather-middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 60vh;

  font-size: 3em;
}
#weather-middle img {
  width: 35vh;
  height: 35vh;
}
#weather-middle .bubble-content {
  top: unset;
  left: 50%;
  bottom: 7vh;
  transform: translateX(-50%);

  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
}
#weather-middle .description {
  font-size: 16px;
}

#weather-air {
  position: absolute;
  top: 50%;
  left: 18%;
  transform: translate(-50%, -50%);

  /* width: 25vh; */
  min-width: 180px;
  width: calc((100% - 60vh) * 0.4);
  max-width: 200px;
}
#weather-air .bubble-content-label {
  position: absolute;
  color: #fff;
  width: 60%;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);

  top: 30%;
  left: 50%;
  transform: translateX(-50%);
}
#weather-air .bubble-content-label img {
  width: 30%;
}
#weather-air .bubble-content-label span {
  position: absolute;
  left: 40%;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
}
#weather-air .bubble-content {
  top: 70%;
  font-size: 14px;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
}
#weather-air .bubble-content strong {
  font-weight: 700;
  font-size: 1.3em;
}

#weather-pollen {
  position: absolute;
  top: 20%;
  left: 25%;
  transform: translate(-50%, -50%);

  width: 18vh;
  min-width: 150px;
}
#weather-pollen img {
  left: 33%;
  width: 30%;
}
#weather-pollen .bubble-content {
  left: 68%;
  width: auto;

  font-size: 50px;
  font-weight: 700;

  text-align: left;
}

#weather-uv {
  position: absolute;
  top: 20%;
  left: 75%;
  transform: translate(-50%, -50%);

  width: 18vh;
  min-width: 150px;
}
#weather-uv img {
  top: 12%;
  transform: translateX(-50%);
  height: 35%;
}
#weather-uv .bubble-content {
  top: 64%;
  font-size: 14px;
  font-weight: 500;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
}
#weather-wind {
  position: absolute;
  top: 50%;
  left: 82%;
  transform: translate(-50%, -50%);

  /* width: 25vh; */
  min-width: 150px;
  width: calc((100% - 60vh) * 0.4);
  max-width: 200px;
}
#weather-wind img {
  left: 30%;
  width: 30%;
}
#weather-wind .bubble-content {
  left: 65%;
  width: auto;

  font-size: 35px;
  font-weight: 600;

  text-align: left;
}

#weather-warnings {
  position: absolute;
  top: 80%;
  left: 75%;
  transform: translate(-50%, -50%);

  width: 18vh;
}
#weather-warnings img {
  width: 50%;
}

.weather-bubble:hover .bubble-back {
  opacity: 1;
}
.weather-bubble:hover .bubble-content, .weather-bubble:hover img, .weather-bubble:hover .bubble-content-label {
  display: none;
}
</style>
