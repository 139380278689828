<template>
  <div id="home-wrapper">
    <transition-group v-show="showCircles" id="clock-widget" appear :css="false" tag="div" @appear="animateAppearClock">
      <div id="pa-date" key="pa-date">
        {{ currentDate }}
      </div>
      <div id="row2" key="row2">
        <div id="pa-time">
          {{ currentTime }}
        </div>
        <div id="pa-carbon" class="tooltip" @click="handleTarget('carbon')">
          <CarbonCountSymbol :pointer="true" :redsymbol="$store.state.carbonCount > $store.state.carbonAllowance">
            {{ $store.state.carbonCount }}
          </CarbonCountSymbol>
          <div class="description">
            Carbon Count
          </div>
        </div>
      </div>
    </transition-group>

    <transition-group
      appear :css="false" tag="div" class="fullscreen" @appear="animateAppear"
      @enter="animateEnter" @leave="animateLeave" @before-enter="beforeEnter" @after-enter="afterEnter"
    >
      <div v-show="showCircles" id="news-widget" ref="news" key="news" class="widget" :class="{ visible: visibleClass }" @click="handleTarget('news')">
        <div class="widget-bg news-bg circle pos-center" />
        <div v-show="displayLabels" class="widget-content">
          <h1>News</h1>
        </div>
      </div>

      <div v-show="showCircles" id="radio-widget" ref="radio" key="radio" class="widget" :class="{ visible: visibleClass }" @click="handleTarget('radio')">
        <div class="widget-bg radio-bg circle pos-center" :class="{ focused: radioFocused }" />
        <div v-show="displayLabels && !radioFocused" class="widget-content">
          <h1>Radio</h1>
        </div>
        <div class="widget-content-inline">
          <RadioInline :radio-focused="radioFocused" />
        </div>
      </div>

      <div v-show="showCircles" id="todo-widget" ref="todo" key="todo" class="widget" :class="{ visible: visibleClass }" @click="handleTarget('todo')">
        <div class="widget-bg todo-bg circle pos-center" />
        <div v-show="displayLabels" class="widget-content">
          <h1>To Do List</h1>
        </div>
      </div>
    
      <div v-show="showCircles" id="weather-widget" ref="weather" key="weather" class="widget" :class="{ visible: visibleClass }" @click="handleTarget('weather')">
        <div class="widget-bg weather-bg circle pos-center" />
        <div v-show="displayLabels" class="widget-content">
          <h1>Weather</h1>
        </div>
      </div>

      <div v-show="showCircles" id="email-widget" ref="email" key="email" class="widget" :class="{ visible: visibleClass }" @click="handleTarget('email')">
        <div class="widget-bg email-bg circle pos-center" />
        <div v-show="displayLabels" class="widget-content">
          <h1>Email</h1>
        </div>
      </div>

      <div v-show="showCircles" id="investorscreen-widget" ref="widget" key="widget" class="widget" :class="{ visible: visibleClass }" @click="handleTarget('investorscreen')">
        <div class="widget-bg investor-bg circle pos-center" />
        <div v-show="displayLabels" class="widget-content">
          <h1>Investor Tool</h1>
        </div>
      </div>

      <div v-if="$store.state.showFood && showCircles" id="food-widget" ref="food" key="food" class="widget" :class="{ visible: visibleClass }" @click="handleTarget('food')">
        <div class="widget-bg food-bg circle pos-center" />
        <div v-show="displayLabels" class="widget-content">
          <h1>Food</h1>
        </div>
      </div>
    </transition-group>

    <transition-group name="client-appear" tag="div">
      <EmailsBackgroundHome v-show="$store.state.currentView == 'email'" key="email-bg" />
      <NewsBackgroundHome v-show="$store.state.currentView == 'news2'" key="news-bg" />
      <TodoBackground v-show="$store.state.currentView == 'todo'" key="todo-bg" />
      <InvestorBackground v-show="$store.state.currentView == 'investorscreen'" key="investor-bg" />
    </transition-group>

    <div v-show="$store.state.currentView !== 'default' && $store.state.currentView !== 'radio' && !$store.state.end" id="click-prevent" class="fullscreen" @mousedown.stop @mouseup.stop @click="resetView" />
    
    <div v-show="showHomeButton" id="close-button" class="tooltip" @click="resetView">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="63"
        height="50"
        viewBox="-1 -1 65 49"
        version="1.1" :class="{ dark: darkButtons }"
      >
        <ellipse
          id="NAV_Circle"
          data-name="NAV Circle"
          cx="50"
          cy="41.5"
          rx="6"
          ry="5.5"
          fill="#fff"
        />
        <circle
          id="NAV_Circle-2"
          data-name="NAV Circle"
          cx="50"
          cy="6"
          r="6"
          fill="#fff"
        />
        <circle
          id="NAV_Circle-3"
          data-name="NAV Circle"
          cx="57"
          cy="24"
          r="6"
          fill="#fff"
        />
        <circle
          id="NAV_Circle-4"
          data-name="NAV Circle"
          cx="13"
          cy="6"
          r="6"
          fill="#fff"
        />
        <circle
          id="NAV_Circle-5"
          data-name="NAV Circle"
          cx="6"
          cy="24"
          r="6"
          fill="#fff"
        />
        <ellipse
          id="NAV_Circle-6"
          data-name="NAV Circle"
          cx="13"
          cy="41.5"
          rx="6"
          ry="5.5"
          fill="#fff"
        />
        <ellipse
          id="path48"
          style="fill:none;fill-rule:evenodd;stroke-width:3.08763;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
          stroke="#fff"
          cx="31.499996"
          cy="23.500004"
          rx="14.606184"
          ry="14.606183"
        />
      </svg>
      <div class="description">
        Desktop
      </div>
    </div>

    <div v-if="showHomeButton" id="back-button" class="tooltip" :class="{ dark: darkButtons }" @click="$store.dispatch('setView', $store.state.backbutton)">
      <div class="back-placeholder" />
      <div class="description">
        Back
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import EmailsBackgroundHome from '@/components/EmailsBackgroundHome.vue';
import NewsBackgroundHome from '@/components/NewsBackgroundHome.vue';
import TodoBackground from '@/components/TodoBackground.vue';
import InvestorBackground from '@/components/InvestorBackground.vue';
import RadioInline from '@/components/RadioInline.vue';
import CarbonCountSymbol from '@/components/CarbonCountSymbol.vue';

export default {
  name: 'HomeScreen',
  components: {
    EmailsBackgroundHome,
    NewsBackgroundHome,
    TodoBackground,
    InvestorBackground,
    RadioInline,
    CarbonCountSymbol,
  },
  props: {
    skipIntro: String,
  },
  data: function() {
    return {
      currentTemp: '20°',
      displayLabels: true,
      showFood: false,
      radioFocused: false,
      clicked: {},
      currentTime: '',
      positions: {
        'news-widget': ['30%', '20%'],
        'radio-widget': ['25%', '45%'],
        'todo-widget': ['30%', '70%'],
        'weather-widget': ['70%', '20%'],
        'email-widget': ['75%', '45%'],
        'investorscreen-widget': ['70%', '70%'],
        'food-widget': ['50%', '85%'],
      },
      toStyles: {
        email: {
          left: 0,
          top: '50%',
          xPercent: -50,
          yPercent: -50,
          width: '100%',
          height: '200%',
        },
        news: {
          left: '75%',
          top: '50%',
          xPercent: -50,
          yPercent: -50,
          width: '100%',
          height: '300%',
        },
      }
    };
  },
  computed: {
    currentDate() {
      var date = new Date();
      var options = { weekday: 'long', month: 'long', day: 'numeric' };
      return new Intl.DateTimeFormat('en-GB', options).format(date);
    },
    // Logic for showing/hiding the home button
    showHomeButton() {
      if (this.$store.state.showFood && this.$store.state.currentView === 'food')
        return false;
      if (this.$store.state.end)
        return false;
      
      if (this.$store.state.currentView !== 'default' && this.$store.state.currentView !== 'radio' && !this.$store.state.pitches) {
        return true;
      }

      return false;
    },
    currentView() {
      return this.$store.state.currentView;
    },
    showCircles() {
      return  ['radio', 'default'].includes(this.$store.state.currentView);
    },
    darkButtons() {
      // return this.$store.state.currentView === 'investor' || this.$store.state.currentView === 'investorscreen' || this.$store.state.currentView === 'food';
      return ['food', 'investor', 'investorscreen', 'todo', 'email', 'weather'].includes(this.$store.state.currentView);
    },
    visibleClass() {
      return ('call_adela' in this.$store.state.events);
    },
  },
  watch: {
    currentView: function(newView, oldView) {
      if (oldView !== 'default' && newView === 'default') {
        this.resetView();
      }
    }
  },
  mounted: function() {
    this.interval = setInterval(this.updateTime, 1000 * 60);
    this.updateTime();
  },
  beforeDestroy: function() {
    clearInterval(this.interval);
  },
  methods: {
    logInfo() {
      if (this.$store.state.currentView === 'radio')
        this.$store.dispatch('setView');
      else
        this.$store.dispatch('setView', 'radio');
    },
    animateAppearClock(el, done) {
      var delay = 7.5*2 + 5;

      if (this.skipIntro === 'skip')
        delay = 0;

      gsap.to('#pa-date, #pa-time', {
        delay: delay,
        duration: 0.5,
        opacity: 0,
      });
      gsap.fromTo('#pa-carbon', { opacity: 0 }, {
        delay: delay,
        duration: 2,
        opacity: 1,
        ease: 'back',
      });
      gsap.fromTo('#pa-date, #pa-time', { opacity: 0 }, {
        delay: delay + 4,
        duration: 0.5,
        opacity: 1,
      });

      done();
    },
    animateAppear(el, done) {
      if (el.id === 'radio')
        return;

      var delay = 7.5*3 + 5;

      if (el.id !== 'radio-widget')
        delay += 7.5 * 2;

      if (this.skipIntro === 'skip')
        delay = 0;

      gsap.fromTo('#' + el.id, { opacity: 0 }, {
        delay: delay,
        duration: 0.5,
        left: this.positions[el.id][0],
        top: this.positions[el.id][1],
        xPercent: -50,
        yPercent: -50,
        opacity: 0.7,
      });

      done();
    },
    beforeEnter(el) {
      el.classList.remove('visible');
    },
    animateEnter(el, done) {
      if (el.id === 'radio')
        return;
      
      gsap.fromTo('#' + el.id, { opacity: 0 }, {
        delay: 1,
        duration: 0.5,
        left: this.positions[el.id][0],
        top: this.positions[el.id][1],
        xPercent: -50,
        yPercent: -50,
        opacity: 0.7,
      }, { complete: done });
    },
    afterEnter(el) {
      if (!this.visibleClass)
        return;

      el.classList.add('visible');
    },
    animateLeave(el, done) {
      if (el.id === 'radio')
        return;
      
      gsap.to('#' + el.id, {
        delay: 0.2,
        duration: 0.5,
        opacity: 0,
        onComplete: done,
      });
    },
    handleTarget(elID) {
      // console.log(event);
      if (!elID)
        return;

      if (!('call_adela' in this.$store.state.events))
        return;

      this.clicked.id = elID;


      this.updateViews();

      if (this.clicked.id === 'radio' || this.clicked.id === 'carbon') {
        return;
      }

      // TODO: Fix issues with transition. zIndex causes lag
      gsap.to('#' + this.clicked.id + '-widget', {
        delay: 0.2,
        duration: 0.5,
        opacity: 1,
        filter: 'none',
        // zIndex: 10,
        ...this.toStyles[this.clicked.id],
        // onComplete: this.updateViews
      });
    },
    updateViews() {
      console.log('ElID: ' + this.clicked.id);
      if (this.clicked.id === 'radio' && this.$store.state.currentView !== 'radio') {
        this.radioView();
      }
      
      this.$store.dispatch('setView', this.clicked.id);
    },
    resetView() {
      if (this.clicked.id && this.clicked.id !== 'carbon') {
        var elID = this.clicked.id + '-widget';

        console.log('Reset: ' + elID);
        
        gsap.to('#' + elID, {
          delay: 0.5,
          duration: 0.5,
          left: this.positions[elID][0],
          top: this.positions[elID][1],
          xPercent: -50,
          yPercent: -50,
          height: '',
          width: '',
          filter: '',
          // zIndex: '',
        });
      }

      this.clicked = {};
      this.radioFocused = false;
      setTimeout(() => { this.displayLabels = true; }, 800);

      if (this.$store.state.currentView !== 'default') {
        this.$store.dispatch('setView');
      }
    },
    radioView() {
      this.radioFocused = true;

      if (this.$store.state.calling || this.$store.state.pitches)
        return;

      this.$store.commit('radioPlay');
    },
    updateTime() {
      var date = new Date();
      var options = { timeStyle: 'short' };
      this.currentTime = new Intl.DateTimeFormat('en-GB', options).format(date);
    },
  }
};
</script>

<style scoped>
#close-button {
  position: absolute;
  top: 5%;
  right: 4%;
  font-size: 3em;
  cursor: pointer;
  color: #fff;
  z-index: 900;
}
#close-button * {
  cursor: pointer;
}
#close-button svg.dark * {
  fill: #333;
  stroke: #333;
}
#click-prevent {
  z-index: 20;
}
#home-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  /* z-index: 15; */
}

#back-button {
  position: absolute;
  top: 5%;
  right: calc(4% + 75px);

  width: 6vh;
  height: 58px;
  cursor: pointer;

  background-repeat: no-repeat;
  background-position: center;

  background-image: url('/img/icons/back.svg');

  background-size: 20px 20px;

  z-index: 900;
}

.back-placeholder {
  height: 50px;
  width: 50px;
  cursor: pointer;
}
/* #back-button:hover {
  transform: scale(1.1);
} */
#back-button.dark {
  background-image: url('/img/icons/back_dark.svg');
}

#clock-widget {
  position: absolute;
  width: 30%;
  left: 35%;
  top: 0;

  margin-top: 25px;
  font-size: 1.3em;
  text-align: center;

  color: #333;
}
#row2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
#pa-time, #pa-carbon {
  font-size: 1.4em;
  float: left;
  margin: 0 10px;
  z-index: 10;
}
#pa-carbon img {
  height: 30px;
  width: 30px; 

  position: absolute;
  top: 50%;
  /* transform: translateY(-50%); */
}

#pa-carbon.tooltip {
  text-decoration: none;
  font-weight: inherit;
}
#pa-carbon .description {
  font-size: 0.5em;
  font-weight: 400;
  transform: translateX(-20%);
}

#target-widget {
  left: 50%;
  top: 50%;
  position: absolute;

  width: 25%;
  height: 30%;

  background: none;

  transform: translate(-50%, -50%);
  filter: none;
}


.client-appear-enter-active {
  transition: opacity 1s linear;
  /* transition-delay: 0.6s; */
  z-index: 50;
}
.client-appear-leave-active {
  transition: all 0.5s linear;
}
.client-appear-enter, .client-appear-leave-to {
  opacity: 0;
}

.focused {
  filter: none;
}

#close-button.tooltip .description {
  font-size: 0.3em;
}

.widget-content h1 {
  font-size: 18px;
  text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);
}
</style>
