<template>
  <div id="call-start-wrapper" class="fullscreen">
    <div v-if="!callStarted" id="call-start" class="fullscreen" @click="startCall" />
  </div>
</template>

<script>
export default {
  name: 'ClickToCall',
  data: function() {
    return {
      callStarted: false,
      enabled: false,
    };
  },
  mounted() {
    // Set a 7s delay for the main pitch video (roughly timed to PA announcement)
    var delay = 7000;

    // For Harry's call, set timer for 12s instead
    if ('call_pitches' in this.$store.state.events)
      delay = 12000;

    setTimeout(() => { this.enable(); }, delay);
  },
  methods: {
    startCall() {
      if (!this.enabled)
        return;

      this.$store.commit('cueCall', true);
      this.callStarted = true;
    },
    enable() {
      this.enabled = true;
    }
  },
};
</script>

<style scoped>
#call-start {
  z-index: 650;
  cursor: pointer;
}
</style>
