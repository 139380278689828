import Vue from 'vue';
import Main from './Main.vue';
import store from './store';
import router from './router';

// Transpiler for older browsers
import 'core-js/stable';
// optional but required for transforming generator fns.
import 'regenerator-runtime/runtime';

import VueCookies from 'vue-cookies';

import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

Vue.use(PerfectScrollbar);
Vue.use(VueCookies);

Vue.config.productionTip = false;

Vue.$cookies.config('1h');

var app = new Vue({
  el: '#main',
  store,
  router,
  render: (h) => h(Main),
});

app.$mount('#main');
