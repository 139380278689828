<template>
  <div id="carbon-symbol" :class="{ pointer, redsymbol }" :style="'transform: scale(' + scale + ')'">
    <p :class="{ pointer, 'fg-red': redsymbol }">
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  name: 'CarbonCountSymbol',
  props: {
    scale: {
      type: Number,
      default: 1
    },
    pointer: {
      type: Boolean,
      default: false
    },
    redsymbol: {
      type: Boolean,
      default: false
    },
  },
};
</script>

<style scoped>
#carbon-symbol {
  background-image: url('/img/carbonsymbol.png');
  width: 45px;
  height: 28px;

  background-size: 100%;

  font-size: 12px;
  font-weight: 500;

  /* color: #6D8760; */
  color: #5C6D53;
  text-align: left;

  position: relative;
}

#carbon-symbol p {
  padding: 0;
  margin: 0;

  position: absolute;
  top: calc(50% - 1px);
  left: 15px;
  transform: translate(-50%, -50%);
}

.pointer {
  cursor: pointer;
}

.redsymbol {
  background-image: url('/img/carbonsymbol_red.png') !important;
}
</style>
