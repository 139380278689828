<template>
  <div id="tracking">
    <div v-if="showSpinner" id="spinner-container">
      <LoadingSpinner id="spinner" key="spinner" />
    </div>
    <h1>Enter Net Zero</h1>
    <div class="player-tracking">
      <h2>Player Tracking</h2>
      <form @submit.prevent="getPlayerInfo">
        <div class="form-group">
          <label for="playerid">Enter an access code: </label>
          <input id="get-player-id" v-model="getPlayerInfoForm.playerId" type="text" name="playerid" placeholder="###">
          <button type="submit">
            Go
          </button>
        </div>
      </form>

      <div v-if="groupData" class="group-data-table">
        <div class="table-topper">
          <div class="group-id">
            Group Identifier: {{ playerData.group }}
          </div>
          <div class="refresh" @click="getPlayerInfo">
            Refresh
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th class="col-code-and-name">
                Player
              </th>
              <th class="col-timing">
                Timing
              </th>
              <th class="col-progress">
                Progress
              </th>
              <th class="col-invested">
                Invested
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row,index) in groupData" :key="index" :class="[row._id == playerData._id ? 'highlight' : '']">
              <td>
                <div class="code-and-name">
                  <div class="code">
                    {{ row._id }}
                  </div>
                  <div class="name">
                    {{ getPlayerName(row.placeInGroup) }}
                  </div>
                </div>
              </td>

              <td v-if="row.timingData">
                <div class="timing">
                  <div v-if="row.timingData.start">
                    START
                  </div>
                  <div v-if="row.timingData.start" class="start">
                    {{ row.timingData.start }}
                  </div>
                  <div v-if="row.timingData.finish">
                    FINISH
                  </div>
                  <div v-if="row.timingData.finish" class="finish">
                    {{ row.timingData.finish }}
                  </div>
                </div>
              </td>
              <td v-if="!row.timingData">
                ...
              </td>

              <td v-if="row.playerState && row.playerState.events" class="progress-points">
                <div v-for="(point,key,index) in row.playerState.events" :key="index" class="progress-point positive">
                  <div class="progress-point-name">
                    {{ key }}
                  </div>
                  <div class="progress-point-time">
                    {{ getTimeDiff(row.playerState.events.start,point) }}
                  </div>
                </div>
              </td>
              <td v-if="!row.playerState || !row.playerState.events" class="neutral">
                No progress data available
              </td>
              
              <td v-if="row.playerInvestments && Object.keys(row.playerInvestments).length > 0" class="positive">
                Yes
              </td>
              <td v-if="!row.playerInvestments || Object.keys(row.playerInvestments).length <= 0" class="neutral">
                No
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import possibleUserNames from '@/content/names.json';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  name: 'Tracking',
  components: {
    LoadingSpinner
  },
  props: {
    playerid: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      showSpinner: false,
      getPlayerInfoForm: {
        playerId: 'roguq9'
      },
      playerData: null,
      groupData: null,
    };
  },
  mounted(){
    if (this.playerid){
      console.log('GETTING AUTOMATICALLY');
      this.getPlayerInfoForm.playerId = this.playerid;
      this.getPlayerInfo();
    }
  },
  methods:{
    getPlayerInfo(){
      console.log('GETTING PLAYER DATA');
      this.showSpinner = true;
      axios.get('/.netlify/functions/players', {params: {id: this.getPlayerInfoForm.playerId }})
      .then(res => {
        console.log('Got Player Data');
        console.log(res.data[0]);
        this.playerData = res.data[0];
        console.log('GETTING GROUP DATA');
        axios.get('/.netlify/functions/groups', { params: { id: this.playerData.group } })
        .then(res => {
          console.log('Got Group Data');
          console.log(res.data);
          this.groupData = res.data;
        })
        .catch(error =>{
          alert(error.message);
          this.showSpinner = false;
        });
        this.showSpinner = false;
        })
        .catch(error =>{
          alert(error.message);
          this.showSpinner = false;
        });    
    },
    getPlayerName(index){
      return possibleUserNames[index];
    },
    getTimeDiff(start,end){
      return msConversion(end - start);
    }
    
  }, 
  
};

function msConversion(millis) {
  let sec = Math.floor(millis / 1000);
  let hrs = Math.floor(sec / 3600);
  sec -= hrs * 3600;
  let min = Math.floor(sec / 60);
  sec -= min * 60;

  sec = '' + sec;
  sec = ('00' + sec).substring(sec.length);

  if (hrs > 0) {
    min = '' + min;
    min = ('00' + min).substring(min.length);
    return hrs + ':' + min + ':' + sec;
  }
  else {
    return min + ':' + sec;
  }
}

</script>
<style scoped>
#tracking {
  padding: 20px;
  height: 100vh;
  width: 100%;
  overflow: scroll;
}

#tracking * {
  -webkit-user-select: text; /* Safari */        
  -moz-user-select: text; /* Firefox */
  -ms-user-select: text; /* IE10+/Edge */
  user-select: text; /* Standard */
}

#spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  z-index: 900;
  font-weight: 300;
  font-size: 2em;
}

.table-topper {
  display: flex;
  justify-content: space-between;
}

.refresh {
  color: rgb(0, 128, 100);
}

.group-data-table table {
  width: 100%;
  table-layout: fixed;
}
.col-code-and-name { width: 40px; }
.col-timing { width: 80px; }
.col-progress { width: 70%; }
.col-invested { width: 30px; }

th {
  border-bottom: 1px solid rgb(54, 54, 54);
  text-align: center;
  font-size: 0.8em;
}

td {
  padding: 1px;
  border: 1px solid rgb(129, 129, 129);
  text-align: center;
}

.highlight {
  font-weight: 600;
}

.progress-points {
  display: flex;
  flex-flow: row wrap;
}

.progress-point {
  margin: 0 3px;
  padding: 1px 3px;
  border: 1px solid black;
}

.positive {
  background-color: rgb(0, 146, 78);
  color: white;
}
.neutral {
  background-color: none;  
}
</style>
