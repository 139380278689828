<template>
  <div id="admin">
    <div v-if="showSpinner" id="spinner-container">
      <LoadingSpinner id="spinner" key="spinner" />
    </div>

    <div v-if="!isAuthenticated" id="login" class="pos-center cgen-window" style="text-align: center">
      <form @submit.prevent="codegenAuthSubmit">
        <div>
          <strong>Authentication for Code Generator</strong>
        </div>

        <p style="margin-bottom: 20px" />

        Username: <input v-model="username" type="text" placeholder="Username" autofocus><br>
        Password: <input v-model="password" type="password" placeholder="Password"><br>
        <input type="submit" value="Log In">

        <div v-if="error" class="error">
          {{ error }}
        </div>
      </form>

      <div v-if="showSpinner" id="spinner-container" class="pos-center">
        <LoadingSpinner id="spinner" key="spinner" />
      </div>
    </div>


    <div v-if="isAuthenticated" id="main-codegen" class="cgen-window">
      <p><a @click="signOut">Sign Out</a></p>

      <h1>Enter Net Zero</h1>

      <div class="code-generator">
        <h2>Code Generator</h2>
        <form @submit.prevent="createPlayers"> 
          <div class="form-group">
            <label for="qty">Create this many players in total (1-2000): </label>
            <input v-model="create.qty" type="number" name="qty" min="1" max="2000">
          </div>
          <div class="form-group">
            <label for="grouping">Players should be grouped together in groups of (1-10): </label>
            <input v-model="create.grouping" type="number" name="grouping" min="1" max="10">
          </div>
          
          <button type="submit">
            Get Codes
          </button>
        </form>
      </div>
      <hr>

      <div class="instructions">
        <h2>Instructions</h2>

        <p>Use this form to generate user codes for Enter Net Zero. The first field determines how many user codes (in total) will be generated and the second field determines how big each group of users should be if several users are going through the experience at once.</p>

        <p>As an example; if the total players is set to 30 and the grouping is set to 10 players per group, three groups will be created, each consisting of 10 players.</p>

        <p>Once the "Get Codes" button is pressed, the server will generate a list of codes in CSV format, which can be opened in Excel or Google Sheets. This file will contain three columns; a direct link to the start of the experience, a link to the user's takeaway (available upon completion of the experience) and a link to the tracking page to follow the user's progress through the experience.</p>

        <p>If the group size is left as 1, all of the users are assumed to go through the experience on their own and takeaways will not show any results from other users. If the group size does not exactly match the total number of players;  eg. 25 players in total and a group size of 10, then two groups of 10 players will be created and the last group will only contain the 5 remaining players.</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  name: 'Admin',
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      create: {
        qty: 1,
        grouping: 1,
      },
      newPlayers: null,
      csvOutput: '',
      showSpinner: false,
      isAuthenticated: false,
      username: '',
      password: '',
      error: '',
    };
  },
  mounted() {
    if (this.$cookies.isKey('jwt')) {
      console.log(this.$cookies.keys());
      this.isAuthenticated = true;
    }
  },
  methods:{
    createPlayers(){
        this.showSpinner = true;
        axios.post('./.netlify/functions/players', { qty: this.create.qty, grouping: this.create.grouping }, { headers: { 'Authorization': 'Bearer ' + this.$cookies.get('jwt') } })
            .then((response)=>{
                this.newPlayers = response.data;
                console.log(this.newPlayers);
                this.csvOutput = makePlayerCSV(this.newPlayers);
                makeCSVFile(this.csvOutput);
                this.showSpinner = false;
            }) 
            .catch( err =>{
              console.log(err);
              this.showSpinner = false;

              if (err.response && err.response.status == 403) {
                console.log('403 error, cookie deleted');
                this.$cookies.remove('jwt');
                this.isAuthenticated = false;
              }

              alert(err);
            });
    },
    codegenAuthSubmit() {
      if (!this.username || !this.password) {
        this.error = 'Please provide a username and password';
        return;
      }

      this.showSpinner = true;
      this.error = '';

      // let dbURL = 'https://enternetzero.com/.netlify/functions/authofflineversion';
      let dbURL = '/.netlify/functions/authofflineversion';

      axios.post(dbURL, {
        username: this.username,
        password: this.password
      })
        .then(res => {
          console.log('Reply from backend');

          this.showSpinner = false;

          if (res.data['token']) {
            console.log('Stored JWT token in cookie');

            this.$cookies.set('jwt', res.data['token']);
            this.isAuthenticated = true;
          }
          else {
            this.error = 'Incorrectly formatted response from the database. Please try again or contact your administrator';
          }
        })
        .catch(error => {
          console.log('Error from backend');

          if (error.response) {
            this.error = error.response.data.message;
          }
          else if (error.request) {
            this.error = 'Could not contact database';
          }
          else {
            this.error = 'An unknown error occurred';
          }

          this.showSpinner = false;
        });
    },
    signOut() {
      this.$cookies.remove('jwt');
      this.isAuthenticated = false;
    },
  }, 
};

function makePlayerCSV(array) {
  var str = 'data:text/csv;charset=utf-8,';
  str += 'Link to access the experience, Link to access the personalised takeaway document\r\n';
  for (var i = 0; i < array.length; i++) {
    var line = '';
    if (!array[i-1] || array[i].group != array[i-1].group){
      line += '-\r\n'; //Add an extra gap for different groups
    }
    line += 'https://enternetzero.com/';
    line += array[i]._id;
    line += ',';
    line += 'https://enternetzero.com/document/';
    line += array[i]._id;
    line += ',';
    line += 'https://enternetzero.com/tracking/';
    line += array[i]._id;
    str += line + '\r\n';
  }

  return str;
}

function makeCSVFile(csvdata){
  const encodedUri = encodeURI(csvdata);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `enternetzero_accesscodes_${Date.now()}.csv`);
  document.body.appendChild(link); // Required for FF
  link.click(); // This will download the data file named "my_data.csv".
}

</script>
<style scoped>
#admin {
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;
  overflow: auto;
}

#admin * {
  -webkit-user-select: text; /* Safari */        
  -moz-user-select: text; /* Firefox */
  -ms-user-select: text; /* IE10+/Edge */
  user-select: text; /* Standard */
}

#spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  z-index: 900;
  font-weight: 300;
  font-size: 2em;
}

.cgen-window {
  background-color: #fff;
  border-radius: 5px;

  padding: 30px 60px;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.3);
}

#login {
  min-height: 240px;
  min-width: 400px;
}

.cgen-window a, #login a {
  color: #000;
  font-weight: 400;
}

form * {
  margin-bottom: 5px;
}

#login input[type="submit"] {
  background-color: #26890D;
  color: #fff;

  border: 0;
  border-radius: 14px;
  padding: 8px 15px;
  margin-left: 20px;
  margin-top: 10px;

  font-size: 16px;
  font-weight: 600;

  cursor: pointer;
}

#main-codegen {
  width: 80%;

  position: absolute;
  left: 50%;
  top: 50px;

  transform: translateX(-50%);
}
</style>
