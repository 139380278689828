<template>
  <div id="landing">
    <MainBackground />

    <audio ref="audio-intro" :src="introSrc" preload="auto" @ended="enterExperience" />

    <div v-if="showAccessCheckText" class="pos-center; width: 50%;">
      <transition-group name="fade-landing">
        <h3 v-if="gotPlayerId && !newId && !badPlayerId" key="checking" class="pos-center">
          Checking your access code...
        </h3>
        <h3 v-if="!rateLimiterHit && gotPlayerId && newId && !badPlayerId" key="checking" class="pos-center">
          Creating a new session...
        </h3>
        <h3 v-if="badPlayerId" key="bad" class="pos-center">
          Access code not accepted <span v-if="$store.state.offlineDatabase"><br>Experience is in offline mode, please use predefined offline codes</span>
        </h3>
        <h3 v-if="noPlayerId" key="none" class="pos-center">
          You need an access code to enter the experience.
        </h3>
        <h3 v-if="rateLimiterHit" key="rateLimiterHit" class="pos-center">
          It hasn't been long since you last created a session, please wait a while and try again.
        </h3>


      </transition-group>
    </div>

    <div v-show="showTicketGraphic && !showTicketGoButton" class="pos-center ticket-audio-playing" />

    <transition name="ticket">
      <div v-show="showTicketGraphic" class="pos-center ticket-container">
        <div class="ticket-text time">
          {{ time }}
        </div>
        <div class="ticket-text date">
          {{ date }}
        </div>
        <transition name="ticket-go">
          <div v-if="showTicketGoButton" class="ticket-go-container" @click="playIntro">
            <div class="ticket-go-text pos-center">
              Click here when you are ready to depart
            </div>
            <img src="/img/landing/logo_white_tiny.png" alt="" class="ticket-go-logo">
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>
<script>
import axios from 'axios';
import MainBackground from './components/MainBackground.vue';

export default {
  name: 'App',
  components: {
    MainBackground,
  },
  props: {
    playerid: String,
    skipIntro: String,
    newId: Boolean,
  },
  data() {
    return {
      showAccessCheckText: true,
      gotPlayerId: false,
      goodPlayerId: false,
      badPlayerId: false,
      noPlayerId: false,
      rateLimiterHit: false,
      showTicketGraphic: false,
      showTicketGoButton: false,
      goButtonDelay: 3000,
      time: '13:00',
      date: 'Today'
    };
  },
  computed: {
    introSrc() {
      if (this.$store.state.isOfflineVersion)
        return 'local-asset://audio/intro_v5.mp3';

    return '/audio/intro_v5.mp3';
    },
  },
  mounted(){
    this.setTime();

    if (this.playerid) {
      this.gotPlayerId = true;

      // If the Electron version is on and offlineDatabase is used, then 
      if (this.$store.state.isOfflineVersion && this.$store.state.offlineDatabase) {
        if (this.playerid === 'db4uth03r' || this.$store.state.offlineCodes.includes(this.playerid)) {
          this.showTicket();
          this.showAccessCheckText = false;
        }
        else {
          this.badPlayerId = true;
        }
      }
      else {
        let headers = {};
        let dbURL = this.$store.state.offlineDbUrl + 'players';

        if (this.$store.state.isOfflineVersion && this.$store.state.offlineJWT) {
          headers = { 'Authorization': 'Bearer ' + this.$store.state.offlineJWT };
        }

        axios.get(dbURL, { params: { id: this.playerid }, headers })
          .then(res => {
            console.log('Reply from backend');
            console.log(res);
            this.showTicket();
            this.showAccessCheckText = false;
          })
          .catch(error => {
            console.log('Error from backend');
            console.log(error.toJSON());
            if (error.toJSON.status === 429) {
              this.rateLimiterHit = true;
            } else if (error.toJSON().message === 'Request failed with status code 429') {
              this.rateLimiterHit = true;
            } else {
              this.badPlayerId = true;
            }
          });
      }
    } else {
      this.noPlayerId = true;
    }
  },
  methods: {
    showTicket(){
      this.showTicketGraphic = true;
      setTimeout(()=>{
        this.showTicketGoButton = true;
      },this.goButtonDelay);
    },
    enterExperience(){
      this.showTicketGoButton = false;
      this.showTicketGraphic = false;
      this.$router.push({name: 'App', params: {id: this.playerid}});
    },
    setTime(){
      const now = new Date();
      this.time = clockTime(now);
      this.date = formatDate(now, 'dd-m-yy');
    },
    playIntro() {
      this.showTicketGoButton = false;

      if (this.skipIntro === 'skip') {
        this.showTicketGoButton = false;
        this.showTicketGraphic = false;
        this.$router.push({name: 'App', params: { id: this.playerid, skipIntro: this.skipIntro}});
      }
      else {
        this.$refs['audio-intro'].play();
      }
    }
  },
  
};
const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN',
  'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
];

function formatDate(date, format) {
    const map = {
        m: monthNames[date.getMonth()],
        mm: date.getMonth() + 1,
        dd: leadingZero(date.getDate()),
        yy: date.getFullYear().toString().slice(-2),
        yyyy: date.getFullYear()
    };

    return format.replace(/mm|m|dd|yy|yyy/gi, matched => map[matched]);
}

function clockTime(date) {
  let h = date.getHours();
  let m = date.getMinutes();
  h = leadingZero(h);
  m = leadingZero(m);
  return `${h}:${m}`;
}

function leadingZero(number) {
  let output = '';
  if (number < 10) {
    output = `0${number}`;
  } else {
    output = number;
  }
  return output;
}
</script>
<style scoped>
#landing {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  overflow: hidden;
}

.opacity-05 {
  opacity: 0.5;
}


.ticket-audio-playing {
  width: 500px;
  height: 308px;
  border-radius: 20px;
  box-shadow: 10px 10px 25px 15px rgba(255,255,255,0.3);

  animation: ticket-audio-animation infinite 3s;
}

.ticket-container {
  width: 500px;
  height: 308px;
  position: relative;
  background-color: rgb(19, 141, 111);
  border-radius: 20px;
  box-shadow: 10px 10px 25px 15px rgba(0,0,0,0.3);
  background-image: url('/img/landing/ticket_simple_v1_500px.png')
}

.ticket-text {
  font-size: 28px;
  font-weight: 400;
  text-align: center;
  color: white;
}

.time {
  position: absolute;
  top: 208px;
  left: 70px;
}

.date {
  position: absolute;
  top: 208px;
  left: 237px;
}


.ticket-go-container {
  position: absolute;
  width: 250px;
  height: 250px;
  border-radius: 125px;
  top: -120px;
  right: -150px;
  background: rgb(50,116,90);
  background: linear-gradient(315deg, rgba(50,116,90,1) 0%, rgba(50,116,90,1) 72%, rgba(112,159,141,1) 92%);
  /* border: 2px solid white; */
  cursor: pointer;
  animation-timing-function: cubic-bezier(0.265, 0.805, 0.605, 0.880); /* custom */
  animation-timing-function: ease;
  animation-name: go-flash;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes go-flash {
  0%,100% {
    filter: brightness(110%);
    box-shadow: inset 0 0 25px 15px rgba(255,255,255,0.5);
    }
  50% {
    filter: brightness(100%);
    box-shadow: inset 0 0 25px 15px rgba(255,255,255,0.2);
    }
}

.ticket-go-text {
  font-size: 20px;
  text-align: center;
  color: white;
  pointer-events: none;

  width: 160px;
}
.ticket-go-logo {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.ticket-enter, .ticket-leave-to {
  opacity: 0;
}
.ticket-enter-to, .ticket-leave {
  opacity: 1;
}
.ticket-enter-active, .ticket-leave-active {
  transition: all 1s linear;
}

.ticket-go-enter, .ticket-go-leave-to {
  opacity: 0;
}
.ticket-go-enter-to, .ticket-go-leave {
  opacity: 1;
}
.ticket-go-enter-active, .ticket-go-leave-active {
  transition: all 1s linear;
}

.fade-landing-enter, .fade-landing-leave-to {
  opacity: 0;
}
.fade-landing-enter-to, .fade-landing-leave {
  opacity: 1;
}
.fade-landing-enter-active, .fade-landing-leave-active {
  transition: all 0.5s linear;
}

@keyframes ticket-audio-animation {
  0%, 100% {
    transform: translate(-50%, -50%) scale(0.8);
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
  }
}
</style>
