<template>
  <div id="todo-wrapper">
    <h1>To do</h1>

    <div id="todo-list-wrapper">
      <perfect-scrollbar id="todo-list">
        <!-- <div v-for="(item, index) in $store.state.todoList" :key="index" class="todo-item" :class="{ 'todo-done': $store.state.todoLogic[item].done }"> -->
        <div v-for="(item, index) in getLatest" :key="index" class="todo-item" :class="{ 'todo-done': $store.state.todoLogic[item].done }">
          <div class="circle todo-marker" />
          <div class="todo-title">
            {{ todoContent[item] }}
          </div>
        </div>
      </perfect-scrollbar>
    </div>

    <DayPlanner />
  </div>
</template>

<script>
import todoContentJSON from '@/content/todos.json';
import DayPlanner from '@/components/DayPlanner.vue';

export default {
  name: 'TodoList',
  components: { DayPlanner },
  data: function() {
      return {
          todoContent: todoContentJSON,
      };
  },
  computed: {
      getLatest() {
        var list = [...this.$store.state.todoList];

        list.sort((a, b) => {
          var aObj = this.$store.state.todoLogic[a];
          var bObj = this.$store.state.todoLogic[b];

          var aOrder = aObj.order ? aObj.order : 0;
          var bOrder = bObj.order ? bObj.order : 0;

          if (aObj.done === bObj.done) {
            if (aOrder > bOrder)
              return -1;
            else if (aOrder === bOrder)
              return 0;
            else
              return 1;
          }
          else if (aObj.done === true)
            return 1;
          else if (bObj.done === true)
            return -1;
        });

        return list;
      }
  },
};
</script>

<style>
#todo-wrapper .ps .ps__rail-y::before {
  background-color: #704d46 !important;
}

#todo-wrapper .ps .ps__thumb-y, #todo-wrapper .ps .ps__rail-y:hover .ps__thumb-y {
  background-color: #704d46;
}
</style>

<style scoped>
#todo-wrapper {
  z-index: 800;
  /* position: absolute;
  left: 50%;
  top: 50%;
  
  transform: translate(-50%, -50%); */
}

#todo-wrapper h1 {
  position: absolute;
  top: 9vh;
  left: 50%;

  transform: translateX(-50%);
  z-index: 801;

  font-weight: 600;
  color: #fff;

  text-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

#todo-list-wrapper {
  position: absolute;
  top: 32%;
  left: calc(49% - 60vh);
  /* transform: translate(-50%, -50%); */

  width: 60vh;
  height: 65%;

  z-index: 801;
}

#todo-list {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 80%;
  /* max-height: 50%; */

  padding-right: 20px;

  /* transform: translate(-50%, -50%); */
  z-index: 801;

  overflow: auto;
}

.todo-item {
  /* padding: 20px 0; */
  color: #fff;
  border-top: 1px solid #fff;

  font-size: 14px;
  position: relative;
  height: 40px;
  font-weight: 600;

  text-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}
.todo-item:last-child {
  border-bottom: 1px solid #fff;
}

.todo-done .todo-marker {
  background-color: #fff;
  /* background-color: rgba(255, 255, 255, 0.6); */
}
.todo-done .todo-title {
  text-decoration: line-through;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 400;
}

.todo-marker {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 12px;
  border: 1px solid #fff;
}

.todo-title {
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translateY(-50%);
}
</style>
