<template>
  <Subtitles v-show="showSubtitles">
    {{ videoSubtitle }}
  </Subtitles>
</template>

<script>
import Subtitles from '@/components/Subtitles.vue';

export default {
  name: 'VideoSubtitles',
  components: { Subtitles },
  computed: {
    videoSubtitle() {
      return this.$store.state.videoSubtitle;
    },
    showSubtitles() {
      if (!this.$store.state.callPlaying)
        return false;

      if (this.videoSubtitle && this.$store.state.showSubtitles)
        return true;

      return false;
    }
  },
};
</script>

<style scoped>
</style>
