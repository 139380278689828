<template>
  <div id="landing">
    <MainBackground />

    <div id="login" class="pos-center" style="text-align: center">
      <form v-if="!showSpinner" @submit.prevent="offlineAuthSubmit">
        <div>
          <strong>Authentication for Database Access</strong>
        </div>

        <p style="margin-bottom: 20px">
          Enter user credentials here to allow access to<br>generated access codes and saving takeaways.
        </p>

        Username: <input v-model="username" type="text" placeholder="Username"><br>
        Password: <input v-model="password" type="password" placeholder="Password"><br>
        <input type="submit" value="Log In">

        <div v-if="error" class="error">
          {{ error }}
        </div>
      </form>

      <div v-if="!showSpinner" class="offlinemode">
        <a @click="offlineWithoutDb">Click here for Offline version<br>(Takeaways ONLY available while application is running)</a>
      </div>

      <div v-if="showSpinner" id="spinner-container" class="pos-center">
        <LoadingSpinner id="spinner" key="spinner" />
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import MainBackground from './components/MainBackground.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  name: 'OfflineLogin',
  components: {
    MainBackground,
    LoadingSpinner,
  },
  data() {
    return {
      username: '',
      password: '',
      showSpinner: false,
      error: '',
    };
  },
  methods: {
    offlineWithoutDb() {
      this.$store.commit('setOfflineDatabase', true);
      window.ipcRenderer.send('setAppAuthentication', { status: true, token: '' });

      this.$router.push('/');
    },
    offlineAuthSubmit() {
      if (!this.username || !this.password) {
        this.error = 'Please provide a username and password';
        return;
      }

      this.showSpinner = true;
      this.error = '';

      let dbURL = 'https://www.enternetzero.com/.netlify/functions/authofflineversion';

      axios.post(dbURL, {
        username: this.username,
        password: this.password
      })
        .then(res => {
          console.log('Reply from backend');

          this.showSpinner = false;

          if (res.data['token']) {
            console.log('Stored JWT token in Vuex store');
            window.ipcRenderer.send('setAppAuthentication', { status: true, token: res.data['token'] });
            this.$store.commit('setOfflineToken', res.data['token']);

            this.$router.push('/');
          }
          else {
            this.error = 'Incorrectly formatted response from the database. Please try again or contact your administrator';
          }
        })
        .catch(error => {
          console.log('Error from backend');

          if (error.response) {
            this.error = error.response.data.message;
          }
          else if (error.request) {
            this.error = 'Could not contact database';
          }
          else {
            this.error = 'An unknown error occurred';
          }

          this.showSpinner = false;
        });
    },
  },
};
</script>


<style scoped>
#landing {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  overflow: hidden;
}

#login {
  background-color: #fff;
  border-radius: 5px;

  padding: 30px 60px;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.3);

  min-height: 240px;
  min-width: 400px;
}

#login a {
  color: #000;
  font-weight: 400;
}

.offlinemode {
  margin-top: 40px;
}

form * {
  margin-bottom: 5px;
}

#login input[type="submit"] {
  background-color: #26890D;
  color: #fff;

  border: 0;
  border-radius: 14px;
  padding: 8px 15px;
  margin-left: 20px;
  margin-top: 10px;

  font-size: 16px;
  font-weight: 600;

  cursor: pointer;
}
</style>
