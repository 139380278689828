<template>
  <div id="bg-main">
    <div id="login-background">
      <svg id="svg-login-background">

        <circle cx="62%" cy="50%" r="35%" class="login-c4" fill="#C6D9D1" />

        <circle cx="33%" cy="40%" r="20%" class="login-c0" fill="#FCFFCB" />
        <circle cx="40%" cy="65%" r="20%" class="login-c3" fill="#B3D59D" />
        <circle cx="23%" cy="55%" r="19%" class="login-c2" fill="#DBEDBF" />

        <circle cx="50%" cy="20%" r="12%" class="login-c1" fill="#fcf2ed" />
        
        <!-- <circle cx="20%" cy="65%" r="20%" class="login-c5" fill="#D7D7D7" />
        <circle cx="35%" cy="65%" r="15%" class="login-c6" fill="#90B888" />
        <circle cx="50%" cy="65%" r="20%" class="login-c7" fill="#6D8760" />
        <circle cx="65%" cy="65%" r="18%" class="login-c8" fill="#536444" />
        <circle cx="83%" cy="65%" r="12%" class="login-c9" fill="#353534" /> -->
      </svg>
    </div>

    <div id="bg-overlay">
      &nbsp;
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginBackground',
};
</script>

<style scoped>
#bg-main {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  z-index: 850;

  opacity: 1;
  filter: blur(40px);
}
#bg-main-svg {
  height: 100%;
  width: 100%;
}
#bg-overlay {
  background-color: white;
  opacity: 0.8;
  height: 100%;
  width: 100%;

}

.bg-circle0 {
  fill: #85A59E;
  /* animation: animation-c0 10s linear infinite; */
}
.bg-circle1 {
  /* animation: animationC1 10s linear infinite; */
  fill: #90B888;
  transform-origin: center;
}
.bg-circle2 {
  fill: #6D8760;
}
.bg-circle3 {
  fill: #536444;
  transform-origin: center;
  /* animation: animationC3 10s linear infinite; */
}


#login-background {
  filter: blur(20px);
  z-index: 851;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#svg-login-background {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
</style>
