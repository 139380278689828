<template>
  <transition name="email-appear" appear>
    <div id="email-window" class="fullscreen">
      <div id="news-image" class="fullscreen">
        <transition name="video-appear">
          <video v-if="hasVideo" :src="videoSrc" preload="auto" autoplay loop muted />
        </transition>
      </div>

      <transition name="bg-appear">
        <div id="bg-middle" :class="{ 'bg-breaking1': breakingNews, 'bg-showing': ($store.state.newsList[showNews] !== 'blankplaceholder') }" />
      </transition>

      <div id="bg-right" :class="{ 'bg-breaking2': breakingNews }" />
      <div id="news-client" class="fullscreen">
        <div id="email-window-title">
          Hub
        </div>

        <perfect-scrollbar id="news-list" ref="ps">
          <div id="news-headline-feature">
            <div class="title">
              Latest headlines
            </div>
            <div v-for="(item) in $store.state.showHeadline" :key="item" class="headline" @click.stop.prevent="openHeadline(item)">
              <div class="heading">
                {{ news[item].heading }}
              </div>
              <div class="summary">
                {{ news[item].summary }}
              </div>
            </div>
          </div>

          <div id="news-search">
            <div class="title">
              Search the News
            </div>

            <SearchBar @user-input="updatePS" />
          </div>
        </perfect-scrollbar>

        <div id="email-body">
          <transition-group name="show-email" tag="div">
            <perfect-scrollbar v-for="(item, index) in $store.state.newsList" v-show="showNews == index && !breakingNews" :key="item" class="news-message">
              <div v-show="news[$store.state.newsList[index]].heading !== ' ' && news[$store.state.newsList[index]].image">
                <picture>
                  <img :src="'/img/news/' + news[$store.state.newsList[index]].image" alt="Article Header Image">
                </picture>
              </div>

              <h1 v-show="news[$store.state.newsList[index]].heading !== ' '">
                {{ news[$store.state.newsList[index]].heading }}
              </h1>

              <div v-show="news[$store.state.newsList[index]].heading !== ' '" class="news-source">
                {{ news[$store.state.newsList[index]].source }}
              </div>

              <div class="news-article-body" @click.stop.prevent="openLink" v-html="news[$store.state.newsList[index]].article" />
            </perfect-scrollbar>
          </transition-group>

          <transition name="breaking">
            <div v-show="breakingNews" id="news-breaking" class="pos-center">
              BREAKING NEWS
            </div>
          </transition>
        </div>
      </div>

      <div v-if="$store.state.devEnvironmentLocal === true" style="position: absolute; color: #000; z-index: 800;" @click="clearNews">
        Clear News
      </div>
    </div>
  </transition>
</template>

<script>
import newsContentJSON from '@/content/news.json';
import SearchBar from '@/components/SearchBar.vue';

export default {
  name: 'NewsRolodex',
  components: {
    SearchBar,
  },
  data: function() {
    return {
      news: newsContentJSON,
    };
  },
  computed: {
    showNews: function() {
      return this.$store.state.showNews;
    },
    breakingNews: function() {
      return this.$store.state.breakingNews;
    },
    sidebarHeadline: function() {
      return this.news[this.$store.state.showHeadline].heading;
    },
    imgSrc: function() {
      return '/img/news/' + this.$store.state.newsList[this.showNews] + '.png';
    },
    hasVideo: function() {
      if (!('video' in this.news[this.$store.state.newsList[this.showNews]])){
        return false;
      } else {
        return true;
      }
    },
    videoSrc: function() {
      if (this.$store.state.isOfflineVersion) {
        return 'local-asset://video/h264/news/' + this.news[this.$store.state.newsList[this.showNews]].video;
      }
      const video = document.createElement('video');
      if (video.canPlayType('video/mp4; codecs="hvc1.1.6.H120.90"')){
        return '/video/h265/news/' + this.news[this.$store.state.newsList[this.showNews]].video;
      } else {
        return '/video/h264/news/' + this.news[this.$store.state.newsList[this.showNews]].video;
      }
    },
  },
  watch: {
    showNews: function() {
      this.updateRead();
    }
  },
  mounted() {
    this.updateRead();
  },
  beforeDestroy() {
    // Clear view if the player has pressed the home button.
    // If the player has followed a link, keep the current article in view
    // until the next time they leave the app.

    if (this.$store.state.backbutton)
      return;

    this.clearNews();
  },
  methods: {
    updateRead() {
      var newsItem = this.$store.state.newsLogic[this.$store.state.newsList[this.showNews]];

      console.log(this.$store.state.newsList[this.showNews]);

      if (newsItem.read)
        return;

      this.$store.commit('newsRead', this.$store.state.newsList[this.showNews]);
      this.$emit('update-status', this.$store.state.newsList[this.showNews]);
    },
    openLink(evt) {
      if (!evt.target.dataset.link)
        return;

      var app = 'investor';

      if (evt.target.dataset.app)
        app = evt.target.dataset.app;

      this.$store.commit('setAppDisplay', { app: app, item: evt.target.dataset.link });
      this.$store.dispatch('setView', app);
      this.$store.commit('setBackButton', 'news');
    },
    openHeadline(item) {
      console.log('Opening headline: ' + item);
      this.$store.commit('setAppDisplay', { app: 'news', item: item });
    },
    clearNews() {
      this.$store.commit('setAppDisplay', { app: 'news', item: 'blankplaceholder' });
    },
    updatePS() {
      setTimeout(() => {
        this.$refs['ps'].update();

        this.$refs['ps']['$el'].scrollTop = 250;
      }, 100);
    },
  }
};
</script>

<style>
.news-article-body a {
  color: #F18682;
}

.news-article-body .tooltip {
  color: #F18682;
}
.news-article-body .description {
  background-color: #F18682;
}

.news-article-body .tooltip .description:before {
  border-bottom: solid #F18682 10px;
}

.news-message.ps .ps__rail-y::before {
  background-color: #6D8760 !important;
}

.news-message.ps .ps__thumb-y, .news-message.ps .ps__rail-y:hover .ps__thumb-y {
  background-color: #6D8760;
}

#news-list.ps .ps__rail-y::before {
  background-color: #3a4734 !important;
}

#news-list.ps .ps__thumb-y, #news-list.ps .ps__rail-y:hover .ps__thumb-y {
  background-color: #3a4734;
}
</style>

<style scoped>
#bg-middle {
  border-radius: 50%;
  height: 400%;
  width: 80%;
  position: absolute;
  top: -150%;
  left: 75%;

  background-color: #fff;
  z-index: 35;

  transition: all 1s linear;
}

#bg-right {
  border-radius: 50%;
  height: 250%;
  width: 50%;
  position: absolute;
  top: 50%;
  left: 73%;

  transform: translateY(-50%);

  /* background-color: rgba(0, 0, 0, 0.2); */
  background-color: #65935c;
  z-index: 35;
}

#news-headline-feature {
  margin-bottom: 35px;
}
#news-headline-feature .title, #news-search .title {
  font-size: 24px;
  font-weight: 800;
}
#news-headline-feature .title::after, #news-search .title::after {
  display: block;
  content: " ";
  position: relative;
  top: -30px;
  left: -1%;
  width: 80%;
  height: 30px;
  box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.2);
  margin-bottom: -10px;
}

#news-headline-feature .headline {
  font-weight: 700;
  font-size: 16px;

  cursor: pointer;
}
#news-headline-feature .headline .heading {
  font-weight: 700;
  font-size: 16px;

  cursor: pointer;
}
#news-headline-feature .summary {
  font-weight: 400;
  font-size: 14px;

  cursor: pointer;
  margin-bottom: 30px;
}


.email-appear-enter-active {
  transition: all 0.5s linear;
  /* transition-delay: 1s; */
}
.email-appear-enter {
  opacity: 0;
}
#email-window {
  color: #fff;

  /* z-index: 100; */
}
#email-window-title {
  position: absolute;
  top: calc(5% + 28px);
  right: 15%;
  font-size: 1.5em;
  font-weight: 200;
  cursor: default;

  transform: translateY(-50%);
}
#news-list {
  position: absolute;
  top: calc(5% + 100px);
  right: 40px;

  width: 21%;
  height: 70%;

  padding-right: 20px;

  font-size: 16px;
  overflow: auto;
}

#news-client {
  z-index: 100;
}

#news-image {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 33;
}

#news-image img, #news-image video {
  height: 100%;
  max-width: none;
  width: auto;
  transform: translateX(-40%);
}

.email-title {
  transition: opacity 1s linear;
  /* transition: color 1s linear; */
  /* transition: all 1s linear; */
  margin-bottom: 10px;
}
.email-title-text {
  max-height: 2.9em;
  white-space: normal;
  overflow: auto;
  transform: translateX(20px);
}

.email-title-text.unread {
  font-weight: 700;
}

#email-body {
  position: absolute;
  left: 24%;
  top: 50%;
  width: 45%;
  height: 75%;

  transform: translateY(-50%);
  font-size: 14px;

  color: #000;
}

.news-message {
  position: absolute;
  left: 0;
  top: 0;
  /* transform: translateY(-50%); */
  overflow: auto;
  white-space: pre-line;
  width: 100%;
  height: 100%;
  padding-right: 60px;
}

.news-message picture * {
  width: 100%;
}

.news-message h1 {
  color: #6D8760;

  margin-bottom: 10px;

  font-weight: 800;
}

.news-source {
  border-top: #aaa 1px solid;

  padding-top: 10px;
  margin-bottom: 30px;

  font-size: 12px;
  font-weight: 500;
}

.news-article-body {
  user-select: text;
}

.show-email-enter-active {
  transition: all 1s linear;
  transition-delay: 0.3s;
}
.show-email-leave-active {
  transition: all 0.5s linear;
}
.show-email-enter, .show-email-leave-to, .show-email-leave {
  opacity: 0;
}
.show-email-enter-to {
  opacity: 1;
}

#bg-middle.bg-breaking1 {
  background-color: #ed716c;
}
#bg-right.bg-breaking2 {
  background-color: #be6357;
}

#news-breaking {
  font-size: 4em;
  font-weight: 700;
  text-align: center;

  width: 90%;
}

.breaking-enter-active {
  animation: breaking-animation 2s;
}
@keyframes breaking-animation {
  0%, 20%, 60%, 100% {
    opacity: 0;
  }
  10%, 40%, 80% {
    opacity: 1;
  }
}

.bg-showing {
  transform: translateX(-70%);
}

.video-appear-enter-active, .video-appear-leave-active {
  transition: opacity 0.5s linear;
}
.video-appear-enter-active {
  transition-delay: 1s;
}
.video-appear-enter, .video-appear-leave-to {
  opacity: 0;
}
</style>
