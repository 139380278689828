<template>
  <div id="landing">
    <MainBackground />

    <div id="login" class="pos-center" style="text-align: center">
      <form @submit.prevent="codeSelect">
        <div style="padding-top: 10px; padding-bottom: 10px">
          <strong>Takeaway Document</strong>
        </div>

        <div v-if="$store.state.offlineDatabase">
          Select Access Code:<br>

          <select v-model="accessCode">
            <option disabled value="">Code</option>
            <option v-for="code in offlineCodeList" :key="code" :value="code">
              {{ code }}
            </option>
          </select>
        </div>
        <div v-else>
          <input v-model="accessCode" type="text" placeholder="Enter access code">
        </div>

        <input type="submit" value="Show">
      </form>

      <div v-if="error" class="error">
        {{ error }}
      </div>
    </div>
  </div>
</template>


<script>
import MainBackground from './components/MainBackground.vue';

export default {
  name: 'OfflineTakeaways',
  components: {
    MainBackground,
  },
  data() {
    return {
      accessCode: '',
      error: '',
    };
  },
  computed: {
    offlineCodeList() {
      let codeList = this.$store.state.offlineCodes;
      codeList.sort();
      return codeList;
    }
  },
  methods: {
    codeSelect() {
      if (this.accessCode === '') {
        this.error = 'Please enter an access code';
        return;
      }

      this.$router.push('/document/' + this.accessCode.trim());
    }
  }
};
</script>


<style scoped>
#landing {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  overflow: hidden;
}

#login {
  background-color: #fff;
  border-radius: 5px;

  padding: 20px 60px;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.3);
}

#login a {
  color: #000;
  font-weight: 400;
}

.offlinemode {
  margin-top: 40px;
}

form * {
  margin-bottom: 5px;
}

#login input[type="submit"] {
  background-color: #26890D;
  color: #fff;

  border: 0;
  border-radius: 14px;
  padding: 5px 15px;
  margin-left: 20px;
  margin-top: 10px;

  font-size: 14px;
  font-weight: 600;

  cursor: pointer;
}

select {
  font-family: 'Open Sans', sans-serif;
}
</style>
