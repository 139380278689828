<template>
  <div id="departure">
    <audio ref="audio-outro" :src="outroSrc" preload="auto" @ended="outroAudioCompleted" />

    <div v-show="$store.state.currentView === 'end' && showDepartButton" id="departure-button" class="circle animation-pop" @click="toDepartureScreen">
      <div class="widget-content">
        Depart?
      </div>
    </div>

    <transition name="ticket">
      <div v-show="showTicketGraphic && showDepartScreen" id="departure-ticket">
        <div class="bg-opaque fullscreen" />
        <MainBackground />

        <div class="pos-center ticket-container">
          <div class="ticket-text time">
            {{ time }}
          </div>
          <div class="ticket-text date">
            {{ date }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import MainBackground from './MainBackground.vue';

export default {
  name: 'App',
  components: {
    MainBackground,
  },
  data() {
    return {
      gotPlayerId: false,
      goodPlayerId: false,
      badPlayerId: false,
      noPlayerId: false,
      showTicketGraphic: true,
      showTicketGoButton: false,
      goButtonDelay: 3000,
      time: '13:00',
      date: 'Today',
      showDepartButton: true,
      showDepartScreen: false,
    };
  },
  computed: {
    outroSrc() {
      if (this.$store.state.isOfflineVersion)
        return 'local-asset://audio/outro_v5.1.mp3';

    return '/audio/outro_v5.1.mp3';
    },
  },
  mounted(){
    this.setTime();
  },
  methods: {
    showTicket(){
      this.showTicketGraphic = true;
      setTimeout(()=>{
        this.showTicketGoButton = true;
      },this.goButtonDelay);
    },
    setTime(){
      const now = new Date();
      this.time = clockTime(now);
      this.date = formatDate(now, 'dd-m-yy');
    },
    toDepartureScreen() {
      // this.$store.dispatch('setView', 'departure');
      this.$refs['audio-outro'].play();

      this.showDepartButton = false;
      this.showDepartScreen = true;
    },
    outroAudioCompleted() {
      this.showDepartScreen = false;
    },
  },
  
};
const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN',
  'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
];

function formatDate(date, format) {
    const map = {
        m: monthNames[date.getMonth()],
        mm: date.getMonth() + 1,
        dd: leadingZero(date.getDate()),
        yy: date.getFullYear().toString().slice(-2),
        yyyy: date.getFullYear()
    };

    return format.replace(/mm|m|dd|yy|yyy/gi, matched => map[matched]);
}

function clockTime(date) {
  let h = date.getHours();
  let m = date.getMinutes();
  h = leadingZero(h);
  m = leadingZero(m);
  return `${h}:${m}`;
}

function leadingZero(number) {
  let output = '';
  if (number < 10) {
    output = `0${number}`;
  } else {
    output = number;
  }
  return output;
}
</script>

<style scoped>
#departure-ticket {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  z-index: 910;
}

.bg-opaque {
  background-color: #eee;

  z-index: -2;
}

.opacity-05 {
  opacity: 0.5;
}

#departure-button {
  z-index: 910;

  position: absolute;

  right: 20px;
  top: 50%;

  transform: translateY(-50%);

  width: 10%;
  min-width: 100px;

  /* background-color: #fff; */
  background-color: #90b888;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);

  cursor: pointer;
}
#departure-button .widget-content {
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
}



.ticket-container {
  width: 500px;
  height: 308px;
  position: relative;
  background-color: rgb(19, 141, 111);
  border-radius: 20px;
  box-shadow: 10px 10px 25px 15px rgba(0,0,0,0.3);
  background-image: url('/img/landing/ticket_master_v2.png')
}

.ticket-text {
  font-size: 28px;
  font-weight: 400;
  text-align: center;
  color: white;
}

.time {
  position: absolute;
  top: 208px;
  left: 70px;
}

.date {
  position: absolute;
  top: 208px;
  left: 237px;
}

.ticket-go-container {
  position: absolute;
  width: 250px;
  height: 250px;
  border-radius: 125px;
  top: -120px;
  right: -150px;
  background: rgb(50,116,90);
  background: linear-gradient(315deg, rgba(50,116,90,1) 0%, rgba(50,116,90,1) 72%, rgba(112,159,141,1) 92%);
  /* border: 2px solid white; */
  cursor: pointer;
  animation-timing-function: cubic-bezier(0.265, 0.805, 0.605, 0.880); /* custom */
  animation-timing-function: ease;
  animation-name: go-flash;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes go-flash {
  0%,100% {
    filter: brightness(110%);
    box-shadow: inset 0 0 25px 15px rgba(255,255,255,0.5);
    }
  50% {
    filter: brightness(100%);
    box-shadow: inset 0 0 25px 15px rgba(255,255,255,0.2);
    }
}

.ticket-go-text {
  font-size: 20px;
  text-align: center;
  color: white;
  pointer-events: none;

  width: 160px;
}
.ticket-go-logo {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.ticket-enter, .ticket-leave-to {
  opacity: 0;
}
.ticket-enter-to, .ticket-leave {
  opacity: 1;
}
.ticket-enter-active, .ticket-leave-active {
  transition: all 1s linear;
}

.ticket-go-enter, .ticket-go-leave-to {
  opacity: 0;
}
.ticket-go-enter-to, .ticket-go-leave {
  opacity: 1;
}
.ticket-go-enter-active, .ticket-go-leave-active {
  transition: all 1s linear;
}

.fade-landing-enter, .fade-landing-leave-to {
  opacity: 0;
}
.fade-landing-enter-to, .fade-landing-leave {
  opacity: 1;
}
.fade-landing-enter-active, .fade-landing-leave-active {
  transition: all 0.5s linear;
}
</style>
