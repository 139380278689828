<template>
  <div id="bg-main">
    <div id="bg-leftcol" />
    <div id="bg-middle" />
    <div id="bg-right" />
  </div>
</template>

<script>
export default {
  name: 'NewsBackgroundHome',
};
</script>

<style scoped>
#bg-main {
  z-index: 30;
}

#bg-leftcol {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  background-color: #eee;
  z-index: 30;
}

#bg-middle {
  border-radius: 50%;
  height: 300%;
  width: 100%;
  position: absolute;
  top: 40%;
  left: 75%;

  transform: translate(-50%, -50%);
  /* transform: translate(0, -50%); */
  filter: blur(45px);

  background-color: #95CC8D;
  z-index: 30;
}

#bg-right {
  border-radius: 50%;
  height: 250%;
  width: 80%;
  position: absolute;
  top: 50%;
  left: 70%;

  transform: translateY(-50%);

  background-color: rgba(0, 0, 0, 0.2);
  z-index: 30;
}
</style>
