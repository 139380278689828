<template>
  <div id="end-wrapper" class="">
    
    <div id="page-background" class="fullscreen" />
    <div class="pos-center">
      <div id="page-title-circle" class="circle" />
      <div id="option-buttons">
        <button @click="requestPDF">
          Save PDF
        </button>
      </div>
      <h1 id="page-title" class="fw-300">
        Your Investment Report
      </h1>

      <div class="page-content">
        <div class="intro-statement">
          <div class="category-title" v-html="takeawayContent.intro_statement" />
          <div class="intro-brief" v-html="takeawayContent.intro_statement_brief" />
        </div>

        <!-- YOUR INVESTMENTS DROPDOWN -->
        <div class="dropdown" @click="toggleCategoryDisplay('investments')" :class="{ categoryShowing: displayCategory.includes('investments') }">
          Your Investments
          <span class="category-indicator" :class="{ categoryShowing: displayCategory.includes('investments') }">></span>
        </div>
        <transition name="tr-takeaway">
          <div class="investment-snapshot-wrapper" v-show="displayCategory.includes('investments')" :class="{ categoryShowing: displayCategory.includes('investments') }">
            <div class="investment-snapshot">
              <div v-for="(category, index) in categoriesWithInvestments" :key="'1-'+index" class="portfolio-box">
                <div class="category-title">{{ category.name }}</div>

                <div v-for="(company, companykey) in category.companies" :key="companykey" class="portfolio-box">
                  <img :src="'/img/logos/' + companykey + '.svg'" :alt="company.name + ' Logo'" class="company-name">

                  <div v-show="playerInvestments[companykey] > 0" class="company-investment">
                    <span class="total-portfolio-worth investment-grad">{{ computeInvestment(playerInvestments[companykey]) }}</span>
                  </div>
                  
                  <div class="company-badge" :class="chooseBadgeColour(company.comment)">
                    {{ company.comment }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>


        <!-- INTRODUCTION DROPDOWN -->
        <div class="dropdown understanding-box" @click="toggleCategoryDisplay('understanding')" :class="{ categoryShowing: displayCategory.includes('understanding') }">
          Understanding more about the experience
          <span class="category-indicator" :class="{ categoryShowing: displayCategory.includes('understanding') }">></span>
        </div>
        <transition name="tr-takeaway">
          <div class="introduction" v-show="displayCategory.includes('understanding')" v-html="takeawayContent.introduction_html" :class="{ categoryShowing: displayCategory.includes('understanding') }" @click="clickHandler" />
        </transition>

        <div class="empty-spacer" :style="{ display: (displayCategory.includes('understanding') ? 'none' : 'block') }" />

        <!-- CATEGORY SUMMARIES -->
        <div v-for="(category, index) in takeawayContent.categories" :key="'1-'+index" class="portfolio-box">
          <!-- CATEGORY TITLE -->
          <div class="fw-600 category-title" @click="toggleCategoryDisplay(category.name)" :style="{ 'background-color': category.color }">
            {{ category.name }}
            <span class="category-indicator" :class="{ categoryShowing: displayCategory.includes(category.name) }">></span>
          </div>

          <transition name="tr-takeaway">
            <div v-show="displayCategory.includes(category.name)" class="category-hide-container">
              <!-- CATEGORY INTRO -->
              <div class="category-intro" v-html="category.intro_html" />

              <!-- COMPANY BREAKDOWNS -->
              <div v-for="(company, companykey) in category.companies" :key="companykey" class="portfolio-box divider">
                <div class="box-header">
                  <div class="box-header-company">
                    <img :src="'/img/logos/' + companykey + '.svg'" :alt="company.name + ' Logo'" class="company-name">

                    <div v-show="playerInvestments[companykey] > 0" class="company-investment">
                      You invested<br><span class="total-portfolio-worth investment-grad fw-600">{{ computeInvestment(playerInvestments[companykey]) }}</span>
                    </div>

                    <div class="company-badge" :class="chooseBadgeColour(company.comment)">
                      {{ company.comment }}
                    </div>
                  </div>
                </div>
                <div class="box-summary" v-html="company.summary_html" />
              </div>
            </div>
          </transition>
        </div>

        <div class="learn-more">
          <div class="category-title">Learn more about sustainability</div>

          <div class="learn-more-box">
            <div class="box">
              <div class="box-title"><span>Climate hub</span></div>
              <div class="box-info">
                <p><a href="https://www2.deloitte.com/uk/en/explore/sustainability-and-climate-change.html/#-" target="_blank">Welcome to our hub for all things climate change</a>, where you'll find lots of resources and ideas to help you take action where it matters most.</p>
              </div>
            </div>

            <div class="box">
              <div class="box-title"><img src="/img/WorldClimate_wordmark.png" alt="WorldClimate wordmark"></div>
              <div class="box-info">
                <p>We recognize change starts within. Deloitte must set and meet higher standards for itself, empowering our professionals and connecting with our broader ecosystem to create solutions that facilitate the transition to a low carbon economy.</p>
                <p><a href="https://www2.deloitte.com/global/en/pages/about-deloitte/articles/world-climate.html" target="_blank">Read more here</a></p>
              </div>
            </div>

            <div class="box">
              <div class="box-title"><a href="https://giki.earth/" target="_blank"><img src="/img/giki_logo.png" alt="Giki Logo"></a></div>
              <div class="box-info"><a href="https://giki.earth/" target="_blank">Giki</a> can support you with a greater understanding of sustainability and the impact you can make in your everyday life.</div>
            </div>
          </div>
        </div>

        <!-- FINAL STATEMENT -->
        <div class="final-statement">
          <div class="category-title" v-html="takeawayContent.final_section_title" />
          <div v-html="takeawayContent.final_section_html" />
        </div>
      </div>
    </div>

    <div id="takeawayImg" v-show="displayImg" @click="closeImg">
      <img :src="takeawayContent.introduction_img">
      <div class="close">
        &times;
      </div>
    </div>
  </div>
</template>

<script>
import takeawayContentJSON from '@/content/takeaway.json';

export default {
  name: 'EndScreen',
  components: {  },
  props: {
    playerInvestmentsInput: {
      type: Object,
      default: null,
    },
    groupInvestmentsInput: {
      type: Object,
      default: null,
    },
  },
  data: function() {
    return {
      takeawayContent: takeawayContentJSON,
      displayCategory: ['investments'],
      displayImg: false
    };
  },
  computed: {
    playerInvestments(){
      return this.playerInvestmentsInput || this.$store.state.investmentChoices;
    },
    groupInvestments(){
      return this.groupInvestmentsInput;
    },
    categoriesWithInvestments() {
      var output = {};

      for (let category in this.takeawayContent.categories) {
        for (let company in this.takeawayContent.categories[category].companies) {
          if (this.playerInvestments[company] > 0) {
            if (!Object.keys(output).includes(category)) {
              output[category] = { name: this.takeawayContent.categories[category].name, companies: {}};
            }

            output[category].companies[company] = this.takeawayContent.categories[category].companies[company];
          }
        }
      }

      return output;
    }
  },
  mounted(){
    
  },
  methods: {
    chooseBadgeColour(comment){
      switch(comment){
        case 'VERY STRONG INVESTMENT':
          return 'badge-col-very-strong';
        case 'STRONG INVESTMENT':
          return 'badge-col-strong';
        case 'WEAK INVESTMENT':
          return 'badge-col-weak';
        case 'VERY WEAK INVESTMENT':
          return 'badge-col-very-weak';      
      }
    },
    computeInvestment(amount) {
      if (!amount)
        return ' ';
      
      return '£' + amount.toLocaleString('en-US') + 'k';
    },
    computeReturn(amount, company) {
      if (!amount)
        return ' ';
      console.log(company);
      var ret = ((this.takeawayContent.rates[company] - 1).toFixed(2) * amount);

      var returnstring = '';

      if (ret > 0)
        returnstring += '+';
      else if (ret < 0)
        returnstring += '-';

      returnstring += '£' + Math.abs(ret).toLocaleString('en-US') + 'k';

      return returnstring;
    },
    removeNonInvestors(investorsInput, company){ //Company optional.
      const investors = JSON.parse(JSON.stringify(investorsInput));
      for (let investor in investors) {
        if (!investors[investor].investments) {
          delete investors[investor];
        } else if (company && !investors[investor].investments[company]){
          delete investors[investor];
        }
      }
      return investors;
    },
    toggleCategoryDisplay(categoryName) {
      let idx = this.displayCategory.indexOf(categoryName);

      if (idx > -1) {
        this.displayCategory.splice(idx, 1);
      }
      else {
        this.displayCategory.push(categoryName);
      }
    },
    clickHandler(evt) {
      if (evt.target.className !== 'imgexpand') {
        return;
      }

      this.displayImg = true;
    },
    closeImg() {
      this.displayImg = false;
    },
    requestPDF(){
      window.open(window.location.origin + '/.netlify/functions/pdftakeaway?investments=' + JSON.stringify(this.playerInvestments), '_blank');
    }
  }
};
</script>

<style>
#option-buttons {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 10;
  display: flex;
}

#option-buttons button {
  margin-right: 10px;
  background-color: #26890D;
  color: #fff;
  border: 0;
  border-radius: 14px;
  padding: 8px 15px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

#end-wrapper a {
  color: #000;
}

.final-statement a {
  color: #fff !important;
}
</style>

<style scoped>
#page-title-circle {
  position: absolute;
  left: 50%;
  bottom: calc(100% - 150px);
  transform: translateX(-50%);
  width: 150%;
  z-index: 1;
  background-color: #86bc25;
}
#page-background {
  background-color: #f8f8f8;
  z-index: 30;
}

#page-title {
  position: absolute;
  left: 50%;
  top: 30px;
  transform: translateX(-50%);
  font-size: 2em;
  max-width: 500px;
  line-height: 1.2em;
  z-index: 2;
  color: #fff;
  font-weight: 600;
}

#end-wrapper {
  z-index: 900;
}

#takeawayImg {
  z-index: 995;
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: #fff;
}
#takeawayImg img {
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
}
#takeawayImg .close {
  font-size: 70px;
  font-weight: 200;
  cursor: pointer;
  display: inline;
  position: absolute;
  top: 0;
  right: 30px;
}

.pos-center h1 {
  text-align: center;
}

.dropdown, .learn-more .category-title {
  text-align: center;
  font-size: 26px;
  color: #353535;

  cursor: pointer;
  margin-top: 40px;
}
.learn-more .category-title {
  cursor: default;
}

.learn-more-box {
  display: flex;
  padding: 0 10%;
  flex-wrap: wrap;
  justify-content: center;
}
.learn-more-box .box {
  width: 28%;
  margin: 0 15px;
  min-width: 300px;
  margin-bottom: 30px;
}
.learn-more-box .box-title {
  color: #2c5234;
  text-align: center;
  font-size: 26px;
  height: 70px;

  position: relative;
}
.learn-more-box .box-title > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.learn-more-box .box-title > span {
  width: 100%;
}
.learn-more-box .box-title > img {
  cursor: default;
}
.learn-more-box .box-info {
  text-align: center;
}
.learn-more-box .box-info p {
  margin-top: 0;
}
.box img {
  max-width: 150px;
  cursor: pointer;
}
.learn-more-box a {
  color: #2c5234 !important;
}

.empty-spacer {
  content: " ";
  width: 100%;
  height: 30px;
}

.dropdown::after, .learn-more .category-title::after {
  display: block;
  content: " ";
  position: relative;
  top: -30px;
  left: 25%;
  width: 50%;
  height: 30px;
  box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.1);
  margin-bottom: -10px;
  z-index: 20;
}

.introduction >>> .twocol {
  display: flex;
}
.introduction >>> .twocol ul {
  width: 65%;
  margin-right: 30px;
  padding-left: 0;
}
.introduction >>> .twocol div {
  width: 30%;
  cursor: pointer;
}
.introduction >>> .twocol div > img {
  width: 100%;
  cursor: pointer;
}

#end-wrapper .pos-center {
  z-index: 100;
  overflow: auto;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  /* padding: 0 10%; */

  background-color: #f8f8f8;
}

.introduction, .investment-snapshot-wrapper {
  background-color: #fff;
  margin-top: -28px;
  padding-top: 20px !important;
}
.introduction.categoryShowing::after, .investment-snapshot.categoryShowing::after {
  display: block;
  content: " ";
  position: relative;
  top: 20px;
  left: 15%;
  width: 70%;
  height: 30px;
  box-shadow: 0 -15px 10px -10px rgba(0, 0, 0, 0.1);
  margin-bottom: -10px;
}

.investment-snapshot {
  display: grid;
  padding: 0 10%;
  column-gap: 50px;
  grid-template-columns: auto auto;
  width: fit-content;

  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.investment-snapshot.categoryShowing {
  margin-bottom: 30px;
}
.investment-snapshot.categoryShowing::after {
  grid-column: span 2;
}

@media screen and (max-width: 1100px) {
  .investment-snapshot {
    grid-template-columns: auto;
  }
  .investment-snapshot.categoryShowing::after {
    grid-column: span 1;
  }
}

.investment-snapshot .category-title {
  cursor: default;
  color: #2c5234;
  padding: 0;
  width: 125px;
  flex-shrink: 0;
  margin: 0;
  font-weight: 700;
  text-align: left;
}

.investment-snapshot > .portfolio-box {
  display: flex;
  margin-bottom: 40px;
  width: auto;
}
.investment-snapshot > .portfolio-box > .portfolio-box {
  max-width: 200px;
  width: auto;
  margin-right: 10px;
}

.investment-snapshot .portfolio-box img {
  display: block;
  width: 120px;
  height: auto;
}
.investment-snapshot .portfolio-box .company-investment {
  width: auto;
}
.investment-snapshot .portfolio-box .company-investment .total-portfolio-worth {
  margin: 0 auto;
}
.investment-snapshot .portfolio-box .portfolio-box div, .investment-snapshot .portfolio-box img {
  margin: 0 auto;
}

.page-content {
  margin-top: 220px;
  /* margin-bottom: 100px; */
}

.portfolio-box {
  width: 100%;
  /* margin: 20px 0 0 0; */
  /* padding: 0 10%; */
}

.page-content >>> .portfolio-box > .category-title {
  z-index: 2;
  position: relative;
}

.divider {
  border-bottom: 1px solid rgb(175, 175, 175);
}
.portfolio-box.divider {
  margin: 0 10%;
  width: auto;
}

.introduction, .portfolio-box p, .category-intro {
  padding: 0 10%;
}

.introduction >>> p img {
  width: 100%;
  max-width: 900px;
  background-color: #fff;
}

.introduction >>> ul {
  list-style: none;
}
.introduction >>> ul li {
  margin-bottom: 20px;
}

.final-statement {
  background-color: #8C8C8C;
  color: #fff;
}

.intro-statement, .final-statement {
  padding: 40px 10%;
}
.intro-statement {
  padding-bottom: 0px;
}

.category-title {
  margin: 0 auto;
  padding: 10px 10%;
  text-align: center;
  /* font-size: 4em; */
  font-size: 1.5em;

  cursor: pointer;
  color: #fff;
}
.final-statement .category-title, .intro-statement .category-title {
  font-size: 2.5em;
  text-decoration: none;
  cursor: unset;
  padding: 0;
  font-weight: 300;
}

.intro-statement .category-title {
  color: #86bc25;
}
.intro-statement, .final-statement .category-title {
  text-align: center;
}

.category-indicator {
  transform: rotate(0deg);
  transition: transform linear 0.2s;
  display: inline-block;
  cursor: pointer;

  position: relative;
  left: 10px;
  scale: 1.5;

  font-weight: 400;
}
.category-indicator.categoryShowing {
  transform: rotate(90deg);
}

.box-header {
  display: flex;
  align-items: center;
  padding: 0 10%;
}
.box-header-company, .box-header-investment, .box-header-return {
  font-size: 1.5em;
  font-weight: 400;
  padding: 30px 0;
  position: relative;
  min-width: 200px;
}

.box-header-company {
  font-size: 2em;
  font-weight: 600;
  display: flex;
  flex: 1 1 auto;
  justify-self: flex-start;
  justify-content: center;
  align-items: center;
  padding: 20px 0 !important;
}

.box-twocol {
  display: grid;
  grid-template-columns: 180px auto;
  justify-items: start;
}
.box-twocol .box-header-company {
  padding: 0;
  margin: 20px 0;
}

.company-investment {
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  font-size: 22px;
  font-weight: 500;
  max-width: 500px;
  width: 60%;
}

.company-badge {
  color: white;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  max-width: 130px;
}

.company-name {
  margin-right: 20px;
  justify-content: center;
  height: 90px;
}

.box-header-investment {
  flex: 0 0 auto;
  justify-self: flex-end;
  margin: 0 30px;
  text-align: center;
}

.box-header-return {
  flex: 0 0 auto;
  justify-self: flex-end;
  margin: 0 30px;
  font-weight: 600;
  text-align: center;
}



.box-header-return .return{
  padding: 0 40px;
}

.box-header-investment .investment{
  padding: 0 40px;
}

.box-summary {
  /* margin-top: 20px; */
  margin-bottom: 20px;
  padding: 0 10%;
}
.box-summary >>> p {
  margin-top: 0;
}

.col-green {
  color: #90B888;
}

.col-red {
  color: #F18682;
}

.group-investments {
  display: flex;
  flex-flow: row wrap;
  padding: 0 10%;
}

.investment-item-container {
  width: 50%;
  display: grid;
  grid-template-columns: 2fr minmax(120px,3fr) minmax(100px,3fr); 
  font-size: 1.5em;
  padding-right: 20px;
}

.investor-name-container, .investor-investment-container, .investor-return-container {
  padding: 1em 0;
  position: relative;
}

.category-hide-container .divider:last-child {
  border: none;
}
.category-hide-container:last-child > .divider:last-child {
  border-bottom: 1px solid rgb(175, 175, 175);
}

.investor-name{
  font-weight: 600;
}

.investor-investment {
  font-weight: 400;
  text-align: center;
  }
.investment-grad {
  background: rgb(220,220,220);
  background: linear-gradient(0deg, rgba(220,220,220,.8) 0%, rgba(255,255,255,0) 40%, rgba(255,255,255,0) 60%, rgba(220,220,220,.8) 100%);
}
.investor-return {
  text-align: center;
  font-weight: 600;
}
.explainer {
  font-size: 0.5em;
  font-weight: 400;
  position: absolute;
  width: 100%;
  top: 2px;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
}

.total-portfolio-worth {
  margin: 0 30px;
  padding: 0 30px;
}


.badge-col-very-strong {
  background-color: green;
}
.badge-col-strong {
  background-color: blue;
}
.badge-col-weak {
  background-color: #AA8B00;
}
.badge-col-very-weak {
  background-color: #CF0000;
}

/* TRANSITIONS FOR CATEGORY APPEARANCE */
.tr-takeaway-enter-active {
  transition: all 1s linear;
}
.tr-takeaway-leave-active {
  transition: all 0.5s linear;
}
.tr-takeaway-enter, .tr-takeaway-leave-to {
  opacity: 0;
}
.tr-takeaway-enter-to, .tr-takeaway-leave {
  opacity: 1;
}
</style>
