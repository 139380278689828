<template>
  <Subtitles v-show="showSubtitles">
    {{ radioSubtitle }}
  </Subtitles>
</template>

<script>
import Subtitles from '@/components/Subtitles.vue';

export default {
  name: 'RadioSubtitles',
  components: { Subtitles },
  computed: {
    radioSubtitle() {
      return this.$store.state.radioSubtitle;
    },
    showSubtitles() {
      if (!this.$store.state.radioPlaying)
        return false;

      if (this.radioSubtitle && this.$store.state.showSubtitles)
        return true;

      return false;
    }
  },
};
</script>

<style scoped>
</style>
