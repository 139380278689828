<template>
  <div id="carbon-list" class="fullscreen">
    <div id="carbon-bg" class="fullscreen" />
    <div id="carbon-bg2" />

    <h1>Carbon Counter History</h1>

    <transition-group name="tr-opacity">
      <div v-if="!offsetView" key="mainview">
        <perfect-scrollbar id="carbon-transactions">
          <div v-for="(item, index) in $store.state.carbonActions" :key="index" class="carbon-transaction">
            <p class="title">
              {{ item.title }}
            </p>
            <div v-for="(charge, chargeidx) in item.charges" :key="chargeidx" class="charge">
              <p class="description">
                <strong>Carbon reduction: </strong>
                {{ charge.description }}
              </p>
              <span class="cost">
                {{ charge.cost }}
              </span>
            </div>
          </div>


          <div v-for="(item, index) in transactions" :key="index" class="carbon-transaction">
            <p class="title">
              {{ item.title }}
            </p>
            <div v-for="(charge, chargeidx) in item.charges" :key="chargeidx" class="charge">
              <p class="description">
                <strong>Carbon charge: </strong>
                {{ charge.description }}
              </p>
              <span class="cost">
                {{ charge.cost }}
              </span>
            </div>
          </div>
        </perfect-scrollbar>

        <div id="carbon-total">
          <div class="carbon-totals fw-600" :class="{ 'fg-red': offsetRequired }">
            Total <CarbonCountSymbol :redsymbol="offsetRequired" style="transform: scale(1.5)">
              {{ $store.state.carbonCount }}
            </CarbonCountSymbol>
          </div>
          <div class="carbon-totals">
            Weekly allowance <CarbonCountSymbol style="transform: scale(1.5)">
              {{ $store.state.carbonAllowance }}
            </CarbonCountSymbol>
          </div>
        </div>

        <div v-if="offsetRequired" id="carbon-offset-button" class="circle bg-red animation-pop" @click="showOffsetView">
          <div class="widget-content">
            OFFSET REQUIRED
          </div>
        </div>
      </div>

      <div v-if="offsetView" id="carbon-offset" key="offsetview" class="pos-center">
        <p><strong>This Quarter your offset campaign is</strong> "Sea Your Offset Inc."</p>
        <p>Sea Your Offset Inc. balances out carbon spend by funding a variety of marine-focussed carbon capture projects around the world</p>

        <div id="offset-options">
          <div class="carbon-transaction" @click="addCarbonOffset(5)">
            <span class="description">
              <CarbonCountSymbol :pointer="true">-5</CarbonCountSymbol>
            </span>
            <span class="cost">£15</span>
          </div>
          <div class="carbon-transaction" @click="addCarbonOffset(20)">
            <span class="description">
              <CarbonCountSymbol :pointer="true">-20</CarbonCountSymbol>
            </span>
            <span class="cost">£50</span>
          </div>
          <div class="carbon-transaction" @click="addCarbonOffset(50)">
            <span class="description">
              <CarbonCountSymbol :pointer="true">-50</CarbonCountSymbol>
            </span>
            <span class="cost">£75</span>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import transactionsJSON from '@/content/carbontransactions.json';
import CarbonCountSymbol from '@/components/CarbonCountSymbol.vue';

export default {
  name: 'CarbonTransactions',
  components: {
    CarbonCountSymbol,
  },
  data: function() {
    return {
      transactions: transactionsJSON,
      offsetView: false,
    };
  },
  computed: {
    offsetRequired() {
      return this.$store.state.carbonCount > this.$store.state.carbonAllowance;
    }
  },
  methods: {
    showOffsetView() {
      this.offsetView = true;
    },
    addCarbonOffset(amount) {
      this.$store.dispatch('offsetCarbonCount', amount);

      this.offsetView = false;
    }
  }
};
</script>

<style scoped>
#carbon-list {
  z-index: 100;
  
  background-color: #6D8760;
}
#carbon-bg {
  background-color: #eee;

  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 98%;

  transform: skewX(30deg);
}
#carbon-bg2 {
  background-color: #eee;

  position: absolute;
  bottom: 0;
  left: 10vh;
  height: 25%;
  width: 25%;

  transform: skewX(-30deg);
}

#carbon-list p {
  margin: 0;
  padding: 0;
}
#carbon-transactions {
  position: absolute;
  top: 18%;
  left: 20%;

  padding: 0 20px;

  width: 60%;
  height: 55%;

  overflow: auto;
}

#carbon-total {
  position: absolute;
  left: 20%;
  bottom: 5%;

  padding-right: 40px;

  width: 60%;
  text-align: right;
}

#offset-options {
  position: relative;
  width: 50%;
  left: 25%;
}
#offset-options * {
  cursor: pointer;
}
#offset-options .cost {
  top: 50%;
  transform: translate(-20px, -50%);
}
#offset-options #carbon-symbol {
  top: 2px;
}

/* #carbon-offset {
  position: absolute;
  top: 18%;
  left: 20%;
} */
#carbon-offset p {
  margin-bottom: 10px;
}

#carbon-offset-button {
  position: absolute;
  bottom: 5%;
  left: calc(20% + 45px);

  width: 150px;

  cursor: pointer;
  font-weight: 400;
}
#carbon-offset-button div {
  color: #fff;
  cursor: pointer;
}

#carbon-list h1 {
  position: absolute;
  left: 50%;
  top: 5%;

  transform: translateX(-50%);

  font-weight: 400;

  color: #6D8760;
}

.carbon-transaction {
  position: relative;
  padding: 15px 30px;

  border-bottom: 1px solid #6D8760;
}
.carbon-transaction:hover {
  background-color: #e5e5e5;
}
.carbon-transaction .charge {
  position: relative;
}
.carbon-transaction .title {
  font-weight: 600;
}
.carbon-transaction .description {
  color: #6D8760;
  width: 90%;
}
.carbon-transaction .cost {
  position: absolute;
  top: 0;
  right: 0;

  font-weight: 600;

  color: #6D8760;
}

.carbon-totals {
  margin-bottom: 25px;

  font-size: 1.5em;
  color: #6D8760;
}

p strong {
  font-weight: 600;
}

#carbon-symbol {
  display: inline-block;
  top: 7px;

  margin-left: 20px;
}

.fw-600 {
  font-weight: 600;
}

.bg-red {
  background-color: #ed716c;
}
.fg-red {
  color: #ed716c;
}


.tr-opacity-enter-active, .tr-opacity-leave-active {
  transition: all 1s linear;
}
.tr-opacity-enter, .tr-opacity-leave-to {
  opacity: 0;
}
.tr-opacity-enter-to, .tr-opacity-leave {
  opacity: 1;
}
</style>
