import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import possibleUserNames from '@/content/names.json';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    playerid: null,
    connected: null,
    username: 'Sam',
    started: false,
    isIE11: !!window.MSInputMethodContext && !!document.documentMode,
    isSafari: (navigator.userAgent.indexOf('Safari') > -1 && navigator.vendor.indexOf('Apple') > -1),

    // Offline version variables
    isOfflineVersion: navigator.userAgent.indexOf(' Electron') > -1,
    offlineJWT: '',
    offlineDbUrl: (navigator.userAgent.indexOf(' Electron') === -1) ? '/.netlify/functions/' : 'https://www.enternetzero.com/.netlify/functions/',

    // For the COMPLETELY offline version, this will be set to true to only use
    // the Vuex store for saving investment decisions for the 20 preset
    // codes and override
    offlineDatabase: false,
    offlineCodes: ['2wAp', 'I3w0', '8gPH', 'QfP2', '9KEh', 'W5Zx', 'v92P', '2f3Z', '47Gb', 't7L2', 'X5Na', 'iZw6', 'h0Yt', 'D2zR', 'rV6e', 'Ao5r', 'j3Uw', 'oW2y', 'nY4q', 'dL7e'],
    offlineInvestments: {},

    showSubtitles: true,

    emailsList: ['singaporeseagrass', 'barry', 'stitchswap', 'ecolodge', 'vidchat', 'anniversary', 'ftarticle', 'meetingtomorrow', 'hapsk', 'charliebday', 'flexiwork'],
    newsList: ['blankplaceholder', 'nipsonic', 'nipsonic2', 'nipsonic3', 'nipsonic4', 'nettech', 'nettech2', 'drccobalt', 'mwpa', 'parskil', 'tyne', 'tynepoo', 'seaweedorseagrass', 'indonesiavictory', 'movetoremoval', 'killercompany', 'newsopt_dolphins', 'newsopt_hairdryer', 'newsopt_marsweet', 'newsopt_thamesbarrier', 'newsopt_barrierreef', 'newsopt_attenborough', 'newsopt_warrington', 'override_precalvermeiner', 'override_pitches_start', 'override_food_reviewcarbon', 'override_carbon_within_allowance', 'override_call_harry', 'override_investment_start',],
    newsHeadlines: ['newsopt_dolphins', 'newsopt_hairdryer', 'newsopt_marsweet', 'newsopt_thamesbarrier', 'newsopt_barrierreef', 'newsopt_attenborough', 'newsopt_warrington'],
    todoList: ['insurance', 'simoncopy', 'kellymeeting', 'rachelquery', 'kettle'],
    companies: [],
    investmentChoices: {},
    currentView: 'default',
    showEmail: 0,
    showNews: 0,
    showHeadline: ['newsopt_dolphins', 'newsopt_hairdryer'],
    showHeadlineIdx: 1,
    newsHeadlineTimer: undefined,
    showCompany: '',

    carbonCount: 196,
    carbonAllowance: 200,
    carbonActions: {
      offset: {
        title: '',
        charges: []
      }
    },

    searchHistoryCompanies: [],
    callPlaying: false,
    callAutoplay: false,
    calling: false,
    currentCall: 'call_adela',
    videoSubtitle: '',

    eventTimer: undefined,
    events: {},
    latestEvent: '',
    end: false,

    breakingNews: false,
    newEmailDialog: false,

    backbutton: '',

    radioPlaying: false,
    radioFeature: '',
    radioSubtitle: '',

    notifications: [],

    PAStatuses: [],
    PAStatusQueue: [],
    PAHintActive: false,

    PALogic: {
      'start': [
        // { msg: 'Hello $username, hope you are well today.', audio: 'hellos' },
        { msg: 'Hello $username, hope you are well today', audio: 'hellos' },
        // { msg: 'Hope you are well today', audio: 'intro', track: 'hopewell' },
        { msg: 'Your director Anna called four minutes ago, I advised her to try again in a few moments.', audio: 'intro', track: 'annacalled' },
        { msg: 'I’ve had a small upgrade overnight; we’ve moved your carbon count monitor next to the date and time for easy access.', widget: 'carbon', audio: 'intro', track: 'movedcarbon' },
        { msg: 'The Radio has been added to your dashboard as requested - click to activate, just like the other apps.', widget: 'radio', audio: 'intro', track: 'radio' },
        { msg: 'You can also control the radio when in other applications by using the radio icon in the bottom right of your screen.', audio: 'intro', track: 'controlradio' },
        { msg: 'Your News, Weather, Email and the Investor Tool apps have all been updated and are ready to use.', widget: 'none', audio: 'intro', track: 'otherapps' },
        // { msg: 'You have one outstanding item on your to do list - would you like to...', widget: 'todo', audio: 'intro', track: 'todolist' },
        { msg: '$username, you have an incoming call, it’s Anna (Head Office).', widget: 'none', audio: 'annacall' },
      ],
      'call_adela': [
        { msg: 'New item on your to do list.', widget: 'todo', track: 'newtodo' }
      ],
      'precalvermeiner': [
        { msg: 'Breaking News announcement. See News Hub.', widget: 'news', track: 'breaking' }
      ],
      'calvermeinerbreaking': [
        { msg: 'Calvermeiner is being discussed on the radio', widget: 'radio', track: 'radiocalv' }
      ],
      'johanna_received': [
        { msg: 'Reminder: upcoming webinar scheduled soon.', track: 'webreminder' }
      ],
      'pitches_start': [
        { msg: 'You are scheduled to attend a webinar hosted by Venture Pitch now', widget: 'none', track: 'webscheduled' },
        { msg: 'Webinar broadcast is about to begin. Click to join.', track: 'webjoin' }
      ],
      'call_pitches': [
        { msg: '1 new email in inbox', widget: 'email', audio: 'newemail' }
      ],
      'food_reviewcarbon': [
        { msg: 'Blood sugar levels are low. I recommend ordering lunch from the dashboard', track: 'bloodsugar' },
        { msg: 'Don\'t forget to review your carbon counter', widget: 'carbon', track: 'reviewcarbon' }
      ],
      'food_start': [
        { msg: 'Time to order lunch', widget: 'food', track: 'timelunch' }
      ],
      'foodordered': [
        { msg: 'Warning: Carbon allowance exceeded', track: 'warningcarbon' },
        { msg: 'Offset action required', widget: 'carbon', track: 'offsetrequired' }
      ],
      'carbon_within_allowance': [
        { msg: 'It\'s not long now until the investment window is open.', widget: 'none', track: 'itsnotlong' },
        { msg: 'Harry called while you were ordering lunch.', track: 'harrycalled' },
        { msg: 'You have one new video message. Click to play.', track: 'newvideomsg' }
      ],
      'call_harry': [
        { msg: '1 new email in inbox', widget: 'email', audio: 'newemail' }
      ],
      'harry': [
        { msg: 'Home owners and renters are talking about lifestyle on the radio', widget: 'radio', track: 'radiolifestyle' }
      ],
      'investment_start': [
        { msg: 'The investment window is now open. It\'s time to make your investments.', track: 'investmentopen' },
        { msg: 'Go to the Investor tool.', widget: 'investor', track: 'timeinvest' }
      ]
    },

    newsLogic: {
      blankplaceholder: {},
      nipsonic: {},
      nipsonic2: {},
      nipsonic3: {},
      nipsonic4: {},
      nettech: {},
      nettech2: {},
      drccobalt: {},
      mwpa: {},
      tyne: {},
      tynepoo: {},
      calvermeinerbreaking: {
        trigger: 'precalvermeiner'
      },
      parskil: {},
      seaweedorseagrass: {},
      indonesiavictory: {},
      movetoremoval: {},
      killercompany: {},
      newsopt_dolphins: {},
      newsopt_hairdryer: {},
      newsopt_marsweet: {},
      newsopt_thamesbarrier: {},
      newsopt_barrierreef: {},
      newsopt_attenborough: {},
      newsopt_warrington: {},
      override_precalvermeiner: {},
      override_pitches_start: {},
      override_food_reviewcarbon: {},
      override_carbon_within_allowance: {},
      override_call_harry: {},
      override_investment_start: {},
    },
    emailLogic: {
      singaporeseagrass: { read: true },
      stitchswap: { read: true },
      ecolodge: { read: true },
      vidchat: { read: true },
      anniversary: { read: true },
      ftarticle: { read: true },
      meetingtomorrow: { read: true },
      hapsk: { read: true },
      charliebday: { read: true },
      flexiwork: { read: true },
      barry: {
        read: true,
        trigger_response: true,
      },
      todaystask: {
        trigger: 'call_adela'
      },
      barry2: {
        trigger: 'barry_response'
      },
      bonghak: {
        trigger: 'radio_calvermeiner',
        trigger_response: true,
      },
      bonghak2: {
        trigger: 'bonghak_responsereceived',
      },
      johanna: {
        trigger: 'bonghak',
        trigger_response: true,
      },
      johanna2: {
        trigger: 'johanna_responsereceived',
      },
      tynerename: {
        trigger: 'johanna'
      },
      pitchfollowup: {
        trigger: 'call_pitches'
      },
      harry: {
        trigger: 'call_harry'
      }
    },
    todoLogic: {
      todo_checkemails: { trigger: 'call_adela', completion: 'todaystask' },
      barrysemail: { trigger: 'todaystask', completion: 'barry_response' },
      barrysemail2: { trigger: 'barry_responsereceived', completion: 'barry2' },
      todo_nipsonic: { trigger: 'investor_nipsonic', completion: 'nipsonic4' },
      todo_businesses: { trigger: 'nipsonic4', completion: 'precalvermeiner' },
      todo_calvermeiner: { trigger: 'precalvermeiner', completion: 'investor_calvermeiner' },
      todo_calvermeinerradio: { trigger: 'calvermeinerbreaking', completion: 'radio_calvermeiner' },
      todo_respondtoemails: { trigger: 'radio_calvermeiner', completion: 'pitches_start' },
      todo_pitches: { trigger: 'pitches_start', completion: 'call_pitches' },
      todo_inbox: { trigger: 'call_pitches', completion: 'food_reviewcarbon' },
      todo_checkcarbon: { trigger: 'food_reviewcarbon', completion: 'food_start' },
      todo_food: { trigger: 'food_start', completion: 'foodordered' },
      todo_offset: { trigger: 'foodordered', completion: 'carbon_within_allowance' },
      todo_harry: { trigger: 'carbon_within_allowance', completion: 'call_harry' },
      todo_inbox2: { trigger: 'call_harry', completion: 'investor_watermead' },
      todo_radio: { trigger: 'investor_watermead', completion: 'radio_watermead' },
      todo_invest: { trigger: 'investment_start', completion: 'call_adela_finale' },
      insurance: { done: true, order: -1 },
      simoncopy: { done: true, order: -1 },
      kellymeeting: { done: true, order: -1 },
      rachelquery: { done: true, order: -1 },
      kettle: { done: true, order: -1 },
    },
    radioLogic: {
      calvermeinerbreaking: {
        src: 'main_1_calvermeiner.mp3',
        emits: 'radio_calvermeiner',
        delay: 20,
        nowPlaying: 'Breaking News',
      },
      harry: {
        src: 'main_2_urbanliving_v4.mp3',
        emits: 'radio_watermead',
        delay: 20,
        nowPlaying: 'Urban Living',
      },
    },
    timers: {
      start: {
        emits: 'call',
        duration: 0.82
      },
      barry_responsereceived: {
        emits: 'precalvermeiner',
        duration: 4,
        view: 'news'
      },
      calvermeinerbreaking: {
        emits: 'pitches_start',
        duration: 7
      },
      call_pitches: {
        emits: 'food_reviewcarbon',
        duration: 5
      },
      food_reviewcarbon: {
        emits: 'food_start',
        duration: 1.5,
      },
      radio_watermead: {
        emits: 'investment_start',
        duration: 1
      }
    },

    showFood: false,
    pitches: false,
    investmentWindowOpen: false,

    // For testing only, default = 60 * 1000
    timerMultiplier: 1000 * 60,
    devEnvironment: (process.env.NODE_ENV === 'development'),
    devEnvironmentLocal: false,
    isPreviewEnvironment: false,
  },
  mutations: {
    updateGameContent(state, trigger) {
      if (trigger in state.events)
        return;

      console.log('Trigger: ' + trigger);
      state.events[trigger] = Date.now();
      state.latestEvent = trigger;

      var newNews = Object.keys(state.newsLogic).filter(key => state.newsLogic[key].trigger === trigger);

      if (newNews.length > 0) {
        state.newsList.push(...newNews);
        
        // Show latest news article if this is enabled
        // if (state.currentView !== 'news' && !this.backbutton) {
        //   state.showNews = state.newsList.length - 1;
        // }
      }

      var newTodos = Object.keys(state.todoLogic).filter(key => state.todoLogic[key].trigger === trigger);
      if (newTodos.length > 0) {
        state.todoList.push(...newTodos);
      }

      var finishedTodos = Object.keys(state.todoLogic).filter(key => state.todoLogic[key].completion === trigger);

      if (finishedTodos.length > 0) {
        finishedTodos.forEach(key => state.todoLogic[key].done = true);
        state.notifications.push('taskcompleted');
      }

      if (trigger in state.PALogic) {
        if (state.currentView === 'default' || state.currentView === 'radio')
          state.PAStatuses = state.PALogic[trigger];
        else
          state.PAStatusQueue = state.PALogic[trigger];

        // console.log('New PA status');
      }

      if (trigger in state.radioLogic) {
        state.radioFeature = trigger;
      }

      if (trigger === 'call_pitches' || trigger === 'call_harry') {
        state.pitches = false;
      }
      else if (trigger === 'food_start' && state.currentView === 'default') {
        state.showFood = true;
      }
      else if (trigger === 'foodordered') {
        state.showFood = false;
      }
      else if (trigger === 'pitches_start') {
        state.pitches = true;
      }
      else if (trigger === 'investment_start') {
        state.investmentWindowOpen = true;
      }
    },
    updateEmails(state, trigger) {
      var newEmails = Object.keys(state.emailLogic).filter(key => state.emailLogic[key].trigger === trigger);

      if (newEmails.length > 0) {
        // state.emailsList.push(...newEmails);
        state.emailsList.unshift(...newEmails);
        state.notifications.push('emailreceived');

        if (state.currentView !== 'email' && !this.backbutton) {
          state.showEmail = 0;
          state.newEmailDialog = true;
        }
        else if (state.currentView === 'email') {
          state.showEmail += newEmails.length;
        }
      }
      else {
        return;
      }


      // Manage any ..._received triggerse in todo list
      for (var i in newEmails) {
        var trigger2 = newEmails[i] + '_received';

        var newTodos = Object.keys(state.todoLogic).filter(key => state.todoLogic[key].trigger === trigger2);

        if (newTodos.length > 0) {
          state.todoList.push(...newTodos);
        }
  
        Object.keys(state.todoLogic).filter(key => state.todoLogic[key].completion === trigger2).forEach(key => state.todoLogic[key].done = true);
      }


      if (state.PAHintActive)
        return;

      var newEmail = [{ msg: '1 new email in inbox', widget: 'email', audio: 'newemail' }];

      if (state.PAStatusQueue.length > 0 || state.PAStatuses.length > 1)
        return;

      if (state.currentView === 'default' || state.currentView === 'radio')
        state.PAStatuses = newEmail;
      else if (state.currentView !== 'email')
        state.PAStatusQueue = newEmail;
    },
    hideEmailDialog(state) {
      state.newEmailDialog = false;
    },
    setView(state, newState) {
      if (newState === state.currentView)
        return;

      var oldState = state.currentView;

      state.currentView = newState ? newState : 'default';

      if (state.currentView === 'default') {
        this.backbutton = '';
        state.notifications.push('windowclose');
      }
      else if (state.currentView === 'weather') {
        state.notifications.push('weather');
      }
      else {
        state.notifications.push('windowopen');
      }

      if (state.currentView === state.PAStatuses[0].widget && oldState === 'default') {
        state.PAStatusQueue = [];
        state.PAStatuses = [{ msg: ' ', widget: 'none' }];
      }

      console.log('View: ' + state.currentView);
    },
    newsRead(state, id) {
      if (id in state.newsList && !state.newsLogic[id].read) {
        return;
      }

      console.log('Marked news read: ' + id);
      state.newsLogic[id].read = true;
    },
    emailRead(state, id) {
      // if (!(id in state.emailsList) || state.emailLogic[id].read) {
      //   return;
      // }

      console.log('Marked email read: ' + id);
      state.emailLogic[id].read = true;
    },
    emailResponse(state, payload) {
      state.emailLogic[payload.id].response = payload.body;
      state.notifications.push('emailsent');
    },
    cueCall(state, autoplay = false) {
      // state.currentView = 'call';
      state.calling = true;
      state.radioPlaying = false;
      state.callAutoplay = autoplay;

      // console.log('Call autoplay: ' + autoplay);
    },
    setCall(state, nextCall) {
      state.currentCall = nextCall;
    },
    endCall(state) {
      state.calling = false;
      state.callPlaying = false;
    },
    setAppDisplay(state, payload) {
      if (payload.app === 'email') {
        if (typeof(payload.item) === 'number') {
          state.showEmail = payload.item;
        }
        else {
          state.showEmail = state.emailsList.includes(payload.item) ? state.emailsList.indexOf(payload.item) : 0;
        }

        if (state.currentView === 'email') {
          state.notifications.push('emailselect');
        }
      }
      else if (payload.app === 'news') {
        state.showNews = state.newsList.includes(payload.item) ? state.newsList.indexOf(payload.item) : 0;
      }
      else if (payload.app === 'investor') {
        state.showCompany = payload.item;
      }
    },
    radioToggle(state) {
      state.radioPlaying = !state.radioPlaying;

      if (!state.radioPlaying) {
        state.radioSubtitle = '';
      }
    },
    radioPlay(state) {
      state.radioPlaying = true;
    },
    radioStop(state) {
      state.radioPlaying = false;
    },
    clearRadioFeature(state) {
      state.radioFeature = '';
      state.radioPlaying = false;
      state.radioSubtitle = '';
    },
    addToCarbonCount(state, payload) {
      state.carbonCount += payload.cost;

      state.carbonActions['food'] = {
        title: 'Dining – ' + payload.title,
        charges: [
          {
            description: 'Standardised food/beverage rates via Carbon Dining Index',
            cost: payload.cost
          }
        ]
      };
    },
    offsetCarbonCount(state, payload) {
      state.carbonCount -= payload;

      state.carbonActions['offset'].title = 'Carbon Offset';

      state.carbonActions['offset'].charges.push({
        'description': 'Sea Your Offset',
        'cost': -payload
      });
    },
    addCompanyToInvestorList(state, payload) {
      state.companies.push(payload);
    },
    removeCompanyFromInvestorList(state, payload) {
      state.companies.splice(state.companies.indexOf(payload), 1);
    },
    addSearchHistoryCompany(state, payload) {
      if (state.searchHistoryCompanies.includes(payload)) {
        state.searchHistoryCompanies.splice(state.searchHistoryCompanies.indexOf(payload), 1);
      }
      else if (state.searchHistoryCompanies.length > 4) {
        state.searchHistoryCompanies.splice(0, 1);
      }

      state.searchHistoryCompanies.push(payload);
    },
    playVideoCall(state) {
      state.callPlaying = true;
    },
    togglePlayVideoCall(state) {
      state.callPlaying = !state.callPlaying;
    },
    updatePA(state) {
      if (state.PAStatuses.length > 1) {
        state.PAStatuses.splice(0, 1);
      }
    },
    saveInvestments(state, portfolio) { //TODO: this is not used anywhere as the state is directly manipulated for investments/portfolio
      state.investmentChoices = portfolio;
    },
    submitInvestments(state, portfolio) {
      state.end = true;
      state.currentView = 'end';
      state.investmentChoices = portfolio;
    },
    setBackButton(state, back) {
      state.backbutton = back;
    },
    setPlayerData(state, playerData) {
      state.playerid = playerData._id;
      state.username = possibleUserNames[playerData.placeInGroup];
    },
    setConnectedStatus(state, connectedStatus) {
      state.connected = connectedStatus;
    },
    setOfflineToken(state, token) {
      state.offlineJWT = token;
    },
    setOfflineDatabase(state, bool) {
      state.offlineDatabase = bool;
    },
    setPAHintActive(state, val = true) {
      state.PAHintActive = val;
    },
    updateNewsHeadline(state) {
      if (state.showHeadlineIdx + 1 >= state.newsHeadlines.length) {
        state.showHeadlineIdx = state.newsHeadlines.length - 1;
        return;
      }
      else {
        state.showHeadlineIdx += 1;
      }

      state.showHeadline = [state.showHeadline[1], state.newsHeadlines[state.showHeadlineIdx]];
    },
    updateRadioSubtitle(state, payload) {
      state.radioSubtitle = payload;
    },
    updateVideoSubtitle(state, payload) {
      state.videoSubtitle = payload;
    },
    updateNotificationStack(state) {
      if (state.notifications.length === 0)
        return;

      state.notifications.splice(0, 1);
    }
  },
  actions: {
    updateGameContentAsync({ commit }, trigger, delay = 5000) {
      setTimeout(() => { commit('updateGameContent', trigger); }, delay);
    },
    async updateGameContent(context, trigger) {
      if (trigger in context.state.events)
        return;

      if (trigger.includes('override_')) {
        var overridetrigger2 = trigger.replace('override_', '');

        console.log('OVERRIDING ' + overridetrigger2);
        context.dispatch('overrideStory', overridetrigger2);
      }

      var emailDelay = 20;

      if (trigger.includes('radio_') || trigger.includes('call_')) {
        emailDelay = 1;
      }

      await context.commit('updateGameContent', trigger);
      context.dispatch('sendPlayerProgress');
      
      setTimeout(() => { context.commit('updateEmails', trigger); }, emailDelay * 1000);

      if (trigger in context.state.timers) {
        console.log('Started timer for ' + trigger + ' (' + context.state.timers[trigger].emits + ')');

        if (context.state.timers[trigger].emits === 'call')
          context.state.eventTimer = setTimeout(() => { context.commit('cueCall'); }, context.state.timers[trigger].duration * context.state.timerMultiplier);
        else
          context.state.eventTimer = setTimeout(() => {
            context.dispatch('timerTrigger', { trigger: context.state.timers[trigger].emits, view: context.state.timers[trigger].view });
          }, context.state.timers[trigger].duration * context.state.timerMultiplier);
      }

      if (trigger === 'precalvermeiner') {
        clearInterval(context.state.newsHeadlineTimer);
        context.state.newsHeadlineTimer = undefined;
        context.state.showHeadline = [context.state.showHeadline[1], 'calvermeinerbreaking'];
      }
      else if (trigger === 'pitches_start') {
        context.dispatch('startHeadlineTimer');
        context.commit('updateNewsHeadline');
      }
    },
    timerTrigger(context, payload) {
      context.commit('setView', payload.view);

      if (payload.trigger === 'precalvermeiner') {
        context.state.breakingNews = true;
        context.commit('updateGameContent', payload.trigger);

        context.state.showNews = context.state.newsList.indexOf('calvermeinerbreaking');
        setTimeout(() => {
          context.state.breakingNews = false;
          context.dispatch('updateGameContent', 'calvermeinerbreaking');
        }, 5000);
      }
      else {
        setTimeout(() => { context.commit('updateGameContent', payload.trigger); }, 500);
      }

      if (payload.trigger === 'food_reviewcarbon') {
        const trigger = 'food_reviewcarbon';

        console.log('Starting timer for food_reviewcarbon (food_start)');
        context.state.eventTimer = setTimeout(() => {
          context.dispatch('timerTrigger', { trigger: context.state.timers[trigger].emits, view: context.state.timers[trigger].view });
        }, context.state.timers[trigger].duration * context.state.timerMultiplier);
      }
    },
    overrideTimer(context, payload) {
      if (context.state.eventTimer !== undefined) {
        clearTimeout(context.state.eventTimer);
        context.state.eventTimer = undefined;
      }

      context.dispatch('setView', 'default');
      context.dispatch('updateGameContent', payload);

      if (payload === 'precalvermeiner') {
        setTimeout(() => {
          context.state.showNews = context.state.newsList.indexOf('calvermeinerbreaking');
        }, 500);
      }
    },
    overrideStory(context, payload) {
      // Function for fast-forwarding to specific points in the story
      var validOptions = ['call_adela', 'precalvermeiner', 'pitches_start', 'food_reviewcarbon', 'carbon_within_allowance', 'call_harry', 'investment_start'];

      console.log('PRE PAYLOAD CHECK ' + payload);

      if (!validOptions.includes(payload) || payload in context.state.events) {
        return;
      }

      // If a game timer is currently active, cancel it
      if (context.state.eventTimer !== undefined) {
        clearTimeout(context.state.eventTimer);
        context.state.eventTimer = undefined;
      }

      console.log('OVERRIDING WITH TRIGGER ' + payload);

      var newNews = [];
      var newEmails = [];
      var newTodos = [];
      var newEvents = [];
      var newCompanies = [];

      if (payload === 'call_adela') {
        newNews = [];
        newEmails = [];
        newTodos = [];
        newEvents = ['start'];
        newCompanies = [];

        context.commit('setCall', 'call_pitches');
      }
      else if (payload === 'precalvermeiner') {
        newNews = [];
        newEmails = ['barry2'];
        newTodos = ['todo_checkemails', 'barrysemail', 'barrysemail2', 'todo_nipsonic', 'todo_businesses'];
        newEvents = ['barry_response', 'barry_responsereceived'];
        newCompanies = ['nipsonic', 'nettech'];
      }
      else if (payload === 'pitches_start') {
        newNews = ['calvermeinerbreaking'];
        newEmails = ['barry2', 'bonghak', 'bonghak2', 'johanna', 'johanna2', 'tynerename'];
        newTodos = ['todo_checkemails', 'barrysemail', 'barrysemail2', 'todo_nipsonic', 'todo_businesses', 'todo_calvermeiner', 'todo_calvermeinerradio', 'todo_respondtoemails'];
        newEvents = ['precalvermeiner', 'barry_response', 'barry_responsereceived', 'johanna_response', 'johanna_responsereceived', 'bonghak_response', 'bonghak_responsereceived', 'bonghak', 'johanna'];
        newCompanies = ['nipsonic', 'nettech', 'calvermeiner', 'parskil', 'tyne'];
      }
      else if (payload === 'food_reviewcarbon' || payload === 'carbon_within_allowance') {
        newNews = ['calvermeinerbreaking'];
        newEmails = ['barry2', 'bonghak', 'bonghak2', 'johanna', 'johanna2', 'tynerename', 'pitchfollowup'];
        newTodos = ['todo_checkemails', 'barrysemail', 'barrysemail2', 'todo_nipsonic', 'todo_businesses', 'todo_calvermeiner', 'todo_calvermeinerradio', 'todo_respondtoemails', 'todo_pitches', 'todo_inbox'];
        newEvents = ['precalvermeiner', 'calvermeinerbreaking', 'pitches_start', 'call_pitches', 'barry_response', 'barry_responsereceived', 'johanna_response', 'johanna_responsereceived', 'bonghak_response', 'bonghak_responsereceived', 'bonghak', 'johanna'];
        newCompanies = ['nipsonic', 'nettech', 'calvermeiner', 'parskil', 'tyne', 'filtair', 'plantdeep', 'peatssake'];

        context.commit('setCall', 'call_harry');
      }
      else if (payload === 'call_harry') {
        newNews = ['calvermeinerbreaking'];
        newEmails = ['barry2', 'bonghak', 'bonghak2', 'johanna', 'johanna2', 'tynerename', 'pitchfollowup'];
        newTodos = ['todo_checkemails', 'barrysemail', 'barrysemail2', 'todo_nipsonic', 'todo_businesses', 'todo_calvermeiner', 'todo_calvermeinerradio', 'todo_respondtoemails', 'todo_pitches', 'todo_inbox', 'todo_checkcarbon', 'todo_food', 'todo_offset', 'todo_harry'];
        newEvents = ['precalvermeiner', 'calvermeinerbreaking', 'pitches_start', 'call_pitches', 'food_reviewcarbon', 'food_start', 'foodordered', 'carbon_within_allowance', 'barry_response', 'barry_responsereceived', 'johanna_response', 'johanna_responsereceived', 'bonghak_response', 'bonghak_responsereceived', 'bonghak', 'johanna'];
        newCompanies = ['nipsonic', 'nettech', 'calvermeiner', 'parskil', 'tyne', 'filtair', 'plantdeep', 'peatssake'];

        context.commit('setCall', 'call_adela_finale');
        context.state.showFood = false;
      }
      else if (payload === 'investment_start') {
        newNews = ['calvermeinerbreaking'];
        newEmails = ['barry2', 'bonghak', 'bonghak2', 'johanna', 'johanna2', 'tynerename', 'pitchfollowup', 'harry'];
        newTodos = ['todo_checkemails', 'barrysemail', 'barrysemail2', 'todo_nipsonic', 'todo_businesses', 'todo_calvermeiner', 'todo_calvermeinerradio', 'todo_respondtoemails', 'todo_pitches', 'todo_inbox', 'todo_checkcarbon', 'todo_food', 'todo_offset', 'todo_harry', 'todo_inbox2', 'todo_radio'];
        newEvents = ['precalvermeiner', 'calvermeinerbreaking', 'pitches_start', 'call_pitches', 'food_reviewcarbon', 'food_start', 'foodordered', 'carbon_within_allowance', 'barry_response', 'barry_responsereceived', 'johanna_response', 'johanna_responsereceived', 'bonghak_response', 'bonghak_responsereceived', 'bonghak', 'johanna', 'call_harry', 'harry'];
        newCompanies = ['nipsonic', 'nettech', 'calvermeiner', 'parskil', 'tyne', 'filtair', 'plantdeep', 'peatssake', 'ashmont', 'watermead'];

        context.commit('setCall', 'call_adela_finale');
        context.state.showFood = false;
      }

      if (payload === 'carbon_within_allowance') {
        newTodos = ['todo_checkemails', 'barrysemail', 'barrysemail2', 'todo_nipsonic', 'todo_businesses', 'todo_calvermeiner', 'todo_calvermeinerradio', 'todo_respondtoemails', 'todo_pitches', 'todo_inbox', 'todo_checkcarbon', 'todo_food', 'todo_offset'];
        newEvents = ['precalvermeiner', 'calvermeinerbreaking', 'pitches_start', 'call_pitches', 'food_reviewcarbon', 'food_start', 'foodordered', 'barry_response', 'barry_responsereceived', 'johanna_response', 'johanna_responsereceived', 'bonghak_response', 'bonghak_responsereceived', 'bonghak', 'johanna'];

        context.commit('setCall', 'call_harry');
        context.state.showFood = false;
      }

      newNews.forEach(key => {
        if (!context.state.newsList.includes(key)) {
          context.state.newsList.push(key);
        }
      });

      newEmails.forEach(key => {
        if (!context.state.emailsList.includes(key)) {
          context.state.emailsList.unshift(key);
        }
      });

      newTodos.forEach(key => {
        if (!context.state.todoList.includes(key)) {
          context.state.todoList.push(key);
        }

        context.state.todoLogic[key].done = true;
      });

      newEvents.forEach(key => {
        if (!(key in context.state.events)) {
          context.state.events[key] = Date.now();
        }
      });

      newCompanies.forEach(key => {
        if (!context.state.companies.includes(key)) {
          context.state.companies.push(key);
        }
      });

      context.dispatch('updateGameContent', payload);
      context.dispatch('setView', 'default');

      if (payload === 'precalvermeiner') {
        setTimeout(() => {
          context.state.showNews = context.state.newsList.indexOf('calvermeinerbreaking');
        }, 500);
      }
    },
    startHeadlineTimer(context) {
      if (context.state.newsHeadlineTimer)
        return;
      context.state.newsHeadlineTimer = setInterval(() => { context.commit('updateNewsHeadline'); }, 4 * 60 * 1000);
    },
    startGame(context, skipIntro = undefined) {
      context.state.started = true;
      context.dispatch('startHeadlineTimer');

      if (skipIntro === 'skip') {
        context.dispatch('updateGameContent', 'override_call_adela');
      }
      else {
        context.commit('updateGameContent', 'start');
        context.state.eventTimer = setTimeout(() => { context.commit('cueCall'); }, context.state.timers['start'].duration * context.state.timerMultiplier);
      }
    },
    endCall(context, callId) {
      context.commit('endCall');
      context.dispatch('updateGameContent', callId);
    },
    setView(context, newState) {
      if (newState === context.state.currentView)
        return;

      var previousView = context.state.currentView;

      context.commit('setView', newState);

      if (context.state.currentView ===  'default' && 'food_start' in context.state.events && !('foodordered' in context.state.events)) {
        context.state.showFood = true;
      }

      if (context.state.currentView === 'default' || newState === context.state.backbutton) {
        context.commit('setBackButton', '');
      }

      // If there is a new PA status, update only after showing desktop
      if (context.state.currentView === 'default' && context.state.PAStatusQueue.length > 0) {
        if (previousView === 'email' && 'widget' in context.state.PAStatusQueue[0] && context.state.PAStatusQueue[0].widget === 'email') {
          context.state.PAStatuses = [];
          context.state.PAStatusQueue = [];
        }
        else {
          setTimeout(() => {
            context.state.PAStatuses = [...context.state.PAStatusQueue];
            context.state.PAStatusQueue = [];
          }, 2000);
        }
      }

      // If food has been ordered, cue Harry Lessons' call
      if ('carbon_within_allowance' in context.state.events && context.state.currentView === 'default' && !('call_harry' in context.state.events)) {
        context.state.pitches = true;
        // setTimeout(() => { context.commit('cueCall'); }, 7000);
      }

      // Manual triggers for cues (fallback for timers)
      if (context.state.currentView === 'default') {
        if (!('precalvermeiner' in context.state.events) && ['investor_nipsonic', 'investor_nettech', 'nettech', 'nipsonic4'].map(x => { return Object.keys(context.state.events).indexOf(x); }).indexOf(-1) === -1) {
          context.dispatch('overrideTimer', 'precalvermeiner');
        }
        else if (!('call_pitches' in context.state.events) && ['investor_parskil', 'investor_tyne', 'bonghak2', 'johanna2'].map(x => { return Object.keys(context.state.events).indexOf(x); }).indexOf(-1) === -1) {
          context.dispatch('overrideTimer', 'pitches_start');
        }
        else if (!('foodordered' in context.state.events) && ['investor_filtair', 'investor_peatssake', 'investor_plantdeep', 'mwpa', 'indonesiavictory', 'movetoremoval'].map(x => { return Object.keys(context.state.events).indexOf(x); }).indexOf(-1) === -1) {
          context.dispatch('overrideTimer', 'food_reviewcarbon');
        }
      }


      if ('food_reviewcarbon' in context.state.events && context.state.currentView === 'carbon' && !('food_start' in context.state.events)) {
        if (context.state.eventTimer) {
          clearTimeout(context.state.eventTimer);
          context.state.eventTimer = undefined;
        }
        context.dispatch('updateGameContent', 'food_start');
      }

      if (context.state.currentView === 'investor') {
        if (context.state.showCompany === 'ashmont' || context.state.showCompany === 'watermead'){
          if ('call_harry' in context.state.events) {
            context.dispatch('updateGameContent', 'investor_' + context.state.showCompany);
          }
        }
        else {
          context.dispatch('updateGameContent', 'investor_' + context.state.showCompany);
        }
      }
    },
    addToCarbonCount(context, payload) {
      context.commit('addToCarbonCount', payload);

      if (context.state.carbonCount > context.state.carbonAllowance) {
        context.dispatch('updateGameContent', 'carbon_exceeds_allowance');
      }
    },
    offsetCarbonCount(context, payload) {
      context.commit('offsetCarbonCount', payload);

      if (context.state.carbonCount <= context.state.carbonAllowance) {
        context.dispatch('updateGameContent', 'carbon_within_allowance');
      }
    },
    toggleCompanyInInvestorList(context, payload) {
      if (context.state.companies.includes(payload)) {
        return;
      }
      else {
        context.commit('addCompanyToInvestorList', payload);
      }
      context.dispatch('sendPlayerProgress');
    },
    emailResponse(context, payload) {
      context.commit('emailResponse', payload);
      
      if ('trigger_response' in context.state.emailLogic[payload.id]) {
        context.dispatch('updateGameContent', payload.id + '_response');
        setTimeout(() => { context.dispatch('updateGameContent', payload.id + '_responsereceived'); }, 20000);
      }
    },
    testingCue(context, trigger) {
      if (context.state.eventTimer) {
        clearTimeout(context.state.eventTimer);
        context.state.eventTimer = undefined;
        console.log('Cleared event timer');
      }

      if (trigger === 'carbon_within_allowance') {
        context.state.pitches = true;
      }

      if (trigger === 'next_call') {
        context.commit('cueCall');
      }
      else {
        context.dispatch('updateGameContent', trigger);
      }
    },
    submitInvestments(context, portfolio) {
      context.commit('submitInvestments', portfolio);
      context.dispatch('sendPlayerInvestments');
      context.commit('cueCall');
    },
    loadPlayerData(context, id) {
      if (context.state.offlineDatabase) {
        console.log('loadPlayerData: Running in offline mode');
        console.log('loadPlayerData: ', id);

        // TODO: Set this to different numbers
        if (id !== 'db4uth03r') {
          let place = 0;

          // Set offline codes to different names depending on position in array
          if (context.state.offlineCodes.includes(id))
            place = context.state.offlineCodes.indexOf(id) % context.state.offlineCodes.length;

          context.commit('setPlayerData', { _id: id, placeInGroup: place });
        }

        return;
      }

      let headers = {};

      if (context.state.isOfflineVersion && context.state.offlineJWT) {
        headers = { 'Authorization': 'Bearer ' + context.state.offlineJWT };
      }

      axios.get(context.state.offlineDbUrl + 'players', { headers, params: { id } })
      .then(res => {
        console.log('Reply from backend');
        if (res.data && res.data[0]) {
          console.log(res.data[0]);
          context.commit('setPlayerData', res.data[0]);
          context.commit('setConnectedStatus', 'connected');
        } else if (res.data.offline === true){
          context.commit('setConnectedStatus', 'offline');
        } else {
          console.log('Unknown response from back end:', res.data);
        }
      })
      .catch(error => {
        console.log('Error from backend');
        console.log(error);
        context.commit('setConnectedStatus', 'couldnotconnect');
      });
    },
    sendPlayerProgress(context){
      if (context.state.offlineDatabase) {
        console.log('sendPlayerProgress: Running in offline mode');
        return;
      }

      let headers = {};

      if (context.state.isOfflineVersion && context.state.offlineJWT) {
        headers = { 'Authorization': 'Bearer ' + context.state.offlineJWT };
      }

      console.log('SENDING PROGRESS');

      axios.patch(context.state.offlineDbUrl + 'players', {
        id: context.state.playerid,
        payload: { 
          playerState: {
            events: context.state.events, 
            investmentChoices: context.state.investmentChoices,
            companies: context.state.companies
          }
        }
      }, { headers }
    );
    },
    sendPlayerInvestments(context) {
      if (context.state.offlineDatabase) {
        console.log('sendPlayerInvestments: Running in offline mode');

        console.log(context.state.playerid);

        window.ipcRenderer.send('setOfflineInvestments', {
          id: context.state.playerid,
          playerInvestments: context.state.investmentChoices
        });

        return;
      }

      let headers = {};

      if (context.state.isOfflineVersion && context.state.offlineJWT) {
        headers = { 'Authorization': 'Bearer ' + context.state.offlineJWT };
      }

      axios.patch(context.state.offlineDbUrl + 'players', {
        id: context.state.playerid,
        payload: { playerInvestments: context.state.investmentChoices }
      }, { headers });
      //TODO: What if this request fails?
    }
  },
  modules: {},
});
